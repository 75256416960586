import client from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.store';
import role from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.store';
import user from '@/apps/controlPanel/modules/user/store/ctrlPanel.user.store';

interface State {
  validationMessage: string;
}

const state = () => ({
  validationMessage: ''
});

const getters = {
  getValidationMessage(state: State): string {
    return state.validationMessage;
  }
};

const mutations = {
  setValidationMessage(state: State, message: string) {
    state.validationMessage = message;
  }
};

const actions = {};

const modules = {
  client,
  role,
  user
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
};
