

























































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreControlPanel } from '@/apps/controlPanel/store/types';
import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import { StepEnum } from '@/apps/controlPanel/models/enums';

import FirstStep from './steps/client-step-first.page.vue';
import SecondStep from './steps/client-step-second.page.vue';
import ThirdStep from './steps/client-step-third.page.vue';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { DialogType, ProductEnum } from '@/utils/models/enums';

import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import Client from '@/apps/controlPanel/models/client';
import Functionality from '@/apps/controlPanel/models/functionality';
import DataSource from '@/models/DataSource';

@Component({
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    DialogMessage,
    TBreadcrumbs
  }
})
export default class extends Vue {
  @Getter(StoreCtrlClient.Getters.getStepClientForm)
  public step!: StepEnum;

  @Getter(StoreControlPanel.Getters.getValidationMessage)
  public validationMessage: string | undefined;

  @Getter(StoreCtrlClient.Getters.getClient)
  public client!: Client;

  @Getter(StoreCtrlClient.Getters.getFunctionalitiesByProduct)
  public getFunctionalities!: (product: ProductEnum) => Functionality[];

  @Getter(StoreCtrlClient.Getters.getDataSources)
  public getDataSources!: DataSource[];

  @Action(StoreCtrlClient.Actions.setDefaultClient)
  public setDefaultClient;

  @Action(StoreCtrlClient.Actions.getFunctionalities)
  public fetchFunctionalities!: () => Promise<void>;

  @Action(StoreCtrlClient.Actions.getDataSources)
  public fetchDataSources!: () => Promise<void>;

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public dialogType = DialogType;
  public tagFunctionalities: Functionality[] = new Array<Functionality>();
  public enrichmentFunctionalities: Functionality[] = new Array<
    Functionality
  >();
  public controlPanelFunctionalities: Functionality[] = new Array<
    Functionality
  >();

  public breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.CLIENT_CREATE.meta.title,
      CtrlPanelRoutes.CLIENT_CREATE.path
    )
  ];

  public created(): void {
    Promise.all([this.fetchFunctionalities(), this.fetchDataSources()]).then(
      () => {
        this.tagFunctionalities = this.getFunctionalities(ProductEnum.TAG);
        this.enrichmentFunctionalities = this.getFunctionalities(
          ProductEnum.Enrichment
        );
        this.controlPanelFunctionalities = this.getFunctionalities(
          ProductEnum.ControlPanel
        );
        this.setDefaultClient();
      }
    );
  }
}
