

















































































import { Component, Vue } from 'vue-property-decorator';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import TrillionDatePicker from '@/components/trillion-date-picker.vue';
import moment from 'moment';
import Job from '@/apps/enrichment/models/job';
import { Action, Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { JobFilter } from '@/apps/enrichment/models/types';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import vuei18n from '@/plugins/vuei18n';
import { DialogType } from '@/utils/models/enums';

const DEFAULT_RELOAD_COUNT = 60;
const MAX_DAYS_OF_PERIOD = 31;

@Component({
  components: {
    TBreadcrumbs,
    TrillionDatePicker,
    DialogMessage
  }
})
export default class ConsultBatch extends Vue {
  @Action(EnrichmentStore.Actions.getJobsByPeriod)
  public getJobsByPeriod!: (jobFilter: JobFilter) => void;

  @Getter(EnrichmentStore.Getters.getJobs)
  public jobs!: Job[];

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

  public dates: { start: Date; end: Date } = {
    start: moment()
      .startOf('month')
      .toDate(),
    end: moment()
      .endOf('month')
      .toDate()
  };

  public count = DEFAULT_RELOAD_COUNT;

  public windowSize = {
    x: 0,
    y: 0
  };

  public interval;

  public enrichmentRoutes = EnrichmentRoutes;

  public headers = [
    {
      text: 'ID',
      value: 'id',
      sortable: true,
      width: '100px',
      align: 'center'
    },
    { text: 'Data', value: 'initialDate', sortable: true, width: '160px' },
    { text: 'Name', value: 'name', sortable: true, width: '200px' },
    {
      text: 'Consultado por',
      value: 'username',
      sortable: true,
      width: '200px'
    },
    { text: 'Qnt.', value: 'documentCount', sortable: true, align: 'center' },
    {
      text: 'Pendente',
      value: 'pendingCount',
      sortable: true,
      align: 'center'
    },
    { text: 'Erro', value: 'errorCount', sortable: true, align: 'center' },
    { text: 'Nulo', value: 'nullCount', sortable: true, align: 'center' },
    { text: 'Status', value: 'statusPercent', sortable: true, align: 'center' },
    { text: 'Ações', value: 'actions', sortable: true }
  ];

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_BATCH.meta.title, '', true)
  ];

  public onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  public reload() {
    this.resetCount();
    this.load();
  }

  public goRoute(path: string): void {
    this.$router.push(path);
  }

  public download(item) {
    alert(JSON.stringify(item));
  }

  public created() {
    this.load();
    this.createIntervalReload();
  }

  public changeDate() {
    this.reload();
  }

  public destroyed() {
    clearInterval(this.interval);
  }

  private load() {
    if (this.InvalidPeriod()) {
      this.showDialogMessage(
        new DialogModel(
          vuei18n.t('enrichment-job.titleMessage').toString(),
          vuei18n.t('enrichment-job.textMessage').toString(),
          DialogType.Information
        )
      );
      return;
    }

    this.getJobsByPeriod({
      initialDate: this.dates.start.toISOString(),
      finalDate: this.dates.end.toISOString()
    });
  }

  private InvalidPeriod() {
    const diffPeriod = moment(this.dates.end).diff(
      moment(this.dates.start),
      'days'
    );
    return diffPeriod >= MAX_DAYS_OF_PERIOD;
  }

  private resetCount() {
    this.count = DEFAULT_RELOAD_COUNT;
  }

  private createIntervalReload() {
    this.interval = setInterval(() => {
      this.count--;

      if (this.count === 0) {
        this.reload();
      }
    }, 1000);
  }
}
