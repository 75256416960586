import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';
import Campaign from '@/apps/enrichment/models/discover-owner/campaign';
import Provider from '@/apps/enrichment/models/discover-owner/provider';
import { DownloadCampaignFilter } from '@/apps/enrichment/models/types';
import DiscoverOwnerJob from '@/apps/enrichment/models/discover-owner/discoverOwnerJob';

export default class DiscoverOwnerService {
  public static consultCampaigns = (
    initialDate: string,
    finalDate: string
  ): Promise<Campaign[]> =>
    ServiceHelper.mapToPromise<Campaign[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/campaigns?_initialDate=${initialDate}&_finalDate=${finalDate}`
    );

  public static consultProviders = (): Promise<Provider[]> =>
    ServiceHelper.mapToPromise<Provider[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/providers`
    );

  public static createCampaign = (formData: FormData): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/campaigns`,
      {
        method: 'post',
        parameters: formData,
        axiosRequestConfig: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      }
    );

  public static updateCampaignOrder = (
    id: number,
    order: number
  ): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/campaigns`,
      {
        method: 'put',
        parameters: { id, order },
        axiosRequestConfig: {
          headers: { 'Content-Type': 'application/json' }
        }
      }
    );

  public static downloadCampaign = (
    filter: DownloadCampaignFilter
  ): Promise<void> =>
    ServiceHelper.generateFile(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/campaigns/export`,
      `campanha_${filter.id}_${filter.name}.csv`,
      {
        method: 'post',
        parameters: {
          id: filter.id
        }
      },
      'text/csv',
    );

    public static getDiscoverOwnerJobs = (): Promise<DiscoverOwnerJob> =>
      ServiceHelper.mapToPromise<DiscoverOwnerJob>(
        `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/jobs`);

    public static restartJobError = (idOrigin: number): Promise<void> =>
      ServiceHelper.mapToPromise<void>(
        `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/jobs/${idOrigin}/restarterror`,
        {
          method: 'put'
        }
      );

    public static downloadDiscoverOwnerJob = (id: number, fileName: string): Promise<void> =>
      ServiceHelper.generateFile(
        `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/jobs/${id}?fileName=${fileName}`,
        `${fileName}.csv`,
        {
          method: 'get',
        },
        'text/csv');

    public static downloadDiscoverAuditRequestJob = (id: number, fileName: string): Promise<void> =>
      ServiceHelper.generateFile(
        `${CONFIG.URL.CONTROLPANEL_API}/v1/discoverowner/jobs/${id}/requests`,
        `${fileName}.txt`,
        {
          method: 'get',
        },
        'text/plain');
}
