import { render, staticRenderFns } from "./client-step-second.page.vue?vue&type=template&id=6d4ea11c&scoped=true&"
import script from "./client-step-second.page.vue?vue&type=script&lang=ts&"
export * from "./client-step-second.page.vue?vue&type=script&lang=ts&"
import style0 from "./client-step-second.page.vue?vue&type=style&index=0&id=6d4ea11c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4ea11c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCol,VDivider,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VRow,VStepperContent,VSwitch,VTab,VTabs})
