

























































































































































import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import User from '@/apps/controlPanel/models/user';
import vueI18n from '@/plugins/vuei18n';
import PageDescription from '@/components/PageDescription.vue';
import Role from '@/apps/controlPanel/models/role';
import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import DialogModal from '@/components/dialog/DialogModal.vue';
import RoleStep from '@/apps/controlPanel/components/role/role-step.vue';
import DocumentValidator from '@/utils/documentValidator';
import EventName from '@/apps/controlPanel/models/event-name';
import { StepEnum } from '@/apps/controlPanel/models/enums';

@Component({
  components: {
    PageDescription,
    DialogMessage,
    DialogModal,
    RoleStep
  }
})
export default class UserCreateForm extends Vue {
  @Prop({ required: true })
  public idClient!: number;

  @Prop({ required: true })
  public user!: User;

  @Prop({ required: true })
  public roles!: Role[];

  @Prop({ required: true })
  public modalIsOpen!: boolean;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  public rules = {
    maxlength: {
      name: 50,
      password: 15,
      email: 50,
      department: 100
    },
    name: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t('user-create-page.textInputName')} ${this.vueI18n.t(
          'common.required'
        )}`,
      (v: string) =>
        (!!v && v.length >= 3) ||
        `${this.vueI18n.t('user-create-page.textInputName')} ${this.vueI18n.t(
          'common.minLength'
        )}`
    ],
    password: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length >= 5) ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.minLength')}`,
      (v: string) =>
        (!!v && v.length <= 15) ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.maxLength')}`
    ],
    confirmPassword: [
      () => this.passwordEquals() || 'As senhas precisam ser iguais'
    ],
    department: [
      (v: string) => !!v || `Departamento ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length <= 100) ||
        `Departamento ${this.vueI18n.t('common.maxLength')}`
    ],
    telephone: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t(
          'user-create-page.textInputTelephone'
        )} ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length > 13) ||
        `${this.vueI18n.t(
          'user-create-page.textInputTelephone'
        )} ${this.vueI18n.t('common.minLength')}`
    ],
    role: [
      v =>
        !!v ||
        `${this.vueI18n.t('user-create-page.textInputRole')} ${this.vueI18n.t(
          'common.required'
        )}`
    ]
  };

  public vueI18n = vueI18n;
  public boolPassword = false;
  public confirmPassword = '';
  public password = '';
  public title = this.vueI18n.t('role-page.titleCreate');
  public documentValidator = DocumentValidator;
  public eventName = EventName;

  public modalNewRole() {
    this.changeToggleDialogModal();
  }

  public closeModal() {
    this.$emit(this.eventName.User.setDefaultRole);
    this.changeToggleDialogModal();
    this.getAllRoles();
  }

  public changeToggleDialogModal(): void {
    this.$emit(this.eventName.User.changeToggleDialogModal);
    this.$emit(this.eventName.Role.setStepRoleForm, StepEnum.First);
  }

  public confirm() {
    this.modalIsOpen ? this.closeModal() : this.backToListUser();
  }

  public passwordEquals() {
    return this.user.password === this.confirmPassword;
  }

  public backToListUser(): void {
    this.$router.push(CtrlPanelRoutes.USER_LIST);
  }

  public validateUserForm() {
    const form = this.$refs.formUser as HTMLFormElement;
    return !!form.validate();
  }

  public save() {
    if (this.validateUserForm()) {
      this.$emit(this.eventName.Common.save, this.user);
    }
  }

  public getAllRoles(): void {
    this.$emit(this.eventName.User.getAllRoles, this.idClient);
  }
}
