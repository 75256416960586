















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreControlPanel } from '@/apps/controlPanel/store/types';
import { StoreCtrlRole } from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.types';
import { StepEnum } from '@/apps/controlPanel/models/enums';

import FirstStep from '@/apps/controlPanel/components/role/role-step-first.page.vue';
import SecondStep from '@/apps/controlPanel/components/role/role-step-second.page.vue';
import ThirdStep from '@/apps/controlPanel/components/role/role-step-third.page.vue';
import { AuthStore } from '@/apps/auth/store/types';

import Role from '@/apps/controlPanel/models/role';
import TagDomain from '@/apps/controlPanel/models/tagDomain';
import Functionality from '@/apps/controlPanel/models/functionality';
import { DialogType, ProductEnum } from '@/utils/models/enums';
import DataSet from '@/apps/controlPanel/models/dataSet';
import EventName from '@/apps/controlPanel/models/event-name';
import { DialogModel } from '@/utils/models/dialogModel';

@Component({
  name: 'RoleStep',
  components: {
    FirstStep,
    SecondStep,
    ThirdStep
  }
})
export default class RoleStep extends Vue {
  @Prop({ required: false })
  public idRole: number | undefined;

  @Prop({ required: true })
  public textButtonBack!: string;

  @Getter(StoreCtrlRole.Getters.getStepRoleForm)
  public step!: StepEnum;

  @Getter(StoreControlPanel.Getters.getValidationMessage)
  public validationMessage: string | undefined;

  @Getter(AuthStore.Getters.getIdClient)
  public idClient!: number;

  @Getter(StoreCtrlRole.Getters.getRole)
  public role!: Role;

  @Getter(StoreCtrlRole.Getters.getTagDomains)
  public tagDomains!: TagDomain[];

  @Getter(StoreCtrlRole.Getters.getFunctionalitiesByAdminRole)
  public getFunctionalities!: (product: ProductEnum) => Functionality[];

  @Getter(StoreCtrlRole.Getters.getDataSets)
  public dataSets!: DataSet[];

  @Action(StoreCtrlRole.Actions.setDefaultRole)
  public setDefaultRole!: () => Promise<void>;

  @Action(StoreCtrlRole.Actions.getFunctionalities)
  public fetchFunctionalities!: (idClient: number) => Promise<void>;

  @Action(StoreCtrlRole.Actions.getDataSets)
  public fetchDataSets!: (idClient: number) => Promise<void>;

  @Action(StoreCtrlRole.Actions.getTagDomains)
  public fetchTagDomains!: (idClient: number) => Promise<void>;

  @Action(StoreCtrlRole.Actions.getRole)
  public getRole!: (idRole: number) => Promise<void>;

  @Action(StoreCtrlRole.Actions.setStepRoleForm)
  public setStepRoleForm!: (step: StepEnum) => Promise<void>;

  @Action(StoreCtrlRole.Actions.saveRole)
  public saveRole!: (role: Role) => Promise<void>;

  @Action(StoreCtrlRole.Actions.updateRole)
  public updateRole!: (role: Role) => Promise<void>;

  @Action(StoreCtrlRole.Actions.showDialogMessage)
  public showDialogMessage!: (dialogModel: DialogModel) => void;

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public productEnum = ProductEnum;
  public eventName = EventName.Role;

  public confirm(): void {
    this.$emit(this.eventName.confirm);
  }

  public created(): void {
    if (!isNaN(Number(this.$route.params.idRole))) {
      this.getRole(Number(this.$route.params.idRole));
    }

    this.setStepRoleForm(StepEnum.First);
  }

  public back(): void {
    this.$emit(this.eventName.back);
  }

  public beforeMount(): void {
    this.fetchFunctionalities(this.idClient)
      .then(() => this.fetchDataSets(this.idClient))
      .then(() => this.fetchTagDomains(this.idClient));
  }

  public save(): void {
    if (!this.role.isValid()) {
      this.showMessageFunctionalitiesAndDataSetAreEmpty();
      return;
    }

    this.saveRole(this.role);
  }

  public update(): void {
    if (!this.role.isValid()) {
      this.showMessageFunctionalitiesAndDataSetAreEmpty();
      return;
    }

    this.updateRole(this.role);
  }

  public destroyed(): void {
    this.setDefaultRole();
    this.setStepRoleForm(StepEnum.First);
  }

  public showMessageFunctionalitiesAndDataSetAreEmpty(): void {
    this.showDialogMessage(
      new DialogModel(
        vueI18n.t('common.attention').toString(),
        vueI18n
          .t('role-create-page.textProductsAndPermissionsAreEmpty')
          .toString(),
        DialogType.Warning
      )
    );
  }
}
