import DataSet from '@/models/DataSet';

export const searchWithoutAccents = (searchValue: string, dataset: DataSet) => {
  const toLower = (value: string) => value.toLowerCase();
  const search = searchValue ? toLower(searchValue) : '';
  const removeAccents = (value: string) =>
    toLower(value.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
  const filter = (value: string) => value.indexOf(search) !== -1;

  return filter(removeAccents(dataset.name)) || filter(toLower(dataset.name));
};
