








































































import { Component, Vue } from 'vue-property-decorator';
import { AuthStore } from '@/apps/auth/store/types';
import { Action, Getter } from 'vuex-class';
import { MainStore } from '@/store/types';
import TButtonLoading from '@/components/trillion-button-loading.vue';

import UserAuthentication from '@/apps/auth/services/auth.service';
import { EnrichmentRoutes } from '@/apps/enrichment/router';

@Component({
  components: {
    TButtonLoading
  }
})
export default class Login extends Vue {
  constructor() {
    super();
  }

  @Getter(MainStore.Getters.getLoading)
  public isLoading!: boolean;

  @Getter(AuthStore.Getters.isAuthenticatedUser)
  public isAuthenticatedUser!: boolean;

  @Getter(AuthStore.Getters.getErrorMessage)
  public errorMessage!: string;

  @Action(MainStore.Actions.clearDialogMessage)
  public clearDialogMessage!: () => void;

  public validAuthentication = true;
  public email = '';
  public password = '';

  public rules = {
    email: [
      (v: string) => !!v || 'E-mail é obrigatório',
      (v: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || 'E-mail inválido';
      }
    ],
    password: [(v: string) => !!v || 'Senha é obrigatório']
  };

  public doLogin() {
    if (!this.form.validate()) {
      return;
    }

    this.$store
      .dispatch(AuthStore.Actions.login, {
        username: this.email,
        password: this.password
      } as UserAuthentication)
      .then(() => {
        if (this.$store.getters[AuthStore.Getters.isAuthenticatedUser]) {
          this.clearDialogMessage();
          this.$router.push(EnrichmentRoutes.CONSULT.path);
          return;
        }
        this.validAuthentication = false;
      });
  }

  public get colorButtonLoading() {
    return this.$vuetify.breakpoint.smAndDown ? '#FFF' : 'secondary';
  }

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
}
