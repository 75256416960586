const base = 'main';
export const MainStore = {
  Getters: {
    getLoading: `${base}/getLoading`,
    getIsOpenDialog: `${base}/getIsOpenDialog`,
    getIsOpenDialogModal: `${base}/getIsOpenDialogModal`,
    getDrawer: `${base}/getDrawer`,
    getCurrentLocale: `${base}/getCurrentLocale`,
    getDialogMessage: `${base}/getDialogMessage`
  },

  Mutations: {
    setLoadingStatus: `setLoadingStatus`,
    setCurrentLocale: `${base}/setCurrentLocale`,
    setDialogMessage: `${base}/setDialogMessage`,
    toggleDialog: `${base}/toggleDialog`,
    toggleDialogModal: 'toggleDialogModal',
    toggleDrawer: `${base}/toggleDrawer`
  },

  Actions: {
    enableLoading: `${base}/enableLoading`,
    disableLoading: `${base}/disableLoading`,
    changeCurrentLocale: `${base}/changeCurrentLocale`,
    showDialogMessage: `${base}/showDialogMessage`,
    clearDialogMessage: `${base}/clearDialogMessage`,
    changeToggleDialog: 'changeToggleDialog',
    changeToggleDialogModal: `${base}/changeToggleDialogModal`
  }
};
