


















































































































































import { Component, Vue } from 'vue-property-decorator';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import CardLink from '@/components/card-link.vue';
import FeaturePermission from '@/utils/feature-permission';

@Component({
  components: {
    CardLink
  }
})
export default class Consult extends Vue {
  public enrichmentRoutes = EnrichmentRoutes;
  public featurePermission = FeaturePermission;

  public goRoute(path: string): void {
    this.$router.push(path);
  }
}
