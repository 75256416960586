export default class FeaturePermission {
  public static readonly system = {
    controlPanel: 'Painel de Controle',
    enrichment: 'Enrichment',
    powerbi: 'Power BI'
  };

  public static readonly controlPanel = {
    client: {
      crud: 'ctrl-panel-client-crud',
      list: 'ctrl-panel-client-list'
    },
    role: {
      crud: 'ctrl-panel-role-crud',
      list: 'ctrl-panel-role-list'
    },
    user: {
      crud: 'ctrl-panel-user-crud',
      list: 'ctrl-panel-user-list'
    }
  };

  public static readonly enrichment = {
    consultCpf: 'enrichment-consult-cpf',
    consultCnpj: 'enrichment-consult-cnpj',
    consultCpfByCep: 'enrichment-consult-cep-cpf',
    consultCnpjByCep: 'enrichment-consult-cep-cnpj',
    consultBatch: 'enrichment-consult-batch',
    consultReport: 'enrichment-consult-report'
  };

  public static readonly powerbi = {
    avonEvas: 'powerbi-avon-evas',
    avonRezoneamento: 'powerbi-avon-rezoneamento'
  };

  public static readonly discoverOwner = {
    campaign: {
      list: 'doc-campaign-list',
      create: 'doc-campaign-create'
    },
    job: {
      auditRequest: 'doc-job-audit-request'
    }
  };
}
