


































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';
import { Locale, LocaleModeMap } from '@/assets/locales/locale';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import { EnrichmentRoutes } from '@/apps/enrichment/router';

import { MainStore } from '@/store/types';
import { AuthStore } from '@/apps/auth/store/types';

import User from '@/apps/controlPanel/models/user';
import FeaturePermission from '@/utils/feature-permission';
import { AppRoutes } from '@/router';

@Component({
  name: 'NavBar'
})
export default class NavBar extends Vue {
  @Getter(MainStore.Getters.getCurrentLocale)
  public currentLocale: Locale | undefined;

  @Getter(AuthStore.Getters.getUser)
  public authUser!: User;

  @Getter(MainStore.Getters.getLoading)
  public isLoading!: boolean;

  @Action(MainStore.Mutations.toggleDrawer)
  public toggleDrawer;

  @Action(MainStore.Actions.changeCurrentLocale)
  public changeCurrentLocale;

  @Action(AuthStore.Actions.logout)
  public actionLogout;

  public vueI18n = vueI18n;
  public enrichmentRoutes = EnrichmentRoutes;
  public ctrlPanelRoutes = CtrlPanelRoutes;
  public featurePermissions = FeaturePermission;

  private controlPanelMenuItems = [
    {
      text: EnrichmentRoutes.CONSULT_CPF.meta.title,
      to: EnrichmentRoutes.CONSULT_CPF.path,
      isAuthorized: FeaturePermission.enrichment.consultCpf
    },
    {
      text: EnrichmentRoutes.CONSULT_CNPJ.meta.title,
      to: EnrichmentRoutes.CONSULT_CNPJ.path,
      isAuthorized: FeaturePermission.enrichment.consultCnpj
    },
    {
      text: EnrichmentRoutes.CONSULT_CEP.meta.title,
      to: EnrichmentRoutes.CONSULT_CEP.path,
      isAuthorized:
        FeaturePermission.enrichment.consultCpfByCep ||
        FeaturePermission.enrichment.consultCnpjByCep
    },
    {
      text: EnrichmentRoutes.CONSULT_BATCH.meta.title,
      to: EnrichmentRoutes.CONSULT_BATCH.path,
      isAuthorized: FeaturePermission.enrichment.consultBatch
    },
    {
      text: EnrichmentRoutes.CONSULT_REPORT.meta.title,
      to: EnrichmentRoutes.CONSULT_REPORT.path,
      isAuthorized: FeaturePermission.enrichment.consultReport
    }
  ];

  public get toggleLocale(): Locale {
    return LocaleModeMap[this.currentLocale || Locale.PT];
  }

  public goRoute(path: string): void {
    if (this.$route.path !== path) {
      this.$router.push(path);
    }
  }

  public logout(): void {
    this.actionLogout();
    this.$router.push(AppRoutes.LOGIN.path);
  }
}
