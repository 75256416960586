import { IUser } from '@/apps/controlPanel/models/types';

export default class User implements IUser {
  constructor() {
    this.id = 0;
    this.idClient = 0;
    this.email = '';
    this.department = '';
    this.isActive = true;
    this.password = '';
    this.telephone = '';
    this.username = '';
    this.createdAt = new Date();
    this.idRoles = [];
    this.nameClient = '';
  }

  public id: number;
  public idClient: number;
  public email: string;
  public department: string;
  public isActive: boolean;
  public password: string;
  public telephone: string;
  public username: string;
  public idRoles: number[];
  public createdAt: Date;
  public nameClient: string;
}
