



















































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import BtnLinkConsult from '@/apps/enrichment/components/btn-link-consult.vue';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';
import numeral from 'numeral';

import Company from '@/apps/enrichment/models/company';
import TTextEmpty from '@/apps/enrichment/components/text-empty.vue';

@Component({
  name: 'CnpjDetailTable',
  components: {
    BtnLinkConsult,
    TTextEmpty
  }
})
export default class CnpjDetailTable extends Vue {
  @Getter(EnrichmentStore.Getters.getCompanyFetched)
  public companyFetched: Company | undefined;

  @Prop({ required: true })
  public subsidiarySummary!: any[];

  public vueI18n = vueI18n;
  public moment = moment;
  public numeral = numeral;

  get unavailableText() {
    return 'Nada consta';
  }

  get companyType() {
    return this.companyFetched?.isMatrix ? 'Matriz' : 'Filial';
  }
}
