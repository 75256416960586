import Client from '@/apps/controlPanel/models/client';
import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../../../config';

export default class ClientService {
  public static getClient = (clientId: number): Promise<Client> =>
    ServiceHelper.mapToPromise<Client>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/client/${clientId}`
    );

  public static getAllClients = (): Promise<Client[]> =>
    ServiceHelper.mapToPromise<Client[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/client`
    );

  public static getClientProducts = (idClient: number): Promise<[]> =>
    ServiceHelper.mapToPromise<[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/ClientProduct/${idClient}`
    );

  public static getClientPermissions = (idClient: number): Promise<[]> =>
    ServiceHelper.mapToPromise<[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/ClientPermission/${idClient}`
    );

  public static saveClient = (clientDto: Client): Promise<number> =>
    ServiceHelper.mapToPromise<number>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/client/create`,
      { method: 'post', parameters: clientDto }
    );

  public static updateClient = (clientDto: Client): Promise<boolean> =>
    ServiceHelper.mapToPromise<boolean>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/client/update`,
      { method: 'put', parameters: clientDto }
    );

  public static deleteClient = (clientId: number): Promise<boolean> =>
    ServiceHelper.mapToPromise<boolean>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/client/${clientId}`,
      { method: 'delete' }
    );
}
