import IContextVuex from '@/utils/models/contracts/iContextVuex';

import { StoreCtrlUser } from '@/apps/controlPanel/modules/user/store/ctrlPanel.user.types';

import User from '@/apps/controlPanel/models/user';
import UserService from '@/apps/controlPanel/modules/user/services/user.service';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import { DialogModel } from '@/utils/models/dialogModel';
import vueI18n from '@/plugins/vuei18n';
import { MainStore } from '@/store/types';
import { DialogType } from '@/utils/models/enums';

interface State {
  users: User[];
  user: User;
  stepNewRoleForm: StepEnum;
}

const state = () => ({
  users: new Array<User>(),
  user: new User(),
  stepNewRoleForm: StepEnum.First
});

const getters = {
  getUsers(state: State): User[] {
    return state.users;
  },

  getUser(state: State): User {
    return state.user;
  },

  getStepNewRoleForm(state: State): StepEnum {
    return state.stepNewRoleForm;
  }
};

const mutations = {
  setUsers(state: State, users: User[]) {
    state.users = users;
  },

  setUser(state: State, user: User) {
    state.user = user;
  },

  setStepNewRoleForm(state: State, step: StepEnum) {
    state.stepNewRoleForm = step;
  },

  removeUserFromList(state: State, user: User) {
    const index = state.users.indexOf(user);
    const qtdRemoved = 1;
    state.users.splice(index, qtdRemoved);
  }
};

const actions = {
  getAllUsers(context: IContextVuex, idClient: number) {
    UserService.getAllUsers(idClient).then(users => {
      context.commit(StoreCtrlUser.Mutations.setUsers, users);
    });
  },

  getUser(context: IContextVuex, idUser: number) {
    UserService.getUser(idUser).then(user => {
      context.commit(StoreCtrlUser.Mutations.setUser, user);
    });
  },

  inactivateUser(context: IContextVuex, user: User): Promise<boolean> {
    return UserService.inactivateUser(user).then(inactivated => {
      context.commit(
        MainStore.Mutations.setDialogMessage,
        new DialogModel(
          String(vueI18n.t('common.success_title_message')),
          String(vueI18n.t('common.success_text_message')),
          DialogType.Success
        ),
        { root: true }
      );

      context.commit(MainStore.Mutations.toggleDialog, {}, { root: true });

      return inactivated;
    });
  },

  setDefaultUser(context: IContextVuex) {
    context.commit(StoreCtrlUser.Mutations.setUser, new User());
  },

  saveUser(context: IContextVuex, user: User) {
    UserService.saveUser(user).then((id: number) => {
      const dialogMessage: DialogModel = new DialogModel('', '');

      if (id > 0) {
        dialogMessage.title = vueI18n
          .t('common.success_title_message')
          .toString();
        dialogMessage.text = vueI18n
          .t('common.success_text_message')
          .toString();
        dialogMessage.type = DialogType.Success;
      }

      if (!id) {
        dialogMessage.title = vueI18n
          .t('common.failure_title_message')
          .toString();
        dialogMessage.text = vueI18n
          .t('common.failure_text_message')
          .toString();
        dialogMessage.type = DialogType.Error;
      }

      context.commit(`${MainStore.Mutations.setDialogMessage}`, dialogMessage, {
        root: true
      });

      context.commit(`${MainStore.Mutations.toggleDialog}`, {}, { root: true });
    });
  },

  updateUser(context: IContextVuex, user: User) {
    UserService.updateUser(user).then(() => {
      const dialogMessage: DialogModel = new DialogModel(
        vueI18n.t('common.success_title_message').toString(),
        vueI18n.t('common.success_text_message').toString(),
        DialogType.Success
      );

      context.commit(MainStore.Mutations.setDialogMessage, dialogMessage, {
        root: true
      });
      context.commit(MainStore.Mutations.toggleDialog, {}, { root: true });
    });
  },

  removeUserFromList(context: IContextVuex, user: User) {
    context.commit(StoreCtrlUser.Mutations.removeUserFromList, user);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
