const base = 'auth';

export const AuthStore = {
  Getters: {
    isAuthorized: `${base}/isAuthorized`,
    isAnyAuthorized: `${base}/isAnyAuthorized`,
    isAuthenticatedUser: `${base}/isAuthenticatedUser`,
    getUser: `${base}/getUser`,
    getToken: `${base}/getToken`,
    getIdClient: `${base}/getIdClientUser`,
    getEmail: `${base}/getEmail`,
    getErrorMessage: `${base}/getErrorMessage`
  },

  Mutations: {
    loginUser: `loginUser`,
    setLogoutUser: 'logoutUser',
    setErrorMessage: 'setErrorMessage'
  },

  Actions: {
    login: `${base}/login`,
    logout: `${base}/logout`,
    clearErrorMessage: `${base}/clearErrorMessage`
  }
};
