const base = 'ctrlPanel';

export const StoreControlPanel = {
  Getters: {
    getValidationMessage: `${base}/getValidationMessage`
  },

  Mutations: {
    setValidationMessage: 'setValidationMessage'
  },

  Actions: {}
};
