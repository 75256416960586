import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import router, { AppRoutes } from '@/router';
import store from '@/store/index';
import { MainStore } from '@/store/types';
import { AuthUser } from '@/utils/models/authUser';
import { DialogModel } from '@/utils/models/dialogModel';
import vueI18n from '@/plugins/vuei18n';
import { DialogType } from '@/utils/models/enums';

export interface InterfaceHttpService {
  http: AxiosInstance;
  httpBlob: AxiosInstance;
}

export class HttpService implements InterfaceHttpService {
  get http(): AxiosInstance {
    const instance = axios.create({
      headers: this.getHeaders()
    });

    instance.interceptors.request.use(
      HttpService._requestSuccess,
      HttpService._requestError
    );
    instance.interceptors.response.use(
      HttpService._responseSuccess,
      HttpService._responseError
    );

    return instance;
  }

  get httpBlob(): AxiosInstance {
    const instance = axios.create({
      responseType: 'arraybuffer',
      headers: this.getHeaders()
    });

    instance.interceptors.request.use(
      HttpService._requestSuccess,
      HttpService._requestError
    );
    instance.interceptors.response.use(
      HttpService._responseSuccess,
      HttpService._responseError
    );

    return instance;
  }

  private static _treatError() {
    store.dispatch(
      MainStore.Actions.showDialogMessage,
      new DialogModel(
        vueI18n.t('common.failure_title_message').toString(),
        'Ocorreu um erro, por favor entre em contato com o Suporte Trillion.',
        DialogType.Error
      )
    );

    throw new axios.Cancel('failure status code 500');
  }

  private static _requestSuccess(req: AxiosRequestConfig) {
    store.dispatch(MainStore.Actions.enableLoading);
    return req;
  }

  private static _requestError(err: AxiosError) {
    store.commit(MainStore.Actions.disableLoading);
    return Promise.reject(err);
  }

  private static _responseSuccess(res: AxiosResponse) {
    store.dispatch(MainStore.Actions.disableLoading);

    if (res.data.status === 500) {
      HttpService._treatError();
    }

    return Promise.resolve(res);
  }

  private static _responseError(err: AxiosError) {
    store.dispatch(MainStore.Actions.disableLoading);

    if (err.request.status === 500) {
      HttpService._treatError();
    }

    if (err.request.status === 400) {
      return Promise.reject(err);
    }

    if (err.request.status === 401) {
      router.push(AppRoutes.LOGIN.path, () => '');
    }

    if (err.request.status === 403) {
      store.dispatch(
        MainStore.Actions.showDialogMessage,
        new DialogModel(
          vueI18n.t('common.failure_title_message').toString(),
          'Você não tem permissão para acessar este recurso.',
          DialogType.Information
        )
      );

      throw new axios.Cancel('failure status code 403');
    }

    return Promise.resolve(err);
  }

  public getHeaders() {
    const usrJson = localStorage.getItem('auth-user');

    if (usrJson) {
      const usr = JSON.parse(usrJson);
      const authUser = new AuthUser(usr._email, usr._username, usr._jwtToken);
      return { Authorization: `Bearer ${authUser.token}` };
    }
  }
}

export const httpService = new HttpService();
export const http = httpService.http;
