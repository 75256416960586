const base = 'enrichment';

export const EnrichmentStore = {
  Getters: {
    getPeopleFetched: `${base}/getPeopleFetched`,
    getPeopleSearch: `${base}/getPeopleSearch`,
    getCompanyFetched: `${base}/getCompanyFetched`,
    getCompanySearch: `${base}/getCompanySearch`,
    getAddressFetched: `${base}/getAddressFetched`,
    getAddressSearch: `${base}/getAddressSearch`,
    getTabResultSelected: `${base}/getTabResultSelected`,
    getDataReportPerson: `${base}/getDataReportPerson`,
    getDataReportConsult: `${base}/getDataReportConsult`,
    getBatchDataContexts: `${base}/getBatchDataContexts`,
    getDataContextTypeSelected: `${base}/getDataContextTypeSelected`,
    getQtdTotalConsults: `${base}/getQtdTotalConsults`,
    getJobs: `${base}/getJobs`,
    getDiscoverOwnerJobs: `${base}/getDiscoverOwnerJobs`,
  },

  Mutations: {
    setPeopleFetched: 'setPeopleFetched',
    setPeopleSearch: `${base}/setPeopleSearch`,
    setCompanyFetched: 'setCompanyFetched',
    setCompanySearch: `${base}/setCompanySearch`,
    setAddressFetched: 'setAddressFetched',
    setAddressSearch: `${base}/setAddressSearch`,
    setTabResultSelected: `${base}/setTabResultSelected`,
    setDataReportPerson: 'setDataReportPerson',
    setDataReportConsult: 'setDataReportConsult',
    setBatchDataContexts: `${base}/setBatchDataContexts`,
    setDataContextTypeSelected: `${base}/setDataContextTypeSelected`,
    setDiscoverOwnerJobs: `${base}/setDiscoverOwnerJobs`,
    setQtdTotalConsults: `setQtdTotalConsults`,
    setJobs: `${base}/setJobs`,
  },

  Actions: {
    consultCpf: `${base}/consultCpf`,
    consultCnpj: `${base}/consultCnpj`,
    consultCep: `${base}/consultCep`,
    searchDataReport: `${base}/searchDataReport`,
    showDialogMessage: `${base}/showDialogMessage`,
    clearDataReports: `${base}/clearDataReports`,
    consultDataContexts: `${base}/consultDataContexts`,
    clearDataContexts: `${base}/clearDataContexts`,
    uncheckBatchDataContexts: `${base}/uncheckBatchDataContexts`,
    updateDataContexts: `${base}/updateDataContexts`,
    updateDataContextTypeSelected: `${base}/updateDataContextTypeSelected`,
    getJobsByPeriod: `${base}/getJobsByPeriod`,
    createJob: `${base}/createJob`,
    clearAddressData: `${base}/clearAddressData`,
    clearCompanyData: `${base}/clearCompanyData`,
    clearPeopleData: `${base}/clearPeopleData`,
    getDiscoverOwnerJobs: `${base}/getDiscoverOwnerJobs`,
  },
};

