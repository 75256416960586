import store from '@/store';
import { AuthStore } from '@/apps/auth/store/types';
import { DirectiveOptions } from 'vue';

const definition: DirectiveOptions = {
  inserted: (el, binding) => {
    const isAuthorized: (feature: string) => boolean =
      store.getters[AuthStore.Getters.isAuthorized];
    let authorized = false;

    if (typeof binding.value === 'object') {
      binding.value.map((feature: string) => {
        if (!authorized) {
          authorized = isAuthorized(feature);
        }
      });
    }

    if (typeof binding.value === 'string') {
      authorized = isAuthorized(binding.value);
    }

    const parent = el.parentNode;
    if (parent && !authorized) {
      parent.removeChild(el);
    }
  }
};

const isAuthorized = {
  id: 'isAuthorized',
  definition
};

export default isAuthorized;
