import Address from '@/apps/enrichment/models/address';

export default class Company {
  constructor(
    public readonly documentSituation: string,
    public readonly document: Document,
    public readonly socialReason: string,
    public readonly openedAt: string,
    public readonly isMatrix: boolean,
    public readonly subsidieries: Subsidiery[],
    public readonly billingEstimated: number,
    public readonly employee: Employee,
    public readonly emails: string[],
    public readonly phones: Phone[],
    public readonly addresses: Address[],
    public readonly documentFormat: string,
    public readonly cnaes: Cnae[],
    public readonly partners: Partner[],
    public readonly administrativePartners: Partner[]
  ) {
    this.addresses = addresses.map(
      (address: Address) =>
        new Address(
          address.addressType,
          address.addressNumber,
          address.additionalAddress,
          address.address,
          address.neighborhood,
          address.city,
          address.state,
          address.cep,
          address.cepFormated,
          address.latitude,
          address.longitude,
          address.geocode,
          address.rank
        )
    );
  }
}

export interface Partner {
  document: Document;
  name: string;
  percent: number;
  qualification?: string;
  datePartner: string;
  documentFormat: string;
}

export interface Document {
  document: number;
  personType: number;
}

export interface Cnae {
  document: number;
  subclass: string;
  kind: string;
  order: number;
}

export interface Employee {
  quantity: number;
}

export interface Phone {
  phoneNumber: number;
  areaCode: number;
  type: number;
  phoneNumberFormat: string;
}

export interface Subsidiery {
  situation: string;
  documentFormated: string;
  socialReason: string;
}
