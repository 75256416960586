























































import { Component, Vue } from 'vue-property-decorator';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';

import CardLink from '@/components/card-link.vue';
import FeaturePermission from '@/utils/feature-permission';

@Component({
  components: {
    CardLink
  }
})
export default class ControlPanel extends Vue {
  public ctrlPanelRoutes = CtrlPanelRoutes;
  public featurePermission = FeaturePermission;

  public goRoute(path: string): void {
    this.$router.push(path);
  }
}
