











import { Component, Prop, Vue } from 'vue-property-decorator';
import PowerbiComponent from '@/apps/enrichment/components/powerbi.component.vue';
import NavBar from '@/components/navbar/navbar.vue';

@Component({
  name: 'PowerbiPage',
  components: { PowerbiComponent, NavBar }
})
export default class PowerbiPage extends Vue {
  @Prop({ required: true })
  public idWorkspace!: string;

  @Prop({ required: true })
  public idReport!: string;
}
