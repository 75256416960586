import Vue from 'vue';
import Vuex from 'vuex';
import { MainStore } from '@/store/types';
import { Locale } from '@/assets/locales/locale';
import vueI18n from '@/plugins/vuei18n';
import IContextVuex from '@/utils/models/contracts/iContextVuex';

import auth from '@/apps/auth/store/store';
import ctrlPanel from '@/apps/controlPanel/store/store';
import enrichment from '@/apps/enrichment/store/store';
import { DialogModel } from '@/utils/models/dialogModel';

Vue.use(Vuex);

interface State {
  isLoading: boolean;
  openDialog: boolean;
  openDialogModal: boolean;
  drawer: boolean;
  currentLocale: Locale;
  dialogMessage: DialogModel;
}

const state = () => ({
  isLoading: false,
  openDialog: false,
  openDialogModal: false,
  drawer: true,
  currentLocale: Locale.PT,
  dialogMessage: new DialogModel('', '')
});

const getters = {
  getLoading(state: State): boolean {
    return state.isLoading;
  },
  getIsOpenDialog(state: State): boolean {
    return state.openDialog;
  },
  getIsOpenDialogModal(state: State): boolean {
    return state.openDialogModal;
  },
  getDrawer(state: State): boolean {
    return state.drawer;
  },
  getCurrentLocale(state: State): Locale {
    return state.currentLocale;
  },
  getDialogMessage(state: State): DialogModel {
    return state.dialogMessage;
  }
};

const mutations = {
  setLoadingStatus(state: State, status: boolean) {
    state.isLoading = status;
  },

  setDialogMessage(state: State, dialogMessage: DialogModel) {
    state.dialogMessage = dialogMessage;
  },

  toggleDialog(state: State) {
    state.openDialog = !state.openDialog;
  },

  toggleDialogModal(state: State) {
    state.openDialogModal = !state.openDialogModal;
  },

  toggleDrawer(state: State) {
    state.drawer = !state.drawer;
  },

  setCurrentLocale(state: State, locale: Locale) {
    localStorage.setItem('current-locale', locale);
    vueI18n.locale = locale;
    state.currentLocale = locale;
  }
};

const actions = {
  enableLoading(context: IContextVuex) {
    context.commit(MainStore.Mutations.setLoadingStatus, true);
  },

  disableLoading(context: IContextVuex) {
    context.commit(MainStore.Mutations.setLoadingStatus, false);
  },

  changeCurrentLocale(context: IContextVuex, locale: Locale) {
    context.commit(MainStore.Mutations.setCurrentLocale, locale);
  },

  showDialogMessage(context: IContextVuex, dialogMessage: DialogModel) {
    context.commit(`${MainStore.Mutations.setDialogMessage}`, dialogMessage, {
      root: true
    });
    context.commit(`${MainStore.Mutations.toggleDialog}`, {}, { root: true });
  },

  clearDialogMessage(context: IContextVuex) {
    context.commit(
      MainStore.Mutations.setDialogMessage,
      new DialogModel('', ''),
      { root: true }
    );
  },

  changeToggleDialog(context: IContextVuex) {
    context.commit(MainStore.Mutations.toggleDialog, {}, { root: true });
  },

  changeToggleDialogModal(context: IContextVuex) {
    context.commit(MainStore.Mutations.toggleDialogModal);
  }
};

const main = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default new Vuex.Store({
  modules: {
    main,
    auth,
    ctrlPanel,
    enrichment
  }
});
