



import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TextEmpty extends Vue {
  public get defaultSlot() {
    if (
      this.$slots.default &&
      this.$slots.default[0] &&
      this.$slots.default[0].text &&
      this.$slots.default[0].text.trim() !== ''
    ) {
      return this.$slots.default[0].text;
    }

    return 'Nada consta';
  }
}
