



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import Client from '@/apps/controlPanel/models/client';
import Functionality from '@/apps/controlPanel/models/functionality';

import DialogMessage from '@/components/dialog/DialogMessage.vue';
import OneLineListItemText from '@/components/list-item/OneLineListItemText.vue';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import { DialogModel } from '@/utils/models/dialogModel';
import { MainStore } from '@/store/types';
import { DialogType } from '@/utils/models/enums';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';

@Component({
  components: {
    DialogMessage,
    OneLineListItemText
  }
})
export default class extends Vue {
  @Prop({ required: true })
  public client!: Client;

  @Prop({ required: true })
  public getControlPanelFunctionalities!: Functionality[];

  @Action(MainStore.Actions.changeToggleDialog)
  public toggleDialog!: () => void;

  @Action(StoreCtrlClient.Actions.saveClient)
  public saveClient!: (client: Client) => Promise<number>;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogModel: DialogModel) => void;

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public dialogMessage: DialogModel = new DialogModel('', '');

  public setStepClientForm(step: StepEnum) {
    this.$store.commit(StoreCtrlClient.Mutations.setStepClientForm, step);
  }

  public get controlPanelFunctionalities(): Functionality[] {
    return this.getControlPanelFunctionalities;
  }

  public save() {
    if (
      this.client.tagDomains.length === 0 &&
      this.client.functionalities.length === 0 &&
      this.client.dataSets.length === 0
    ) {
      this.dialogMessage.title = vueI18n.t('common.attention').toString();
      this.dialogMessage.text = vueI18n
        .t('client-create-page.textProductsAndPermissionsAreEmpty')
        .toString();
      this.toggleDialog();
      this.$store.commit(
        StoreCtrlClient.Mutations.setStepClientForm,
        StepEnum.Second
      );
      return;
    }
    this.saveClient(this.client)
      .then(() => {
        this.dialogMessage.title = 'Operação Realizada com sucesso';
        this.dialogMessage.text = `O cliente ${this.client.name} foi inserido.`;
        this.dialogMessage.type = DialogType.Success;
        this.showDialogMessage(this.dialogMessage);
        this.$router.push(CtrlPanelRoutes.CLIENT_LIST);
      })
      .catch(() => {
        this.dialogMessage.title = 'Ops! Ocorreu um erro';
        this.dialogMessage.text = `Favor entrar em contato com administrador...`;
        this.dialogMessage.type = DialogType.Error;
        this.showDialogMessage(this.dialogMessage);
      });
  }
}
