


































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DialogModel } from '@/utils/models/dialogModel';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { MainStore } from '@/store/types';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import DiscoverOwnerJob, { DiscoverOwnerJobStatus, DiscoverOwnerJobType } from '@/apps/enrichment/models/discover-owner/discoverOwnerJob';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import DiscoverOwnerService from '@/apps/enrichment/services/discoverowner.service';
import FeaturePermission from '@/utils/feature-permission';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage
  }
})
export default class JobList extends Vue {
    @Getter(MainStore.Getters.getDialogMessage)
    public dialogMessage!: DialogModel;

    @Getter(MainStore.Getters.getLoading)
    public isLoading!: boolean;

    @Getter(EnrichmentStore.Getters.getDiscoverOwnerJobs)
    public jobs!: DiscoverOwnerJob[];

    @Action(MainStore.Actions.showDialogMessage)
    public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

    @Action(EnrichmentStore.Actions.getDiscoverOwnerJobs)
    public getDiscoverOwnerJobs!: () => Promise<void>;

    public enrichmentRoutes = EnrichmentRoutes;
    public featurePermission = FeaturePermission;
    public discoverOwnerJobStatus = DiscoverOwnerJobStatus;

    public windowSize = {
        x: 0,
        y: 0
    };

    public breadcrumps: BreadcrumbItem[] = [
        new BreadcrumbItem(
            EnrichmentRoutes.CONSULT.meta.title,
            EnrichmentRoutes.CONSULT.path
        ),
        new BreadcrumbItem(
            EnrichmentRoutes.DISCOVER_OWNER_JOB_LIST.meta.title,
            '',
            true
        )
    ];

    public headers = [
        { text: '', value: 'options', align: 'center' },
        { text: 'ID', value: 'id', sortable: true, width: '100px', align: 'center' },
        { text: 'Name', value: 'name', sortable: true, width: '200px' },
        { text: 'Estado', value: 'statusDesc', sortable: true, align: 'center' },
        { text: 'Tipo', value: 'typeDesc', sortable: true, align: 'center' },
        { text: 'Quantidade', value: 'chassisCount', sortable: true, align: 'center' },
        { text: 'Processados', value: 'processCount', sortable: true, align: 'center' },
        { text: 'Coletados', value: 'ownersCount', sortable: true, align: 'center' },
        { text: 'Fornecedor', value: 'providerDesc', sortable: true, align: 'center' },
        { text: 'Tempo', value: 'elapsedAt', sortable: true, align: 'center' },
        { text: 'Criado em', value: 'createdAtFormatted', sortable: true, align: 'center' },
        { text: 'Atualizado em', value: 'updatedAtFormatted', sortable: true, align: 'center' },
    ];

    public async created() {
        await this.getDiscoverOwnerJobs();
    }

    public async downloadOwners(job: DiscoverOwnerJob) {
        await DiscoverOwnerService.downloadDiscoverOwnerJob(job.id, job.name);
    }

    public async downloadAuditRequest(job: DiscoverOwnerJob) {
        await DiscoverOwnerService.downloadDiscoverAuditRequestJob(job.id, `audit_request_job_${job.id}`);
    }

    public async restartJobError(job: DiscoverOwnerJob) {
        await DiscoverOwnerService.restartJobError(job.id);
        await this.getDiscoverOwnerJobs();
    }

    public onResize() {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    }

    public defineElapsedColor(item: DiscoverOwnerJob) {
      const [days, hours] = this.getElapsedDaysAndHours(item);

      if (days > 2 || (days === 2 && hours > 12)) {
        return '#EF5350';
      }

      if ((days >= 1 && days <= 2) || (days === 2 && hours <= 12)) {
        return '#FFCC80';
      }

      return '#80CBC4';
    }

    public defineColorStatus(status: DiscoverOwnerJobStatus): string {
        const options = {
            [DiscoverOwnerJobStatus.Finished]: 'teal darken-1',
            [DiscoverOwnerJobStatus.Receipt]: 'orange darken-1',
            [DiscoverOwnerJobStatus.Sended]: 'blue-grey darken-1',
            [DiscoverOwnerJobStatus.Sending]: 'blue-grey darken-1',
            [DiscoverOwnerJobStatus.ReceiptError]: 'red darken-1',
            [DiscoverOwnerJobStatus.SendError]: 'red darken-1',
            [DiscoverOwnerJobStatus.SendSizeError]: 'red darken-1',
        };

        return options[status];
    }

    public defineColorType(type: DiscoverOwnerJobType): string {
      const options = {
        [DiscoverOwnerJobType.Daily]: '#80CBC4',
        [DiscoverOwnerJobType.Individual]: '#81D4FA',
      };

      return options[type];
    }

    private getElapsedDaysAndHours(item: DiscoverOwnerJob) {
      const days = parseInt(item.elapsedAt.substring(0, 2), 10);
      const hours = parseInt(item.elapsedAt.substring(4, 6), 10);

      return [days, hours];
    }
}
