import local from './local.env';
import qa from './qa.env';
import prod from './prod.env';

// this class contains globals methods to use in application.
import {EnumHelpers} from '@/utils/enumerable.helper';
import {ProductEnum} from '@/utils/models/enums';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const products = EnumHelpers.TransformToKeyValue(ProductEnum);
const dictEnvironments = [
  { environment: prod, domain: 'portal.trillion.com.br' },
  { environment: qa, domain: 'cp-qa.trillion.com.br' },
  { environment: local, domain: 'localhost' },
];

const { environment } = dictEnvironments.find(x => location.hostname.indexOf(x.domain) > -1) ||  dictEnvironments[0];
export const CONFIG = environment;
