import {
  IClient,
  IClientProduct,
  IFunctionality,
  ITagDomain
} from '@/apps/controlPanel/models/types';
import { PersonType } from '@/apps/controlPanel/models/enums';
import DataSet from '@/models/DataSet';

export default class Client implements IClient {
  constructor() {
    this.id = 0;
    this.name = '';
    this.personType = PersonType.Juridic;
    this.companyName = '';
    this.documentNumber = '';
    this.email = '';
    this.telephone = '';
    this.qtdLimitRequest = 0;
    this.functionalities = new Array<IFunctionality>();
    this.clientProducts = new Array<IClientProduct>();
    this.tagDomains = new Array<ITagDomain>();
    this.dataSets = new Array<DataSet>();
  }

  public id: number;
  public name: string;
  public personType: PersonType;
  public companyName: string;
  public documentNumber: string;
  public email: string;
  public telephone: string;
  public qtdLimitRequest: number;
  public functionalities: IFunctionality[];
  public clientProducts: IClientProduct[];
  public tagDomains: ITagDomain[];
  public dataSets: DataSet[];

  public unMaskFields() {
    this.telephone = this.telephone.replace(/\D+/g, '');
    this.documentNumber = this.documentNumber.replace(/\D+/g, '');
  }
}
