


















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import { StepEnum } from '@/apps/controlPanel/models/enums';

import Client from '@/apps/controlPanel/models/client';

import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import FeaturePermission from '@/utils/feature-permission';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { MainStore } from '@/store/types';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage
  }
})
export default class extends Vue {
  public vueI18n = vueI18n;
  public cnpjLength = 14;

  @Getter(StoreCtrlClient.Getters.getClients)
  public clients!: Client[];

  @Action(StoreCtrlClient.Actions.getAllClients)
  public getAllClients;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogMessage;

  public featurePermission = FeaturePermission;

  public breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.CLIENT_LIST.meta.title,
      CtrlPanelRoutes.CLIENT_LIST.path
    )
  ];

  public created() {
    this.getAllClients();
  }

  public createClient() {
    this.$store.commit(
      StoreCtrlClient.Mutations.setStepClientForm,
      StepEnum.First
    );
    this.$router.push(CtrlPanelRoutes.CLIENT_CREATE);
  }

  public getDataTableHeader(name: string, personType: string) {
    return [
      { text: name, align: 'start', value: 'name' },
      { text: personType, value: 'documentNumber' }
    ];
  }
}
