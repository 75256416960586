import { Route } from 'vue-router';

import ClientPage from '@/apps/controlPanel/modules/client/pages/client.page.vue';
import CreateClient from '@/apps/controlPanel/modules/client/pages/client-create.page.vue';

import RolePage from '@/apps/controlPanel/modules/role/pages/role.page.vue';
import CreateRole from '@/apps/controlPanel/modules/role/pages/role-create-update.page.vue';

import ControlPanel from '@/apps/controlPanel/pages/control-panel-page.vue';

import UserPage from '@/apps/controlPanel/modules/user/pages/user.page.vue';
import CreateUser from '@/apps/controlPanel/modules/user/pages/user-create-update.page.vue';
import FeaturePermission from '@/utils/feature-permission';

const clientPath = '/clients';
const rolePath = '/roles';
const userPath = '/users';

export const CtrlPanelRoutes = Object.freeze({
  CONTROL_PANEL: {
    path: '/cp',
    name: 'ControlPanel',
    meta: {
      title: 'Painel de Controle',
      authorize: [
        FeaturePermission.controlPanel.client.list,
        FeaturePermission.controlPanel.role.list,
        FeaturePermission.controlPanel.user.list
      ]
    }
  },

  CLIENT_LIST: {
    path: clientPath,
    name: 'ListClients',
    meta: {
      title: 'Clientes',
      authorize: [FeaturePermission.controlPanel.client.list]
    }
  },
  CLIENT_CREATE: {
    path: `${clientPath}/create`,
    name: 'CreateClient',
    meta: {
      title: 'Novo Cliente',
      authorize: [FeaturePermission.controlPanel.client.crud]
    }
  },

  ROLE_LIST: { path: rolePath, name: 'ListRoles', meta: { title: 'Perfis' } },
  ROLE_CREATE: {
    path: `${rolePath}/create`,
    name: 'CreateRole',
    meta: {
      title: 'Novo Perfil',
      authorize: [FeaturePermission.controlPanel.role.crud]
    }
  },
  ROLE_UPDATE: {
    path: `${rolePath}/:idRole/update`,
    name: 'UpdateRole',
    meta: {
      title: 'Editar Perfil',
      authorize: [FeaturePermission.controlPanel.role.crud]
    }
  },

  USER_LIST: { path: userPath, name: 'ListUsers', meta: { title: 'Usuários' } },
  USER_CREATE: {
    path: `${userPath}/create`,
    name: 'CreateUser',
    meta: {
      title: 'Novo Usuário',
      authorize: [FeaturePermission.controlPanel.user.crud]
    }
  },
  USER_UPDATE: {
    path: `${userPath}/:idUser/update`,
    name: 'UpdateUser',
    meta: {
      title: 'Editar Usuário',
      authorize: [FeaturePermission.controlPanel.user.crud]
    }
  }
});

export const controlPanelRoutes = [
  {
    path: CtrlPanelRoutes.CONTROL_PANEL.path,
    name: CtrlPanelRoutes.CONTROL_PANEL.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: ControlPanel
  },
  {
    path: CtrlPanelRoutes.CLIENT_LIST.path,
    name: CtrlPanelRoutes.CLIENT_LIST.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: ClientPage
  },
  {
    path: CtrlPanelRoutes.CLIENT_CREATE.path,
    name: CtrlPanelRoutes.CLIENT_CREATE.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: CreateClient,
    props: (route: Route) => ({
      idClient: Number(route.params.idClient)
    })
  },
  {
    path: CtrlPanelRoutes.ROLE_LIST.path,
    name: CtrlPanelRoutes.ROLE_LIST.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: RolePage,
    props: (route: Route) => ({
      idClient: Number(route.params.idClient)
    })
  },
  {
    path: CtrlPanelRoutes.ROLE_CREATE.path,
    name: CtrlPanelRoutes.ROLE_CREATE.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: CreateRole,
    props: (route: Route) => ({
      idRole: Number(route.params.idRole)
    })
  },
  {
    path: CtrlPanelRoutes.ROLE_UPDATE.path,
    name: CtrlPanelRoutes.ROLE_UPDATE.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: CreateRole,
    props: (route: Route) => ({
      idRole: Number(route.query.idRole)
    })
  },

  {
    path: CtrlPanelRoutes.USER_LIST.path,
    name: CtrlPanelRoutes.USER_LIST.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: UserPage
  },
  {
    path: CtrlPanelRoutes.USER_CREATE.path,
    name: CtrlPanelRoutes.USER_CREATE.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: CreateUser
  },

  {
    path: CtrlPanelRoutes.USER_UPDATE.path,
    name: CtrlPanelRoutes.USER_UPDATE.name,
    meta: CtrlPanelRoutes.CONTROL_PANEL.meta,
    component: CreateUser,
    props: (route: Route) => ({
      idUser: Number(route.query.idUser)
    })
  }
];
