import DataSet from './DataSet';

export default class DataContext {
  constructor(
    public readonly id = 0,
    public readonly name = '',
    public readonly dataSets: DataSet[] = [],
    allDataSetsChecked = false
  ) {
    this.allDataSetsChecked = allDataSetsChecked;
  }

  public allDataSetsChecked: boolean;
}
