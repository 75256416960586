









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TrillionListPhones extends Vue {
  @Prop({ required: true }) public items!: string[];
}
