







































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { MainStore } from '@/store/types';
import { DialogType } from '@/utils/models/enums';
import vueI18n from '@/plugins/vuei18n';

@Component
export default class DialogMessage extends Vue {
  @Getter(MainStore.Getters.getIsOpenDialog)
  public openDialog!: boolean;

  @Mutation(MainStore.Mutations.toggleDialog)
  public toggleDialog;

  @Prop({ required: true })
  public title!: string;

  @Prop({ required: true })
  public text!: string;

  @Prop({ required: false })
  public isConfirm!: boolean;

  @Prop({ required: true })
  public type!: DialogType;

  public vueI18n = vueI18n;
  public dialogType = DialogType;

  public confirm() {
    this.toggleDialog();
    this.$emit('confirm');
  }

  public yes() {
    this.toggleDialog();
    this.$emit('yes');
  }

  public cancel() {
    this.toggleDialog();
    this.$emit('cancel');
  }
}
