export class TupleDictionary<TKey, TResult> {
  private map = new Map<string, TResult>();

  public set(parameters: TKey, value: any) {
    const key = this.key(parameters);

    this.map.set(key, value);
  }

  public get(parameters: TKey): TResult {
    const key = this.key(parameters);
    return this.map.get(key) as TResult;
  }

  private key(parameters: TKey) {
    return Array.from([parameters])
      .flat()
      .join('-');
  }
}
