













































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';

import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import { PersonType, StepEnum } from '@/apps/controlPanel/models/enums';

import Client from '@/apps/controlPanel/models/client';
import DocumentValidator from '@/utils/documentValidator';
import PageDescription from '@/components/PageDescription.vue';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';

@Component({
  components: {
    PageDescription
  }
})
export default class ClientStepFirst extends Vue {
  @Prop({ required: true })
  public client!: Client;

  private vueI18n = vueI18n;
  private documentValidator = DocumentValidator;
  private stepEnum = StepEnum;
  private personType = PersonType;

  public setPersonType(personType: PersonType): void {
    this.client.personType = personType;
  }

  private backToListClient(): void {
    this.$router.push(CtrlPanelRoutes.CLIENT_LIST);
  }

  private validateClientForm() {
    const form = this.$refs.formClient as HTMLFormElement;
    if (form.validate()) {
      this.client.unMaskFields();
      this.$store.commit(
        StoreCtrlClient.Mutations.setStepClientForm,
        StepEnum.Second
      );
    }
  }
}
