













































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';

import { MainStore } from '@/store/types';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { DocumentSpecification, TabResult } from '../models/types';
import { DialogModel } from '@/utils/models/dialogModel';

import DialogMessage from '@/components/dialog/DialogMessage.vue';
import DocumentValidator from '@/utils/documentValidator';
import CpfDetailCard from '@/apps/enrichment/components/cpf-detail-card.vue';
import CpfDetailTable from '@/apps/enrichment/components/cpf-detail-table.vue';
import People from '@/apps/enrichment/models/people';
import TButtonLoading from '@/components/trillion-button-loading.vue';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import TListDatasets from '@/apps/enrichment/components/list-datasets.vue';
import { DocumentSearch } from '@/apps/enrichment/store/store';
import TCardTrillion from '@/apps/enrichment/components/card-trillion.vue';

@Component({
  components: {
    CpfDetailCard,
    CpfDetailTable,
    TButtonLoading,
    DialogMessage,
    TBreadcrumbs,
    TListDatasets,
    TCardTrillion
  }
})
export default class ConsultCpf extends Vue {
  @Action(EnrichmentStore.Actions.clearPeopleData)
  public clearPersonDataStored!: () => void;

  @Action(EnrichmentStore.Actions.consultCpf)
  public consultCpf!: (document: number) => Promise<People>;

  @Mutation(EnrichmentStore.Mutations.setTabResultSelected)
  public setTabResultSelected!: (tabResult: TabResult) => void;

  @Mutation(EnrichmentStore.Mutations.setPeopleSearch)
  public setPeopleSearch!: (search: DocumentSearch) => void;

  @Getter(EnrichmentStore.Getters.getPeopleFetched)
  public peopleFetched: People | undefined;

  @Getter(EnrichmentStore.Getters.getPeopleSearch)
  public peopleSearchResult!: DocumentSearch;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Getter(EnrichmentStore.Getters.getTabResultSelected)
  public tabResultSelected!: TabResult;

  @Prop({ required: false })
  public queryDocument: number | undefined;

  public documentValidator = DocumentValidator;
  public vueI18n = vueI18n;
  public moment = moment;
  public TabResult = TabResult;
  public document = '';

  public get tabSelected() {
    return this.tabResultSelected;
  }

  public set tabSelected(value: string) {
    this.setTabResultSelected(value as TabResult);
  }

  public get peopleSearch(): DocumentSearch {
    return this.peopleSearchResult;
  }

  public set peopleSearch(value: DocumentSearch) {
    this.setPeopleSearch(value);
  }

  public dataSetDescription: string[] = [
    'Nome',
    'Data de Nascimento',
    'Signo',
    'Telefone',
    'Endereço',
    'Nome da Mãe',
    'Moradores do mesmo endereço',
    'Endereços adicionais',
    'Outros telefones do mesmo CPF',
    'Participações empresariais',
    'E-mails'
  ];

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_CPF.meta.title, '', true)
  ];

  public destroyed() {
    this.clearPersonDataStored();
    this.clearData();
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public created(): void {
    if (this.peopleSearch.document) {
      this.document = this.peopleSearch.document;
    }

    if (this.queryDocument) {
      this.document = this.queryDocument
        .toString()
        .padStart(DocumentSpecification.DocumentLength.CPF, '0');
    }

    window.addEventListener('keydown', this.keyDownEvent);
  }

  public keyDownEvent(event) {
    const treat = {
      27: {
        name: 'esc',
        func: () => this.clearCPF()
      }
    };

    treat[event.keyCode]?.func();
  }

  public clearCPF(): void {
    this.clearData();
    (this.$refs.document as Vue & { focus: () => void }).focus();
  }

  public mounted(): void {
    if (this.queryDocument) {
      this.doConsult();
    }
  }

  public doConsult() {
    (this.$refs.document as Vue & { onBlur: () => void }).onBlur();
    if (this.form.validate() && this.getUnMaskDocument) {
      this.setPeopleSearch({
        document: this.document,
        date: moment().format('lll')
      });
      this.consultCpf(this.getUnMaskDocument);
    }
  }

  public updateDocument(document: string): void {
    this.document = document;
    this.doConsult();
  }

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public get hasData(): boolean {
    return !!(
      this.document &&
      this.document === this.peopleSearch.document &&
      this.peopleFetched &&
      this.peopleFetched.document
    );
  }

  private get getUnMaskDocument(): number {
    return parseInt(this.document.replace(/\D+/g, ''), 10);
  }

  private clearData(): void {
    this.document = '';
    this.setPeopleSearch({ document: '', date: '' });
  }
}
