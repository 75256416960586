import IContextVuex from '@/utils/models/contracts/iContextVuex';

import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import { StepEnum } from '@/apps/controlPanel/models/enums';

import ClientService from '@/apps/controlPanel/modules/client/services/client.service';
import Client from '@/apps/controlPanel/models/client';
import Functionality from '@/apps/controlPanel/models/functionality';
import ControlPanelService from '@/apps/controlPanel/services/controlPanel.service';
import { ProductEnum } from '@/utils/models/enums';
import DataSource from '@/models/DataSource';
import DataSet, { DATASET_MAIN_INDEX_CONSIDER } from '@/models/DataSet';
import DataContext from '@/models/DataContext';
import { IDataContext } from '@/apps/controlPanel/models/interfaces/IDataContext';
import { IDataSource } from '@/apps/controlPanel/models/interfaces/IDataSource';

interface State {
  clients: Client[];
  functionalities: Functionality[];
  client: Client;
  stepClientForm: StepEnum;
  dataSources: DataSource[];
}

const state = () => ({
  clients: new Array<Client>(),
  client: new Client(),
  stepClientForm: StepEnum.First,
  functionalities: new Array<Functionality>(),
  dataSources: new Array<DataSource>()
});

const getters = {
  getStepClientForm(state: State): StepEnum {
    return state.stepClientForm;
  },

  getClients(state: State): Client[] {
    return state.clients;
  },

  getClient(state: State): Client {
    return state.client;
  },

  getFunctionalities(state: State): Functionality[] {
    return state.functionalities;
  },

  getFunctionalitiesByProduct(
    state: State
  ): (product: ProductEnum) => Functionality[] {
    return (product: ProductEnum): Functionality[] => {
      return state.functionalities.filter(x => x.idProduct === product);
    };
  },

  getDataSources(state: State): DataSource[] {
    return state.dataSources;
  }
};

const mutations = {
  setClients(state: State, clients: Client[]) {
    state.clients = clients;
  },

  setClient(state: State, client: Client) {
    state.client = client;
  },

  clearTagDomains(state: State) {
    state.client.tagDomains.length = 0;
  },

  setStepClientForm(state: State, step: StepEnum) {
    state.stepClientForm = step;
  },

  setFunctionalities(state: State, functionalities: Functionality[]) {
    state.functionalities = functionalities;
  },

  setDataSources(state: State, dataSources: DataSource[]) {
    state.dataSources = dataSources;
  },

  setDataSets(state: State, dataSets: DataSet[]) {
    state.client.dataSets = dataSets;
  },

  clearDataSets(state: State) {
    state.client.dataSets.length = 0;
  }
};

const actions = {
  getAllClients(context: IContextVuex) {
    ClientService.getAllClients().then(clients => {
      context.commit(StoreCtrlClient.Mutations.setClients, clients);
    });
  },

  getClient(context: IContextVuex, idClient: number) {
    ClientService.getClient(idClient).then(client => {
      context.commit(StoreCtrlClient.Mutations.setClient, client, {
        root: true
      });
    });
  },

  setDefaultClient(context: IContextVuex) {
    context.commit(StoreCtrlClient.Mutations.setClient, new Client(), {
      root: true
    });
  },

  saveClient(context: IContextVuex, client: Client): Promise<number> {
    return ClientService.saveClient(client);
  },

  getFunctionalities(context: IContextVuex) {
    return ControlPanelService.getAllFunctionalities().then(functionalities => {
      context.commit(
        StoreCtrlClient.Mutations.setFunctionalities,
        functionalities,
        { root: true }
      );
    });
  },

  getDataSources(context: IContextVuex) {
    const createDataSets = (dataContext: IDataContext) =>
      dataContext.dataSetSourceDataSets.map(
        (dataSet, index) =>
          new DataSet(
            dataSet.id,
            dataSet.dataSetName,
            index <= DATASET_MAIN_INDEX_CONSIDER
          )
      );

    const createDataContexts = (dataSource: IDataSource) =>
      dataSource.dataContexts.map(
        dataContext =>
          new DataContext(
            dataContext.id,
            dataContext.name,
            createDataSets(dataContext)
          )
      );

    return ControlPanelService.getAllDataSourcesWithDataSets().then(
      dataSources => {
        const dataSourcesTransform = dataSources.map(
          dataSource =>
            new DataSource(
              dataSource.id,
              dataSource.name,
              createDataContexts(dataSource)
            )
        );

        context.commit(
          StoreCtrlClient.Mutations.setDataSources,
          dataSourcesTransform,
          { root: true }
        );
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
