




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CardLink'
})
export default class CardLink extends Vue {
  @Prop({ required: true })
  public icon!: string;

  @Prop({ required: true })
  public title!: string;

  @Prop({ required: true })
  public text!: string;

  @Prop({ required: true })
  public flex!: number;

  public goRoute(): void {
    this.$emit('goRoute');
  }
}
