














































































































































import { Component, Vue } from 'vue-property-decorator';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import Provider from '@/apps/enrichment/models/discover-owner/provider';
import DiscoverOwnerService from '@/apps/enrichment/services/discoverowner.service';
import { MainStore } from '@/store/types';
import { Action, Getter } from 'vuex-class';
import { DialogModel } from '@/utils/models/dialogModel';
import { DialogType } from '@/utils/models/enums';
import DialogMessage from '@/components/dialog/DialogMessage.vue';

const headerFile =
  'NR_CHS,DS_PLA,CD_TPO_FNE_DAD,NR_RNV,DS_UF_EMC,CD_TPO_PPI,CD_TPO_VCL,DT_EMC';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage
  }
})
export default class CampaignNew extends Vue {
  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(
      EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.meta.title,
      EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.path
    ),
    new BreadcrumbItem(
      EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_NEW.meta.title,
      '',
      true
    )
  ];

  public isFormValid = false;
  public name = '';
  public providersSelected = [];
  public fileUploaded = null;
  public quantityDocuments = 0;
  public providers: Provider[] = [];

  public nameRules = [
    v => !!v || 'O nome é obrigatório',
    v => v.length <= 50 || 'O tamanho máximo para o nome é de 50 caracteres',
  ];
  public fileRules = [
    v => !!v || 'O arquivo é obrigatório',
    v => {
      return (
        (v && /(.csv|text\/csv|application\/vnd.ms-excel)/.test(v.type)) ||
        'Somente arquivo csv é aceito.'
      );
    }
  ];

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public fileUpload() {
    if (!this.isFormValid) {
      this.quantityDocuments = 0;

      this.getContentFile(this.fileUploaded).then(
        (documents: string[] | null) =>
          documents?.some((doc, index) => {
            if (index === 0 && doc !== headerFile) {
              this.showDialogMessage(
                new DialogModel(
                  'Atenção',
                  'O layout do arquivo é inválido!',
                  DialogType.Warning
                )
              );

              this.fileUploaded = null;

              return true;
            }

            if (doc !== headerFile) {
              this.quantityDocuments++;
            }
          })
      );
    }
  }

  public created() {
    if (!this.providers.length) {
      DiscoverOwnerService.consultProviders().then(
        (data: Provider[]) => (this.providers = data)
      );
    }
  }

  public doCreateCampaign() {
    const validate = this.form.validate();
    if (this.providersSelected.length && validate) {
      DiscoverOwnerService.createCampaign(this.prepareFormData())
        .then(() => {
          this.showDialogMessage(
            new DialogModel(
              'Informação',
              'Campanha criada com sucesso!',
              DialogType.Success
            )
          ).then(() =>
            this.$router.push(
              EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.path
            )
          );
        })
        .catch(err => {
          this.showDialogMessage(
            new DialogModel('Error', err.response.data, DialogType.Error)
          );
        });
    }
  }

  private prepareFormData(): FormData {
    const formData = new FormData();
    formData.append('name', this.name);
    this.providersSelected.forEach(x => formData.append('providers', x));
    formData.append('file', this.fileUploaded || '');

    return formData;
  }

  private getContentFile(file: File | null): Promise<string[] | null> {
    return new Promise(resolve => {
      if (!file) {
        resolve(null);
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const documents = reader.result
            .toString()
            .replace(/ï»¿/, '')
            .split('\n')
            .map(document => document.trim());

          resolve(documents);
        }
      };

      reader.readAsBinaryString(file);
    });
  }

  private onGenerateLayout() {
    const csvContent = `data:text/csv;charset=utf-8,${headerFile}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.name || 'campanha_layout'}.csv`);
    document.body.appendChild(link);

    link.click();
  }
}
