export type TabResultType = 'mdi-view-grid' | 'mdi-table';
export enum TabResult {
  Grid = 'mdi-view-grid',
  Table = 'mdi-table'
}

export const DocumentSpecification = {
  documentFormattedLength: {
    CPF: 14,
    CNPJ: 18,
    CEP: 10
  },
  DocumentLength: {
    CPF: 11,
    CNPJ: 14,
    CEP: 8
  }
};

export enum Step {
  Dataset = 0,
  File = 1
}

export interface ReportFilter {
  idClient: number;
  initialDate: string;
  finalDate: string;
  reportType: number;
}

export interface JobFilter {
  initialDate: string;
  finalDate: string;
}

export interface DownloadCampaignFilter {
  id: number;
  name: string;
}

export interface PowerBiConfig {
  type: string;
  embedToken: {
    token: string;
    tokenId: string;
    expiration: Date;
  };
  embedReports: PowerBiEmbedReport[];
}

export interface PowerBiEmbedReport {
  idReport: string;
  nameReport: string;
  embedUrl: string;
}
