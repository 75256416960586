var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-row',{staticClass:"campaign-list-page",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('t-breadcrumbs',{attrs:{"items":_vm.breadcrumps,"home":false}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"list-title-main"},[_vm._v("DiscoverOwner - Consulta de Jobs")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.jobs,"items-per-page":10,"loading":_vm.isLoading,"loading-text":"Processando...","fixed-header":"","height":_vm.windowSize.y - 280},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","gap":"10px"}},[_c('button',{on:{"click":function($event){return _vm.downloadOwners(item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('button',{directives:[{name:"is-authorized",rawName:"v-is-authorized",value:(_vm.featurePermission.discoverOwner.job.auditRequest),expression:"featurePermission.discoverOwner.job.auditRequest"}],on:{"click":function($event){return _vm.downloadAuditRequest(item)}}},[_c('v-icon',[_vm._v("mdi-shield-search")])],1),(item.status === _vm.discoverOwnerJobStatus.ReceiptError)?_c('button',{directives:[{name:"is-authorized",rawName:"v-is-authorized",value:(_vm.featurePermission.discoverOwner.job.auditRequest),expression:"featurePermission.discoverOwner.job.auditRequest"}],attrs:{"title":"Iniciar a coleta do lote com erro"},on:{"click":function($event){return _vm.restartJobError(item)}}},[_c('v-icon',[_vm._v("mdi-restart")])],1):_vm._e()])]}},{key:"item.typeDesc",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","text-color":"black","color":_vm.defineColorType(item.type)}},[_vm._v(" "+_vm._s(item.typeDesc)+" ")])]}},{key:"item.elapsedAt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":_vm.defineElapsedColor(item)}},[_vm._v(" "+_vm._s(item.elapsedAt)+" ")])]}},{key:"item.statusDesc",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","text-color":"white","color":_vm.defineColorStatus(item.status)}},[_vm._v(" "+_vm._s(item.statusDesc)+" ")])]}}])})],1)],1)],1)],1),_c('DialogMessage',{attrs:{"title":_vm.dialogMessage.title,"text":_vm.dialogMessage.text,"type":_vm.dialogMessage.type}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }