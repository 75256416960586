







































import { Component, Prop, Vue } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';
import Role from '@/apps/controlPanel/models/role';
import PageDescription from '@/components/PageDescription.vue';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import EventName from '@/apps/controlPanel/models/event-name';

@Component({
  components: {
    PageDescription
  }
})
export default class RoleStepFirst extends Vue {
  @Prop({ required: false })
  public idRole: number | undefined;

  @Prop({ required: true })
  public textButtonBack!: string;

  @Prop({ required: true })
  public role!: Role;

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public eventName = EventName.Role;

  public rules = {
    name: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t('role-create-page.textInputName')} ${this.vueI18n.t(
          'common.required'
        )}`
    ],
    nameMaxLength: 50
  };

  public backToListRole(): void {
    this.$emit(this.eventName.backToListRole);
  }

  public validateRoleForm() {
    const form = this.$refs.formRole as HTMLFormElement;
    if (form.validate()) {
      this.setStepRoleForm(StepEnum.Second);
    }
  }

  public setStepRoleForm(step: StepEnum) {
    this.$emit(this.eventName.setStepRoleForm, step);
  }
}
