import UserAuthentication from '@/apps/auth/services/auth.service';
import AuthService from '@/apps/auth/services/auth.service';
import { AuthStore } from '@/apps/auth/store/types';
import { AuthUser } from '@/utils/models/authUser';
import IContextVuex from '@/utils/models/contracts/iContextVuex';

const storageAuthUserKey = 'auth-user';

interface State {
  authUser: AuthUser;
  errorMessage: string;
}

const state = () => ({
  authUser: AuthUser,
  errorMessage: String
});

const getters = {
  getUser(state: State): AuthUser {
    return state.authUser;
  },

  getIdClient(state: State): number {
    return state.authUser.idClient;
  },

  getIdClientUser(state: State): number {
    return state.authUser.idClient;
  },

  isAuthenticatedUser(state: State): boolean {
    const usrJson = localStorage.getItem(storageAuthUserKey);
    if (usrJson) {
      const usr = JSON.parse(usrJson);
      state.authUser = new AuthUser(
        usr._email,
        usr._username,
        usr._jwtToken,
        usr._idClient,
        usr._nameClient,
        usr._functionalities
      );
    }

    return state.authUser.isLogged;
  },

  getToken(state: State): string {
    return state.authUser.token;
  },

  isAuthorized(state: State): (feature: string) => boolean {
    return (feature: string): boolean => {
      return state.authUser.functionalities.includes(feature);
    };
  },

  isAnyAuthorized(state: State): (features: string[]) => boolean {
    return (features: string[]): boolean => {
      return features.some(f => state.authUser.functionalities.includes(f));
    };
  },

  getEmail(state: State): string {
    return state.authUser.email;
  },

  getErrorMessage(state: State): string {
    return state.errorMessage;
  }
};

const mutations = {
  loginUser(state: State, user: AuthUser) {
    localStorage.setItem('auth-user', JSON.stringify(user));
    state.authUser = user;
  },

  logoutUser(state: State) {
    localStorage.removeItem('auth-user');
    state.authUser = new AuthUser();
  },

  setErrorMessage(state: State, errorMessage: string) {
    state.errorMessage = errorMessage;
  }
};

const actions = {
  login(context: IContextVuex, user: UserAuthentication): Promise<AuthUser> {
    const defaultUser = new AuthUser();
    return AuthService.login(user)
      .then(authUser => {
        context.commit(
          AuthStore.Mutations.loginUser,
          new AuthUser(
            authUser.email,
            authUser.username,
            authUser.token,
            authUser.idClient,
            authUser.nameClient,
            authUser.functionalities
          )
        );
        return authUser;
      })
      .catch(err => {
        context.commit(
          AuthStore.Mutations.setErrorMessage,
          err.response.data.Message
        );
        context.commit(AuthStore.Mutations.setLogoutUser, defaultUser);
        return defaultUser;
      });
  },

  logout(context: IContextVuex) {
    context.commit(AuthStore.Mutations.setLogoutUser, new AuthUser());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
