






























































































import { Component, Vue } from 'vue-property-decorator';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { Action, Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { AuthStore } from '@/apps/auth/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import TrillionDatePicker from '@/components/trillion-date-picker.vue';
import moment from 'moment';
import ReportDataTable from '@/apps/enrichment/components/report-data-table.vue';
import vuei18n from '@/plugins/vuei18n';
import { MainStore } from '@/store/types';
import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';
import User from '@/apps/controlPanel/models/user';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage,
    TrillionDatePicker,
    ReportDataTable
  }
})
export default class Report extends Vue {
  @Getter(EnrichmentStore.Getters.getDataReportPerson)
  public dataReportPerson!: Report[];

  @Getter(EnrichmentStore.Getters.getDataReportConsult)
  public dataReportConsult!: Report[];

  @Getter(AuthStore.Getters.getIdClient)
  public idClient!: number;

  @Getter(AuthStore.Getters.getUser)
  public user!: User;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Getter(MainStore.Getters.getLoading)
  public getLoading!: boolean;

  @Getter(EnrichmentStore.Getters.getQtdTotalConsults)
  public qtdTotalConsults!: number;

  @Action(EnrichmentStore.Actions.searchDataReport)
  public searchDataReport!: (prop: {
    idClient: number;
    initialDate: string;
    finalDate: string;
    reportType: number;
  }) => Promise<void>;

  @Action(EnrichmentStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

  @Action(EnrichmentStore.Actions.clearDataReports)
  public clearDataReports!: () => void;

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_REPORT.meta.title, '', true)
  ];

  public dates: { start: Date; end: Date } = {
    start: moment()
      .startOf('month')
      .toDate(),
    end: moment()
      .endOf('month')
      .toDate()
  };

  public consultTypes = [
    { id: 1, type: 'Por pessoa' },
    { id: 2, type: 'Por consulta' }
  ];

  public reportTypes = [
    { id: 1, type: 'Faturamento' },
    { id: 2, type: 'Requisições' }
  ];
  public consultTypeSelected = { id: 1 };
  public consultReportTypes = { id: 1 };

  public headerPerson = [
    {
      text: 'Consulta Por',
      value: 'name',
      align: 'left',
      sortable: true,
      width: '200px'
    },
    {
      text: 'Consulta',
      value: 'qtdConsultsUsed',
      align: 'center',
      sortable: true,
      width: '150px'
    },
    {
      text: 'Qnt.',
      value: 'qtdAllConsults',
      align: 'center',
      sortable: true,
      width: '150px'
    }
  ];

  public headerConsult = [
    {
      text: 'Consulta',
      value: 'name',
      align: 'left',
      sortable: true,
      width: '150px'
    },
    {
      text: 'Consulta Por',
      value: 'qtdConsultsUsed',
      align: 'center',
      sortable: true,
      width: '200px'
    },
    {
      text: 'Qnt.',
      value: 'qtdAllConsults',
      sortable: true,
      align: 'center',
      width: '150px'
    }
  ];

  public downloadExcel() {
    const date = this.getDateToSearch(this.dates);

    const dateExport = moment().format('YYYYMMDD-HHmmss');
    const fileName = `Trillion_Relatório_Consumo_${this.user.nameClient}_${dateExport}.xlsx`;

    ServiceHelper.generateFile(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/report/${date.initial}/${date.final}/excel?_reportType=${this.consultReportTypes.id}`,
      fileName
    );
  }

  public search(dates) {
    if (!this.isValidDateRange(dates)) {
      this.dialogMessage.title = vuei18n
        .t('enrichment-report.titleMessage')
        .toString();
      this.dialogMessage.text = vuei18n
        .t('enrichment-report.textMessage')
        .toString();
      this.showDialogMessage(this.dialogMessage);
      return;
    }
    this.clearDataReports();

    const date = this.getDateToSearch(this.dates);

    this.searchDataReport({
      idClient: this.idClient,
      initialDate: date.initial,
      finalDate: date.final,
      reportType: this.consultReportTypes.id
    });
  }

  public handleReportType() {
    this.search(this.dates);
  }

  public isValidDateRange(dates): boolean {
    const daysInMonth = 31;
    const startDate = moment(dates.start);
    const endDate = moment(dates.end);
    const diffDaysBetweenDates = endDate.diff(startDate, 'days');

    return diffDaysBetweenDates <= daysInMonth;
  }

  public created(): void {
    const date = this.getDateToSearch(this.dates);
    this.searchDataReport({
      idClient: this.idClient,
      initialDate: date.initial,
      finalDate: date.final,
      reportType: this.consultReportTypes.id
    });
  }

  public destroyed(): void {
    this.clearDataReports();
  }

  public getDateToSearch(dates) {
    const initial = dates.start ?? moment().startOf('month');
    const final = dates.end ?? moment().endOf('month');

    return {
      initial: moment(initial).format('YYYY-MM-DDT00:00:00.000'),
      final: moment(final).format('YYYY-MM-DDT23:59:59.000')
    };
  }
}
