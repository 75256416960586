










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TrillionListDatasets extends Vue {
  @Prop({ required: true }) public items!: string[];
}
