export const env = {
  NODE_ENV: 'development',
  CLIENT_ID: 'controlpanel_api',
  URL: {
    HOST: 'http://cp-qa.trillion.com.br',
    CONTROLPANEL_API: 'http://cp-api-qa.trillion.com.br'
  }
};

export default env;
