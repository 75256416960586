export enum ProductEnum {
  ControlPanel = 1,
  Enrichment = 2,
  TAG = 3
}

export enum DataSourceEnum {
  Trillion = 1,
  Neocom = 2,
  BigData = 3
}

export enum ConsultTypeEnum {
  People = 1,
  Consult = 2
}

export enum DialogType {
  Success = 0,
  Warning = 1,
  Information = 2,
  Error = 3
}
