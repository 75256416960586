import { ITagDomain } from '@/apps/controlPanel/models/types';

export default class TagDomain implements ITagDomain {
  constructor(name: string, domain: string) {
    this.name = name;
    this.domain = domain;
    this.id = 0;
    this.idClientProduct = 0;
  }
  public domain: string;
  public id: number;
  public idClientProduct: number;
  public name: string;
}
