import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';
import People from '@/apps/enrichment/models/people';
import Address from '@/apps/enrichment/models/address';
import Company from '@/apps/enrichment/models/company';
import Report from '@/apps/enrichment/models/report';
import ConsultReturn from '@/apps/enrichment/models/consultReturn';
import DataContext from '@/models/DataContext';

export default class EnrichmentService {
  public static consultCpf = (
    document: number
  ): Promise<ConsultReturn<People>> =>
    ServiceHelper.mapToPromise<ConsultReturn<People>>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/people/${document}`
    );

  public static consultCnpj = (
    document: number
  ): Promise<ConsultReturn<Company>> =>
    ServiceHelper.mapToPromise<ConsultReturn<Company>>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/company/${document}`
    );

  public static consultCpfByCep = (
    address: Address
  ): Promise<ConsultReturn<Address>> =>
    ServiceHelper.mapToPromise<ConsultReturn<Address>>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/address/cpf`,
      {
        method: 'post',
        parameters: address
      }
    );

  public static consultCnpjByCep = (
    address: Address
  ): Promise<ConsultReturn<Address>> =>
    ServiceHelper.mapToPromise<ConsultReturn<Address>>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/address/cnpj`,
      {
        method: 'post',
        parameters: address
      }
    );

  public static getDataReport = (
    idClient: number,
    initialDate: string,
    finalDate: string,
    reportType: number
  ): Promise<Report[]> =>
    ServiceHelper.mapToPromise<Report[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/report/${idClient}/${initialDate}/${finalDate}?_reportType=${reportType}`
    );

  public static getDataContexts = (): Promise<DataContext[]> =>
    ServiceHelper.mapToPromise<DataContext[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/datacontexts`
    );
}
