var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-row',{staticClass:"campaign-list-page",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('t-breadcrumbs',{attrs:{"items":_vm.breadcrumps,"home":false}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"list-title-main"},[_vm._v("Consulta de Campanhas")])])],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_c('v-btn',{attrs:{"x-large":"","dark":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push(_vm.enrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_NEW.path)}}},[_vm._v(" Nova campanha ")])],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"d-flex justify-space-between"},[_c('TrillionDatePicker',{attrs:{"dates":_vm.dates,"close-in-change":true},on:{"update:dates":function($event){_vm.dates=$event},"selectDate":_vm.changeDate}}),_c('v-btn',{staticClass:"btn-reload",attrs:{"outlined":"","max-width":"160px","height":"40px","color":"rgb(0, 0, 0, 0.38)"},on:{"click":_vm.load}},[_c('v-icon',{attrs:{"color":"rgb(0, 0, 0)"}},[_vm._v("mdi-reload")]),_c('span',{staticStyle:{"color":"rgb(0,0,0)"}},[_vm._v("Atualizar ("+_vm._s(_vm.count)+"s)")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.campaigns,"items-per-page":10,"loading":_vm.isLoading,"loading-text":"Processando...","fixed-header":"","height":_vm.windowSize.y - 480},scopedSlots:_vm._u([{key:"item.processed",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{staticStyle:{"padding":"5px"},attrs:{"size":40,"width":5,"value":item.statusPercent,"color":_vm.getProgressColor(item.status)}},[_vm._v(" "+_vm._s(item.statusPercent)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.processed))]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("/"+_vm._s(item.total))])]}},{key:"item.ownerQuantity",fn:function(ref){
var item = ref.item;
return [_c('v-progress-circular',{staticStyle:{"padding":"5px"},attrs:{"size":40,"width":5,"value":item.collectPercent,"color":_vm.getProgressColor(item.collect)}},[_vm._v(" "+_vm._s(item.collectPercent)+" ")]),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(item.ownerQuantity))]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("/"+_vm._s(item.total))])]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","width":"100%"}},[_c('div',{staticStyle:{"width":"80px","padding":"5px"}},[_c('v-text-field',{staticClass:"input-order",attrs:{"value":item.order,"type":"number","dense":"","hide-details":"","single-line":""},on:{"blur":function($event){_vm.doUpdateOrder(item.id, parseInt($event.target.value))}}})],1)])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('button',{attrs:{"title":"Baixar os proprietários coletados"},on:{"click":function($event){$event.preventDefault();return _vm.onDownloadCampaign(item.id, item.name)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])})],1)],1)],1)],1),_c('DialogMessage',{attrs:{"title":_vm.dialogMessage.title,"text":_vm.dialogMessage.text,"type":_vm.dialogMessage.type}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }