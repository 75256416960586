








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DataUnavailable',
  components: {}
})
export default class DataUnavailable extends Vue {
  @Prop({ required: false, default: 'Nada consta' })
  public message!: string;
}
