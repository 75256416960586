export const env = {
  NODE_ENV: 'production',
  CLIENT_ID: 'controlpanel_api',
  URL: {
    HOST: 'https://portal.trillion.com.br',
    CONTROLPANEL_API: 'https://portal-api.trillion.com.br'
  }
};

export default env;
