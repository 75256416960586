









import { Component, Prop, Vue } from 'vue-property-decorator';
import * as pbi from 'powerbi-client';
import PowerBiService from '@/apps/enrichment/services/powerbi.service';
import { PowerBiConfig } from '@/apps/enrichment/models/types';

@Component({
  name: 'PowerBiComponent'
})
export default class PowerbiComponent extends Vue {
  @Prop({ required: true })
  public idWorkspace!: string;

  @Prop({ required: true })
  public idReport!: string;

  private embedType = 'report';
  private powerBi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  public created() {
    this.getPbiParameters();
  }

  public getPbiParameters(): void {
    PowerBiService.getPowerBiConfig(this.idWorkspace, this.idReport).then(
      (config: PowerBiConfig) => {
        const reportConfig = {
          type: this.embedType,
          tokenType: pbi.models.TokenType.Embed,
          accessToken: config.embedToken.token,
          embedUrl: config.embedReports[0].embedUrl
        };

        const report = this.powerBi.embed(
          this.$refs.content as HTMLElement,
          reportConfig
        );

        report.off('loaded');
        report.on('loaded', () => {
          console.log('Report load successful');
        });

        report.off('rendered');
        report.on('rendered', () => {
          console.log('Report render successful');
        });

        // Clear any other error handler events
        report.off('error');

        report.on('error', (event) => {
          const errorMsg = event.detail;

          // Use errorMsg variable to log error in any destination of choice
          console.error(errorMsg);
          return;
        });
      }
    );
  }
}
