import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';

import User from '@/apps/controlPanel/models/user';
import Functionality from '@/apps/controlPanel/models/functionality';
import { IDataSource } from '@/apps/controlPanel/models/interfaces/IDataSource';

export default class ControlPanelService {
  public static getAllUsers = (): Promise<User[]> =>
    ServiceHelper.mapToPromise<User[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user`
    );

  public static getAllFunctionalities = (): Promise<Functionality[]> =>
    ServiceHelper.mapToPromise<Functionality[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/controlPanel/functionalities`
    );

  public static getAllDataSourcesWithDataSets = (): Promise<IDataSource[]> =>
    ServiceHelper.mapToPromise<IDataSource[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/controlPanel/dataSourcesWithDataSets`
    );
}
