


























































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';
import { Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';

import People from '@/apps/enrichment/models/people';
import DataUnavailable from '@/apps/enrichment/components/data-unavailable.vue';
import BtnLinkConsult from '@/apps/enrichment/components/btn-link-consult.vue';
import TListPhones from '@/apps/enrichment/components/list-phones.vue';
import TTextEmpty from '@/apps/enrichment/components/text-empty.vue';

@Component({
  name: 'CpfDetailCard',
  components: {
    DataUnavailable,
    BtnLinkConsult,
    TListPhones,
    TTextEmpty
  }
})
export default class CpfDetailCard extends Vue {
  @Getter(EnrichmentStore.Getters.getPeopleFetched)
  public peopleFetched: People | undefined;

  public vueI18n = vueI18n;
  public moment = moment;

  get unavailableText() {
    return vueI18n.t('enrichment.dataNotFound', { prop: 'CPF' });
  }
}
