










import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  @Prop()
  private text!: string;

  private buttonAction() {
    this.$emit('buttonAction');
  }
}
