







import { Component, Prop, Vue } from 'vue-property-decorator';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';

@Component({
  inheritAttrs: false
})
export default class TBreadcrumbs extends Vue {
  @Prop({
    required: true,
    default: []
  })
  public items!: BreadcrumbItem[];

  @Prop({
    required: false,
    default: true
  })
  public home!: boolean;

  private get breadcrumbs() {
    const breads = [
      {
        text: 'Home',
        to: '/',
        disabled: false,
        exact: true
      },
      ...this.items.map((x: BreadcrumbItem) => {
        return {
          text: x.text,
          to: x.to,
          disabled: x.disabled,
          exact: x.exact
        };
      })
    ];

    return this.home ? breads : breads.slice(1);
  }
}
