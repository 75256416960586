








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataSourceEnum } from '@/utils/models/enums';
import { Getter } from 'vuex-class';
import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import Client from '@/apps/controlPanel/models/client';

@Component
export default class DataSetTab extends Vue {
  @Getter(StoreCtrlClient.Getters.getClient)
  public client!: Client;

  @Prop({ required: true })
  public dataSetGrouped!: any[];

  public dataSourceEnum = DataSourceEnum;
  public selectedTab: any = null;
}
