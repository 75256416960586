import vuei18n from '@/plugins/vuei18n';
import DataContext from '@/models/DataContext';

export default class Job {
  constructor(
    public id: number = 0,
    public name: string = '',
    public username: string = '',
    public initialDate: string = '',
    public endDate: string = '',
    public documentCount: string = '0',
    public errorCount: string = '0',
    public nullCount: string = '0',
    public pendingCount: string = '0',
    public processingCount: string = '0',
    public successCount: string = '0',
    public statusPercent: string = '0',
    public contexts: DataContext[] = []
  ) {
    const formatNumber = new Intl.NumberFormat(vuei18n.locale);
    const formatNumberPercent = new Intl.NumberFormat(vuei18n.locale, {
      style: 'percent'
    });
    const formatDate = new Intl.DateTimeFormat(vuei18n.locale, {
      hour12: false,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    this.initialDate = formatDate.format(new Date(initialDate));
    this.documentCount = formatNumber.format(Number(this.documentCount));
    this.errorCount = formatNumber.format(Number(this.errorCount));
    this.nullCount = formatNumber.format(Number(this.nullCount));
    this.pendingCount = formatNumber.format(Number(this.pendingCount));
    this.processingCount = formatNumber.format(Number(this.processingCount));
    this.successCount = formatNumber.format(Number(this.successCount));
    this.statusPercent = formatNumberPercent.format(Number(this.statusPercent));
  }
}
