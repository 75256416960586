import vuei18n from '@/plugins/vuei18n';

export default class Campaign {
  constructor(
    public id: number = 0,
    public name: string = '',
    public createdAt: string = '',
    public processed: string = '0',
    public total: string = '0',
    public ownerQuantity: string = '0',
    public order: number = 0
  ) {
    const formatNumber = new Intl.NumberFormat(vuei18n.locale);
    const formatNumberPercent = new Intl.NumberFormat(vuei18n.locale, {
      style: 'percent'
    });
    const formatDate = new Intl.DateTimeFormat(vuei18n.locale, {
      hour12: false,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    this.createdAt = formatDate.format(new Date(createdAt));
    this.processed = formatNumber.format(Number(processed));
    this.total = formatNumber.format(Number(total));
    this.ownerQuantity = formatNumber.format(Number(ownerQuantity));
    this.status = Number(processed) / Number(total);
    this.statusPercent = formatNumberPercent.format(this.status);
    this.collect = Number(ownerQuantity) / Number(total);
    this.collectPercent = formatNumberPercent.format(this.collect);
  }

  public statusPercent = '0';
  public status = 0;
  public collectPercent = '0';
  public collect = 0;
}
