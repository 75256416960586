import People from '@/apps/enrichment/models/people';
import Company from '@/apps/enrichment/models/company';

export default class Address {
  constructor(
    public addressType: string = '',
    public addressNumber: string = '',
    public additionalAddress: string = '',
    public address: string = '',
    public neighborhood: string = '',
    public city: string = '',
    public state: string = '',
    public cep: string = '',
    public cepFormated: string = '',
    public latitude: number = 0,
    public longitude: number = 0,
    public geocode: number = 0,
    public rank: number = 0
  ) {
    this.cep = this.cepFormated;
    this.address = `${this.addressType} ${this.address} ${this.addressNumber}`;
  }
  public peoples: People[] = new Array<People>();
  public companies: Company[] = new Array<Company>();
  public dateSearch = '';

  public unMaskCep(): string {
    return this.cep.replace(/\D+/g, '');
  }
}
