






































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import TCardTrillion from '@/apps/enrichment/components/card-trillion.vue';
import DocumentValidator from '@/utils/documentValidator';
import DataContext from '@/models/DataContext';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';

const REMOVE_TEXT_BOM = /ï»¿/;

@Component({
  components: {
    TBreadcrumbs,
    TCardTrillion,
    DialogMessage
  }
})
export default class ConsultFileBatchPage extends Vue {
  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Getter(EnrichmentStore.Getters.getBatchDataContexts)
  public getDataContexts!: DataContext[];

  @Getter(EnrichmentStore.Getters.getDataContextTypeSelected)
  public dataContextTypeSelected!: string;

  @Action(EnrichmentStore.Actions.clearDataContexts)
  public clearDataContexts!: () => void;

  @Action(EnrichmentStore.Actions.updateDataContextTypeSelected)
  public updateContextTypeSelected!: (dataContextType: string) => void;

  @Action(EnrichmentStore.Actions.createJob)
  public createJob!: (formData: FormData) => void;

  public breadcrumps: BreadcrumbItem[] = [];

  public fileUploaded: any = null;
  public name = '';

  public dataContextQuantityDocuments = 0;
  public dataContextInvalidDocuments: string[] = [];

  public isFormValid = false;

  public nameRules = [v => !!v || 'O nome é obrigatório'];
  public fileRules = [
    v => !!v || 'O arquivo é obrigatório',
    v => {
      return (
        (v &&
          /(.csv|.txt|text\/plain|text\/csv|application\/vnd.ms-excel)/.test(
            v.type
          )) ||
        'Somente arquivos txt e csv são aceitos.'
      );
    }
  ];

  public validateDocument = {
    ['pessoas']: {
      validate: (document: string) => DocumentValidator.isCpfValid(document)
    },
    ['empresas']: {
      validate: (document: string) => DocumentValidator.isCnpjValid(document)
    }
  };

  public get quantityInvalidDocuments() {
    return this.dataContextInvalidDocuments.length;
  }

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public created() {
    if (this.getDataContexts.length === 0) {
      this.$router.push(EnrichmentRoutes.CONSULT_NEW_BATCH.path);
    }

    this.setBreadcrumps();
  }

  public fileUpload() {
    if (!this.isFormValid) {
      this.dataContextQuantityDocuments = 0;
      this.dataContextInvalidDocuments = [];

      this.getContentFile(this.fileUploaded).then((documents: string[]) =>
        documents?.forEach((document: string) => {
          this.countDocuments();
          this.setInvalidDocuments(document);
        })
      );
    }
  }

  public doCreateJob() {
    const validate = this.form.validate();
    if (this.dataContextQuantityDocuments && validate) {
      this.createJob(this.prepareFormData());
    }
  }

  public showInvalidDocuments() {
    this.generateCSV();
  }

  public doSuccessConfirm() {
    this.clearDataContexts();
    this.updateContextTypeSelected('');
    this.$router.push(EnrichmentRoutes.CONSULT_BATCH.path);
  }

  private prepareFormData(): FormData {
    const contextCurrent = this.getDataContexts.findOrFirst(
      x => x.name === this.dataContextTypeSelected
    );

    const formData = new FormData();
    formData.append('name', this.name);
    contextCurrent.dataSets
      .filter(x => x.checked)
      .forEach(x => formData.append('jobDataSets', x.id.toString()));
    formData.append('idDataContext', contextCurrent.id.toString());
    formData.append('file', this.fileUploaded);

    return formData;
  }

  private generateCSV() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    this.dataContextInvalidDocuments.forEach(document => {
      csvContent += `${document}`;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.getFileUploadedName()}_Invalids.csv`);
    document.body.appendChild(link);

    link.click();
  }

  private getFileUploadedName() {
    return this.fileUploaded.name.replace(/(.txt|.csv)/, '');
  }

  private setInvalidDocuments(document: string) {
    const validator = this.validateDocument[
      this.dataContextTypeSelected.toLowerCase()
    ];

    if (typeof validator !== 'undefined' && !validator.validate(document)) {
      this.dataContextInvalidDocuments.push(
        document.replace(REMOVE_TEXT_BOM, '')
      );
    }
  }

  private countDocuments() {
    this.dataContextQuantityDocuments++;
  }

  private getContentFile(file: File): Promise<string[]> {
    return new Promise(resolve => {
      if (!file) {
        resolve();
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const documents = reader.result
            .toString()
            .split('\n')
            .map(document => document);

          resolve(documents);
        }
      };

      reader.readAsBinaryString(file);
    });
  }

  private setBreadcrumps() {
    this.breadcrumps = [
      new BreadcrumbItem(
        EnrichmentRoutes.CONSULT.meta.title,
        EnrichmentRoutes.CONSULT.path
      ),
      new BreadcrumbItem(
        EnrichmentRoutes.CONSULT_BATCH.meta.title,
        EnrichmentRoutes.CONSULT_BATCH.path
      ),
      new BreadcrumbItem(
        EnrichmentRoutes.CONSULT_NEW_BATCH.meta.title,
        EnrichmentRoutes.CONSULT_NEW_BATCH.path
      ),
      new BreadcrumbItem(
        `Consulta por ${this.dataContextTypeSelected}`,
        '',
        true
      )
    ];
  }
}
