export const env = {
  NODE_ENV: 'development',
  CLIENT_ID: 'controlpanel_api',
  URL: {
    HOST: 'http://localhost/controlpanel',
    CONTROLPANEL_API: 'http://localhost:5000'
  }
};

export default env;
