export default class DiscoverOwnerJob {
    constructor(
        public id: number = 0,
        public name: string = '',
        public status: DiscoverOwnerJobStatus,
        public type: DiscoverOwnerJobType,
        public statusDesc: string = '',
        public typeDesc: string = '',
        public chassisCount: number = 0,
        public processCount: number = 0,
        public ownersCount: number = 0,
        public providerDesc: string = '',
        public createdAtFormatted: string = '',
        public updatedAtFormatted: string = '',
        public createdAt: string,
        public updatedAt: string,
        public elapsedAt: string
    ) { }
    public getUpdatedAt(): Date {
      return this.updatedAt ? new Date(this.updatedAt) : new Date(new Date().setUTCHours(0));
    }

    public getCreatedAt(): Date {
      return this.createdAt ? new Date(this.createdAt) : new Date(new Date().setUTCHours(0));
    }
}

export enum DiscoverOwnerJobType {
  Daily = 1,
  Individual = 2
}

export enum DiscoverOwnerJobStatus {
  Pending = 1,
  Sending = 2,
  Sended = 3,
  Receipt = 4,
  Finished = 5,
  SendError = 6,
  SendSizeError = 7,
  ReceiptError = 8,
}
