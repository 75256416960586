export enum Locale {
  EN = 'en-US',
  PT = 'pt-BR'
}

export const LOCALES = [
  { value: Locale.EN, caption: 'English' },
  { value: Locale.PT, caption: 'Portuguese' }
];

export const LocaleModeMap = {
  'en-US': Locale.PT,
  'pt-BR': Locale.EN
};
