























































import { Component, Vue } from 'vue-property-decorator';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import TrillionDatasetSelect from '@/components/dataset/trillion-dataset-select.vue';
import TrillionDatasetDialog from '@/components/dataset/trillion-dataset-dialog.vue';
import { Action, Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import DataContext from '@/models/DataContext';
import { DATASET_MAIN_INDEX_CONSIDER } from '@/models/DataSet';

@Component({
  components: {
    TBreadcrumbs,
    TrillionDatasetSelect,
    TrillionDatasetDialog
  }
})
export default class CreateBatch extends Vue {
  @Action(EnrichmentStore.Actions.consultDataContexts)
  public consultDataContexts!: () => Promise<DataContext[]>;

  @Action(EnrichmentStore.Actions.uncheckBatchDataContexts)
  public uncheckDataContexts!: () => void;

  @Action(EnrichmentStore.Actions.updateDataContexts)
  public updateDataContexts!: (dataContexts: DataContext[]) => void;

  @Action(EnrichmentStore.Actions.updateDataContextTypeSelected)
  public updateContextTypeSelected!: (dataContextType: string) => void;

  @Getter(EnrichmentStore.Getters.getBatchDataContexts)
  public getDataContexts!: DataContext[];

  @Getter(EnrichmentStore.Getters.getDataContextTypeSelected)
  public dataContextTypeSelected!: string;

  public contexts: DataContext[] = [];

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT_BATCH.meta.title,
      EnrichmentRoutes.CONSULT_BATCH.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_NEW_BATCH.meta.title, '', true)
  ];

  public dialog = false;

  public get hasDataSetChecked() {
    return (
      this.contexts.filter(
        ctx => ctx.dataSets.filter(ds => ds.checked).length > 0
      ).length > 0
    );
  }

  public get hasDataSetGreaterThanTen() {
    return (
      this.contexts.length &&
      this.contexts.findOrFirst(
        (x: DataContext) => x.name === this.dataContextTypeSelected
      ).dataSets.length > DATASET_MAIN_INDEX_CONSIDER
    );
  }

  public async created() {
    if (this.getDataContexts.length === 0) {
      await this.consultDataContexts();
    }

    this.contexts = this.getDataContexts;
    this.updateContextTypeSelected(this.contexts[0].name);
  }

  public checkedDatasetSelect(dataset) {
    if (!dataset.main && !dataset.checked) {
      dataset.show = false;
    }
  }

  public checkedDatasetDialog(dataset) {
    if (!dataset.main && !dataset.checked) {
      dataset.show = false;
      return;
    }

    dataset.show = true;
  }

  public selectTab(dataContextSelected: string) {
    this.updateContextTypeSelected(dataContextSelected);
    this.uncheckDataContexts();
  }

  public nextConsultFileBatchPage() {
    if (!this.hasDataSetChecked) {
      return;
    }

    this.updateDataContexts(this.contexts);
    this.$router.push(EnrichmentRoutes.CONSULT_FILE_BATCH.path);
  }
}
