import { DialogType } from '@/utils/models/enums';

export class DialogModel {
  constructor(
    title: string,
    text: string,
    type: DialogType = DialogType.Warning,
    isConfirm = false
  ) {
    this.title = title;
    this.text = text;
    this.type = type;
    this.isConfirm = isConfirm;
  }

  public title: string;
  public text: string;
  public type: DialogType;
  public isConfirm: boolean;
}
