import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import vueI18n from './plugins/vuei18n';
import vueTheMask from 'vue-the-mask';
import isAuthorized from './components/directives/is-authorized-directive';
import numeral from 'numeral';
import moment from 'moment';
import VCalendar from 'v-calendar';
import formatNumber from '@/components/filters/formatNumber';

Vue.config.productionTip = false;
Vue.use(vueTheMask);
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});
Vue.directive(isAuthorized.id, isAuthorized.definition);
Vue.filter(formatNumber.id, formatNumber.definition);

moment.locale('pt-BR');

numeral.register('locale', 'pt-BR', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: 'R$'
  }
});

numeral.locale('pt-BR');

new Vue({
  router,
  store,
  vuetify,
  i18n: vueI18n,
  render: h => h(App)
}).$mount('#app');
