


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreControlPanel } from '@/apps/controlPanel/store/types';
import { StoreCtrlRole } from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.types';
import { StepEnum } from '@/apps/controlPanel/models/enums';

import DialogMessage from '@/components/dialog/DialogMessage.vue';

import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import { DialogModel } from '@/utils/models/dialogModel';
import { MainStore } from '@/store/types';
import RoleStep from '@/apps/controlPanel/components/role/role-step.vue';

@Component({
  components: {
    RoleStep,
    DialogMessage,
    TBreadcrumbs
  }
})
export default class extends Vue {
  @Prop({ required: false })
  public idRole: number | undefined;

  @Getter(StoreCtrlRole.Getters.getStepRoleForm)
  public step!: StepEnum;

  @Getter(StoreControlPanel.Getters.getValidationMessage)
  public validationMessage: string | undefined;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  public vueI18n = vueI18n;
  public breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.ROLE_LIST.meta.title,
      CtrlPanelRoutes.ROLE_LIST.path
    ),
    new BreadcrumbItem(
      this.$route.params.idRole
        ? CtrlPanelRoutes.ROLE_UPDATE.meta.title
        : CtrlPanelRoutes.ROLE_CREATE.meta.title,
      '',
      true
    )
  ];

  public confirm(): void {
    this.$router.push(CtrlPanelRoutes.ROLE_LIST);
  }

  public backToListRole(): void {
    this.$router.push(CtrlPanelRoutes.ROLE_LIST);
  }
}
