import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';
import Job from '@/apps/enrichment/models/job';

export default class JobService {
  public static consultJobs = (
    initialDate: string,
    finalDate: string
  ): Promise<Job[]> =>
    ServiceHelper.mapToPromise<Job[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/job?_initialDate=${initialDate}&_finalDate=${finalDate}`
    );

  public static createJob = (formData: FormData): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/enrichment/job`,
      {
        method: 'post',
        parameters: formData,
        axiosRequestConfig: {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      }
    );
}
