const base = 'ctrlPanel/role';

export const StoreCtrlRole = {
  Getters: {
    getRoles: `${base}/getRoles`,
    getRole: `${base}/getRole`,
    getStepRoleForm: `${base}/getStepRoleForm`,
    getFunctionalities: `${base}/getFunctionalities`,
    getFunctionalitiesByAdminRole: `${base}/getFunctionalitiesByAdminRole`,
    getDataSets: `${base}/getDataSets`,
    getTagDomains: `${base}/getTagDomains`,
    getIdRole: `${base}/getIdRole`,
    getRoleHasUser: `${base}/getRoleHasUser`
  },

  Mutations: {
    setRoles: 'setRoles',
    setRole: `setRole`,
    setStepRoleForm: `setStepRoleForm`,
    setFunctionalities: 'setFunctionalities',
    setDataSets: 'setDataSets',
    setTagDomains: 'setTagDomains',
    clearTagDomains: `${base}/clearTagDomains`,
    clearDataSets: `${base}/clearDataSets`,
    removeRoleFromList: `removeRoleFromList`,
    setRoleHasUser: 'setRoleHasUser'
  },

  Actions: {
    getAllRoles: `${base}/getAllRoles`,
    getRole: `${base}/getRole`,
    saveRole: `${base}/saveRole`,
    setDefaultRole: `${base}/setDefaultRole`,
    getFunctionalities: `${base}/getFunctionalities`,
    getTagDomains: `${base}/getTagDomains`,
    getDataSets: `${base}/getDataSets`,
    deleteRole: `${base}/deleteRole`,
    updateRole: `${base}/updateRole`,
    setRole: `${base}/setRole`,
    setStepRoleForm: `${base}/setStepRoleForm`,
    removeRoleFromList: `${base}/removeRoleFromList`,
    roleHasUser: `${base}/roleHasUser`,
    showDialogMessage: `${base}/showDialogMessage`
  }
};
