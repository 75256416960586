import IContextVuex from '@/utils/models/contracts/iContextVuex';

import { StoreCtrlRole } from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.types';

import RoleService from '@/apps/controlPanel/modules/role/services/role.service';
import Role from '@/apps/controlPanel/models/role';
import Functionality from '@/apps/controlPanel/models/functionality';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import DataSet from '@/apps/controlPanel/models/dataSet';
import TagDomain from '@/apps/controlPanel/models/tagDomain';
import { DialogType, ProductEnum } from '@/utils/models/enums';
import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import vueI18n from '@/plugins/vuei18n';

interface State {
  roles: Role[];
  role: Role;
  stepRoleForm: StepEnum;
  functionalities: Functionality[];
  dataSets: DataSet[];
  tagDomains: TagDomain[];
  idAdminFunctionalities: number[];
  idFunctionalities: number[];
  idDataSets: number[];
  idTagDomains: number[];
  roleHasUser: boolean;
}

const state = () => ({
  roles: new Array<Role>(),
  role: new Role(),
  stepRoleForm: StepEnum.First,
  functionalities: new Array<Functionality>(),
  dataSets: new Array<DataSet>(),
  tagDomains: new Array<TagDomain>(),
  idAdminFunctionalities: new Array<number>(),
  idFunctionalities: new Array<number>(),
  idDataSets: new Array<number>(),
  idTagDomains: new Array<number>(),
  roleHasUser: Boolean
});

const getters = {
  getRoles(state: State): Role[] {
    return state.roles;
  },

  getRole(state: State): Role {
    return state.role;
  },

  getStepRoleForm(state: State): StepEnum {
    return state.stepRoleForm;
  },

  getFunctionalities(state: State): Functionality[] {
    return state.functionalities;
  },

  getFunctionalitiesByAdminRole(
    state: State
  ): (product: ProductEnum) => Functionality[] {
    return (product: ProductEnum): Functionality[] => {
      return state.functionalities.filter(x => x.idProduct === product);
    };
  },

  getDataSets(state: State): DataSet[] {
    return state.dataSets;
  },

  getTagDomains(state: State): TagDomain[] {
    return state.tagDomains;
  },

  getIdRole(state: State): number {
    return state.role.id;
  },

  getRoleHasUser(state: State): boolean {
    return state.roleHasUser;
  }
};

const mutations = {
  setRoles(state: State, roles: Role[]) {
    state.roles = roles;
  },

  setRole(state: State, role: Role) {
    state.role = role;
  },

  setStepRoleForm(state: State, step: StepEnum) {
    state.stepRoleForm = step;
  },

  clearTagDomains(state: State) {
    state.tagDomains.length = 0;
  },

  setFunctionalities(state: State, functionalities: Functionality[]) {
    state.functionalities = functionalities;
  },

  setIdFunctionalities(state: State, functionalities: number[]) {
    state.idFunctionalities = functionalities;
  },

  setDataSets(state: State, dataSets: DataSet[]) {
    state.dataSets = dataSets;
  },

  setIdDataSets(state: State, dataSets: number[]) {
    state.idDataSets = dataSets;
  },

  clearDataSets(state: State) {
    state.dataSets.length = 0;
  },

  setTagDomains(state: State, tagDomains: TagDomain[]) {
    state.tagDomains = tagDomains;
  },

  setIdTagDomains(state: State, idDataSets: number[]) {
    state.idTagDomains = idDataSets;
  },

  removeRoleFromList(state: State, idRole: number) {
    const index = state.roles.findIndex(x => x.id === idRole);
    const qtdRemoved = 1;
    state.roles.splice(index, qtdRemoved);
  },

  setRoleHasUser(state: State, roleHasUser: boolean) {
    state.roleHasUser = roleHasUser;
  }
};

const actions = {
  setRole({ commit }, role: Role) {
    commit(StoreCtrlRole.Mutations.setRole, role);
  },

  getAllRoles(context: IContextVuex, idClient: number) {
    RoleService.getAllRoles(idClient).then(roles => {
      context.commit(StoreCtrlRole.Mutations.setRoles, roles);
    });
  },

  getRole(context: IContextVuex, idRole: number) {
    RoleService.getRole(idRole).then(role => {
      context.commit(
        StoreCtrlRole.Mutations.setRole,
        Object.assign(new Role(), role)
      );
    });
  },

  setDefaultRole(context: IContextVuex) {
    context.commit(StoreCtrlRole.Mutations.setRole, new Role());
  },

  saveRole(context: IContextVuex, role: Role) {
    RoleService.saveRole(role)
      .then((id: number) => {
        const dialogMessage: DialogModel = new DialogModel(
          '',
          '',
          DialogType.Information
        );

        if (id > 0) {
          dialogMessage.title = vueI18n
            .t('common.success_title_message')
            .toString();
          dialogMessage.text = vueI18n
            .t('common.success_text_message')
            .toString();
          dialogMessage.type = DialogType.Success;
        }

        if (id === 0) {
          dialogMessage.title = vueI18n
            .t('common.failure_title_message')
            .toString();
          dialogMessage.title = vueI18n
            .t('common.failure_text_message')
            .toString();
          dialogMessage.type = DialogType.Error;
        }

        context.commit(MainStore.Mutations.setDialogMessage, dialogMessage, {
          root: true
        });

        context.commit(MainStore.Mutations.toggleDialog, null, {
          root: true
        });
      })
      .catch(() => {
        context.commit(StoreCtrlRole.Mutations.setStepRoleForm, StepEnum.First);
      });
  },

  updateRole(context: IContextVuex, role: Role) {
    RoleService.updateRole(role)
      .then(() => {
        const dialogMessage: DialogModel = new DialogModel(
          vueI18n.t('common.success_title_message').toString(),
          vueI18n.t('common.success_text_message').toString(),
          DialogType.Success
        );

        context.commit(MainStore.Mutations.setDialogMessage, dialogMessage, {
          root: true
        });
        context.commit(MainStore.Mutations.toggleDialog, null, {
          root: true
        });
      })
      .catch(() => {
        context.commit(StoreCtrlRole.Mutations.setStepRoleForm, StepEnum.First);
      });
  },

  deleteRole(context: IContextVuex, idRole: number) {
    RoleService.deleteRole(idRole).then(() => {
      context.commit(
        MainStore.Mutations.setDialogMessage,
        new DialogModel(
          String(vueI18n.t('common.success_title_message')),
          String(vueI18n.t('common.success_text_message')),
          DialogType.Success
        ),
        { root: true }
      );

      context.commit(MainStore.Mutations.toggleDialog, {}, { root: true });
      context.commit(StoreCtrlRole.Mutations.removeRoleFromList, idRole);
    });
  },

  async getFunctionalities(context: IContextVuex, idClient: number) {
    await RoleService.getAllFunctionalities(idClient).then(functionalities => {
      context.commit(
        StoreCtrlRole.Mutations.setFunctionalities,
        functionalities
      );
    });
  },

  getDataSets(context: IContextVuex, idClient: number) {
    RoleService.getAllDataSets(idClient).then(dataSets => {
      context.commit(StoreCtrlRole.Mutations.setDataSets, dataSets);
    });
  },

  getTagDomains(context: IContextVuex, idClient: number) {
    RoleService.getAllTagDomains(idClient).then(tagDomains => {
      context.commit(StoreCtrlRole.Mutations.setTagDomains, tagDomains);
    });
  },

  setStepRoleForm(context: IContextVuex, stepEnum: StepEnum) {
    context.commit(StoreCtrlRole.Mutations.setStepRoleForm, stepEnum);
  },

  removeRoleFromList(context: IContextVuex, role: Role) {
    context.commit(StoreCtrlRole.Mutations.removeRoleFromList, role);
  },

  async roleHasUser(context: IContextVuex, role: Role) {
    await RoleService.RoleHasUser(role.id).then(haveRelUserRole => {
      context.commit(StoreCtrlRole.Mutations.setRoleHasUser, haveRelUserRole);
    });
  },

  showDialogMessage(context: IContextVuex, dialogModel: DialogModel) {
    context.commit(
      `${MainStore.Mutations.setDialogMessage}`,
      new DialogModel(dialogModel.title, dialogModel.text, dialogModel.type),
      { root: true }
    );

    context.commit(`${MainStore.Mutations.toggleDialog}`, null, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
