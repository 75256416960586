import { AuthUser } from '@/utils/models/authUser';
import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../config';

export default interface UserAuthentication {
  username: string;
  password: string;
}

export default class AuthService {
  public static login = (user: UserAuthentication): Promise<AuthUser> =>
    ServiceHelper.mapToPromise<AuthUser>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/User/authenticate`,
      {
        method: 'post',
        parameters: {
          Email: user.username,
          Password: user.password
        }
      }
    );
}
