const base = 'ctrlPanel/client';

export const StoreCtrlClient = {
  Getters: {
    getClients: `${base}/getClients`,
    getClient: `${base}/getClient`,
    getStepClientForm: `${base}/getStepClientForm`,
    getFunctionalities: `${base}/getFunctionalities`,
    getFunctionalitiesByProduct: `${base}/getFunctionalitiesByProduct`,
    getDataSources: `${base}/getDataSources`
  },

  Mutations: {
    setClients: 'setClients',
    setClient: `${base}/setClient`,
    setStepClientForm: `${base}/setStepClientForm`,
    clearTagDomains: `${base}/clearTagDomains`,
    setDataSources: `${base}/setDataSources`,
    clearDataSets: `${base}/clearDataSets`,
    setFunctionalities: `${base}/setFunctionalities`,
    setDataSets: `${base}/setDataSets`
  },

  Actions: {
    getAllClients: `${base}/getAllClients`,
    getClient: `${base}/getClient`,
    setDefaultClient: `${base}/setDefaultClient`,
    saveClient: `${base}/saveClient`,
    getFunctionalities: `${base}/getFunctionalities`,
    getDataSources: `${base}/getDataSources`
  }
};
