import Address from '@/apps/enrichment/models/address';
import Age from '@/apps/enrichment/models/Age';
import CompanyPartner from '@/apps/enrichment/models/companyPartner';

export default class People {
  constructor(
    gender: string,
    document: number,
    statusDocument: string,
    name: string,
    nameMother: string,
    birthDate: Date,
    emails: string[],
    phones: Phone[],
    addresses: Address[],
    age: Age,
    peoplesInSameAddress: People[],
    companies: CompanyPartner[],
    documentFormat: string
  ) {
    this.gender = gender;
    this.document = document;
    this.statusDocument = statusDocument;
    this.name = name;
    this.nameMother = nameMother;
    this.birthDate = birthDate;
    this.emails = emails;
    this.phones = phones;
    this.addresses = addresses.map(
      (address: Address) =>
        new Address(
          address.addressType,
          address.addressNumber,
          address.additionalAddress,
          address.address,
          address.neighborhood,
          address.city,
          address.state,
          address.cep,
          address.cepFormated,
          address.latitude,
          address.longitude,
          address.geocode,
          address.rank
        )
    );
    this.age = age;
    this.peoplesInSameAddress = peoplesInSameAddress;
    this.companies = companies;
    this.documentFormat = documentFormat;
    this.mainAddress = this.addresses.sortBy(x => x.rank)[0];
  }

  public gender = '';
  public document = 0;
  public statusDocument = '';
  public name = '';
  public nameMother = '';
  public birthDate: Date = new Date();
  public emails: string[] = new Array<string>();
  public phones: Phone[] = [];
  public addresses: Address[] = new Array<Address>();
  public age: Age = new Age();
  public peoplesInSameAddress: People[] = new Array<People>();
  public companies: CompanyPartner[] = new Array<CompanyPartner>();
  public documentFormat = '';
  public mainAddress: Address;
}

export interface Phone {
  phoneNumber: number;
  areaCode: number;
  type: number;
  phoneNumberFormat: string;
}
