const formatNumber = {
  id: 'formatNumber',
  definition: (value: number) => {
    if (isNaN(value)) {
      return 0;
    }

    return new Intl.NumberFormat('pt-BR').format(value);
  }
};

export default formatNumber;
