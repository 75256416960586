import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    themes: {
      light: {
        primary: '#5b7aa3',
        secondary: '#4E72A0',
        anchor: '#25c4a0',
        deleteBtn: '#ff5a5f'
      }
    }
  }
});
