








































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';
import { Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';

import Company from '@/apps/enrichment/models/company';
import DataUnavailable from '@/apps/enrichment/components/data-unavailable.vue';
import BtnLinkConsult from '@/apps/enrichment/components/btn-link-consult.vue';
import TListPhones from '@/apps/enrichment/components/list-phones.vue';
import TTextEmpty from '@/apps/enrichment/components/text-empty.vue';

@Component({
  name: 'CnpjDetailCard',
  components: {
    DataUnavailable,
    BtnLinkConsult,
    TListPhones,
    TTextEmpty
  }
})
export default class CnpjDetailCard extends Vue {
  @Getter(EnrichmentStore.Getters.getCompanyFetched)
  public companyFetched: Company | undefined;

  @Prop({ required: true })
  public subsidiarySummary!: any[];

  get companyType() {
    return this.companyFetched?.isMatrix ? 'Matriz' : 'Filial';
  }

  public vueI18n = vueI18n;
  public moment = moment;
}
