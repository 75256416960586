


































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';

import People from '@/apps/enrichment/models/people';
import DataUnavailable from '@/apps/enrichment/components/data-unavailable.vue';
import BtnLinkConsult from '@/apps/enrichment/components/btn-link-consult.vue';
import TTextEmpty from '@/apps/enrichment/components/text-empty.vue';

@Component({
  name: 'CpfDetailTable',
  components: {
    BtnLinkConsult,
    DataUnavailable,
    TTextEmpty
  }
})
export default class CpfDetailTable extends Vue {
  @Getter(EnrichmentStore.Getters.getPeopleFetched)
  public peopleFetched: People | undefined;

  public vueI18n = vueI18n;
  public moment = moment;

  get unavailableText() {
    return vueI18n.t('enrichment.dataNotFound', { prop: 'CPF' });
  }

  get phoneTableTwoColumns() {
    if (!this.peopleFetched || !this.peopleFetched.phones.length) {
      return [];
    }

    let index1 = 1;
    let index2 = 2;

    const numberColumns = Math.round(this.peopleFetched.phones.length / 2);

    const phoneTable = {
      column1: this.peopleFetched.phones.slice(0, numberColumns).map(x => {
        const result = {
          value: x.phoneNumberFormat,
          index: index1
        };
        index1 = index1 + 2;
        return result;
      }),

      column2: this.peopleFetched.phones.slice(numberColumns).map(x => {
        const result = {
          value: x.phoneNumberFormat,
          index: index2
        };
        index2 = index2 + 2;
        return result;
      })
    };

    return Array(numberColumns)
      .fill(0)
      .map((_, index) => ({
        column1: phoneTable.column1[index],
        column2: phoneTable.column2[index]
      }));
  }
}
