import { CONFIG } from '../../../../config';
import { ServiceHelper } from '@/utils/service.helper';

export default class PowerBiService {
  public static getPowerBiConfig = (
    idWorkspace: string,
    idReport: string
  ): Promise<any> =>
    ServiceHelper.mapToPromise<any>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/powerbi/${idWorkspace}/config/${idReport}`
    );
}
