export const DATASET_MAIN_INDEX_CONSIDER = 5;

export default class DataSet {
  constructor(
    public readonly id = 0,
    public readonly name = '',
    public readonly main: boolean = false,
    checked = false,
    show = false
  ) {
    this.checked = checked;
    this.show = show;
  }

  public checked: boolean;
  public show: boolean;
}
