import Role from '@/apps/controlPanel/models/role';
import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../../../config';
import Functionality from '@/apps/controlPanel/models/functionality';
import DataSet from '@/apps/controlPanel/models/dataSet';
import TagDomain from '@/apps/controlPanel/models/tagDomain';

export default class RoleService {
  public static getAllRoles = (idClient: number): Promise<Role[]> =>
    ServiceHelper.mapToPromise<Role[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idClient}/all`
    );

  public static getRole = (idRole: number): Promise<Role> =>
    ServiceHelper.mapToPromise<Role>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idRole}`
    );

  public static saveRole = (roleDto: Role): Promise<number> =>
    ServiceHelper.mapToPromise<number>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/create`,
      { method: 'post', parameters: roleDto }
    );

  public static updateRole = (roleDto: Role): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/update`,
      { method: 'put', parameters: roleDto }
    );

  public static deleteRole = (idRole: number): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idRole}`,
      { method: 'delete' }
    );

  public static getAllFunctionalities = (
    idClient: number
  ): Promise<Functionality[]> =>
    ServiceHelper.mapToPromise<Functionality[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idClient}/adminFunctionalities`
    );

  public static getAllDataSets = (idClient: number): Promise<DataSet[]> =>
    ServiceHelper.mapToPromise<DataSet[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idClient}/adminDataSets`
    );

  public static getAllTagDomains = (idClient: number): Promise<TagDomain[]> =>
    ServiceHelper.mapToPromise<TagDomain[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idClient}/adminTagDomains`
    );

  public static RoleHasUser = (idRole: number): Promise<boolean> =>
    ServiceHelper.mapToPromise<boolean>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/role/${idRole}/RoleHasUser`
    );
}
