




















































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import TwoLineItem from '@/components/list-item/TwoLineListItem.vue';
import OneLineItemAction from '@/components/list-item/OneLineListItemButtonAction.vue';

import Client from '@/apps/controlPanel/models/client';

@Component({
  components: {
    OneLineItemAction,
    TwoLineItem
  }
})
export default class LimitRequest extends Vue {
  @Getter(StoreCtrlClient.Getters.getClient)
  private client!: Client;

  private vueI18n: any = vueI18n;
  private isEditing: any = false;

  private editDomain() {
    this.isEditing = true;
  }

  private save() {
    this.isEditing = false;
  }
}
