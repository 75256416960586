import IContextVuex from '@/utils/models/contracts/iContextVuex';
import EnrichmentService from '@/apps/enrichment/services/enrichment.service';
import vuei18n from '@/plugins/vuei18n';

import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import { EnrichmentStore } from '@/apps/enrichment/store/types';
import {
  JobFilter,
  ReportFilter,
  TabResult,
  TabResultType
} from '@/apps/enrichment/models/types';

import { DataReport } from '@/apps/enrichment/models/consult-report/dataReport';
import { DataReportDetailed } from '@/apps/enrichment/models/consult-report/dataReportDetailed';

import People from '@/apps/enrichment/models/people';
import Company from '@/apps/enrichment/models/company';
import Address from '@/apps/enrichment/models/address';
import { DialogType } from '@/utils/models/enums';
import ConsultReturn from '@/apps/enrichment/models/consultReturn';
import JobService from '@/apps/enrichment/services/job.service';
import Job from '@/apps/enrichment/models/job';
import DataContext from '@/models/DataContext';
import DataSet, { DATASET_MAIN_INDEX_CONSIDER } from '@/models/DataSet';
import Report from '../models/report';
import store from '@/store';
import { AuthStore } from '@/apps/auth/store/types';
import FeaturePermission from '@/utils/feature-permission';
import DiscoverOwnerJob from '../models/discover-owner/discoverOwnerJob';
import DiscoverOwnerService from '../services/discoverowner.service';

const storageTabResultSelectedKey = 'auth-tab-result-selected';

export interface DocumentSearch {
  document: string;
  date: string;
}

interface State {
  peopleFetched: People;
  companyFetched: Company;
  companySearch: DocumentSearch;
  addressFetched: Address;
  addressSearch: Address;
  tabResultSelected: TabResult;
  peopleSearch: DocumentSearch;
  batchDataContexts: DataContext[];
  dataContextTypeSelected: string;
  dataReportsPerson: DataReport[];
  dataReportsConsult: DataReport[];
  qtdTotalConsults: number;
  jobs: Job[];
  discoverOwnerJobs: DiscoverOwnerJob[];
}

const state = () => ({
  peopleFetched: People,
  companyFetched: {},
  addressFetched: new Address(),
  addressSearch: new Address(),
  tabResultSelected: TabResult.Grid,
  companySearch: { document: '', date: '' },
  peopleSearch: { document: '', date: '' },
  dataReportsPerson: new Array<DataReport>(),
  dataReportsConsult: new Array<DataReport>(),
  batchDataContexts: [],
  dataContextTypeSelected: '',
  qtdTotalConsults: 0,
  jobs: new Array<Job>(),
  discoverOwnerJobs: new Array<DiscoverOwnerJob>(),
});

const getters = {
  getPeopleFetched(state: State): People {
    return state.peopleFetched;
  },

  getPeopleSearch(state: State): DocumentSearch {
    return state.peopleSearch;
  },

  getCompanyFetched(state: State): Company {
    return state.companyFetched;
  },

  getCompanySearch(state: State): DocumentSearch {
    return state.companySearch;
  },

  getAddressFetched(state: State): Address {
    return state.addressFetched;
  },

  getAddressSearch(state: State): Address {
    return state.addressSearch;
  },

  getTabResultSelected(state: State): TabResult {
    const resultType = localStorage.getItem(storageTabResultSelectedKey);
    if (resultType) {
      state.tabResultSelected = resultType as TabResult;
    }

    return state.tabResultSelected;
  },

  getBatchDataContexts(state: State): DataContext[] {
    return state.batchDataContexts;
  },

  getDataContextTypeSelected(state: State): string {
    return state.dataContextTypeSelected;
  },

  getDataReportPerson(state: State) {
    return state.dataReportsPerson;
  },

  getDataReportConsult(state: State) {
    return state.dataReportsConsult;
  },

  getQtdTotalConsults(state: State) {
    return state.qtdTotalConsults;
  },

  getJobs(state: State) {
    return state.jobs;
  },

  getDiscoverOwnerJobs(state: State) {
    return state.discoverOwnerJobs;
  },
};

const mutations = {
  setPeopleFetched(state: State, people: People) {
    state.peopleFetched = people;
  },

  setCompanyFetched(state: State, company: Company) {
    state.companyFetched = company;
  },

  setAddressFetched(state: State, address: Address) {
    state.addressFetched = address;
  },

  setAddressSearch(state: State, address: Address) {
    state.addressSearch = address;
  },

  setTabResultSelected(state: State, tabResult: TabResult) {
    if (tabResult as TabResultType) {
      localStorage.setItem(storageTabResultSelectedKey, tabResult);
      state.tabResultSelected = tabResult;
    }
  },

  setPeopleSearch(state: State, search: DocumentSearch) {
    state.peopleSearch = search;
  },

  setCompanySearch(state: State, search: DocumentSearch) {
    state.companySearch = search;
  },

  setDataReportPerson(state: State, dataReports: DataReport[]) {
    state.dataReportsPerson = dataReports;
  },

  setDataReportConsult(state: State, dataReports: DataReport[]) {
    state.dataReportsConsult = dataReports;
  },

  setBatchDataContexts(state: State, dataContexts: DataContext[]) {
    state.batchDataContexts = dataContexts;
  },

  setDataContextTypeSelected(state: State, dataContextType: string) {
    state.dataContextTypeSelected = dataContextType;
  },

  setQtdTotalConsults(state: State, qtdTotalConsults: number) {
    state.qtdTotalConsults = qtdTotalConsults;
  },

  setJobs(state: State, dataJobs: Job[]) {
    state.jobs = dataJobs;
  },

  setDiscoverOwnerJobs(state: State, discoverOwnerJobs: DiscoverOwnerJob[]) {
    state.discoverOwnerJobs = discoverOwnerJobs;
  }
};

const actions = {
  getDiscoverOwnerJobs(context: IContextVuex): Promise<void> {
    return DiscoverOwnerService.getDiscoverOwnerJobs().then((jobs: any) => {
      const discoverOwnerJobs = jobs.map(
        (job: any) =>
          new DiscoverOwnerJob(
            job.id,
            job.name,
            job.status,
            job.type,
            job.statusDesc,
            job.typeDesc,
            job.chassisCount,
            job.processCount,
            job.ownersCount,
            job.providerDesc,
            job.createdAtFormatted,
            job.updatedAtFormatted,
            job.createdAt,
            job.updatedAt,
            job.elapsedAt
          )
      );

      context.commit(
        EnrichmentStore.Mutations.setDiscoverOwnerJobs,
        discoverOwnerJobs,
        { root: true }
      );
    });
  },
  consultCpf(context: IContextVuex, document: number) {
    context.commit(EnrichmentStore.Mutations.setPeopleFetched, {});
    EnrichmentService.consultCpf(document).then((p: ConsultReturn<People>) => {
      if (!p || p.status === 204) {
        context.commit(
          `${MainStore.Mutations.setDialogMessage}`,
          new DialogModel(
            vuei18n.t('common.attention').toString(),
            vuei18n.t('enrichment.dataNotFound', { prop: 'CPF' }).toString(),
            DialogType.Information
          ),
          { root: true }
        );

        context.commit(
          `${MainStore.Mutations.toggleDialog}`,
          {},
          { root: true }
        );

        return;
      }

      context.commit(
        EnrichmentStore.Mutations.setPeopleFetched,
        new People(
          p.result.gender,
          p.result.document,
          p.result.statusDocument,
          p.result.name,
          p.result.nameMother,
          p.result.birthDate,
          p.result.emails,
          p.result.phones,
          p.result.addresses,
          p.result.age,
          p.result.peoplesInSameAddress,
          p.result.companies,
          p.result.documentFormat
        )
      );
    });
  },

  consultCnpj(context: IContextVuex, document: number) {
    context.commit(EnrichmentStore.Mutations.setCompanyFetched, {});
    EnrichmentService.consultCnpj(document).then(
      (company: ConsultReturn<Company>) => {
        if (!company || company.status === 204) {
          context.commit(
            `${MainStore.Mutations.setDialogMessage}`,
            new DialogModel(
              vuei18n.t('common.attention').toString(),
              vuei18n.t('enrichment.dataNotFound', { prop: 'CNPJ' }).toString(),
              DialogType.Information
            ),
            { root: true }
          );

          context.commit(
            `${MainStore.Mutations.toggleDialog}`,
            {},
            { root: true }
          );
        }

        context.commit(
          EnrichmentStore.Mutations.setCompanyFetched,
          new Company(
            company.result.documentSituation,
            company.result.document,
            company.result.socialReason,
            company.result.openedAt,
            company.result.isMatrix,
            company.result.subsidieries,
            company.result.billingEstimated,
            company.result.employee,
            company.result.emails,
            company.result.phones,
            company.result.addresses,
            company.result.documentFormat,
            company.result.cnaes,
            company.result.partners,
            company.result.administrativePartners
          )
        );
      }
    );
  },

  consultCep(context: IContextVuex, address: Address) {
    context.commit(EnrichmentStore.Mutations.setAddressFetched, {});

    const servicesByPermission = () => {
      const isAuthorized: (feature: string) => boolean =
        store.getters[AuthStore.Getters.isAuthorized];
      const result: Array<Promise<ConsultReturn<Address>>> = [];

      if (isAuthorized(FeaturePermission.enrichment.consultCpfByCep)) {
        result.push(EnrichmentService.consultCpfByCep(address));
      }

      if (isAuthorized(FeaturePermission.enrichment.consultCnpjByCep)) {
        result.push(EnrichmentService.consultCnpjByCep(address));
      }

      return result;
    };

    Promise.all([...servicesByPermission()]).then(values => {
      const address: ConsultReturn<Address> = values.reduce(
        (address: any, value: any) => {
          if (!address.result) {
            return { ...value };
          }

          if (!address.result.peoples.length) {
            address.result.peoples = value.result.peoples;
          }

          if (!address.result.companies.length) {
            address.result.companies = value.result.companies;
          }

          return address;
        },
        {}
      );

      if (
        !address ||
        (!address.result.companies.length && !address.result.peoples.length)
      ) {
        context.commit(
          `${MainStore.Mutations.setDialogMessage}`,
          new DialogModel(
            vuei18n.t('common.attention').toString(),
            vuei18n.t('enrichment.dataNotFound', { prop: 'CEP' }).toString(),
            DialogType.Information
          ),
          { root: true }
        );

        context.commit(
          `${MainStore.Mutations.toggleDialog}`,
          {},
          { root: true }
        );
      }

      context.commit(EnrichmentStore.Mutations.setAddressFetched, {
        ...address.result
      });
    });
  },

  searchDataReport(context: IContextVuex, prop: ReportFilter) {
    EnrichmentService.getDataReport(
      prop.idClient,
      prop.initialDate,
      prop.finalDate,
      prop.reportType
    ).then((dataReports: Report[]) => {
      let qtdTotalConsults = 0;

      if (dataReports.length === 0) {
        context.commit(
          `${MainStore.Mutations.setDialogMessage}`,
          new DialogModel(
            vuei18n.t('common.attention').toString(),
            vuei18n.t('enrichment-report.textMessageEmptyReturn').toString(),
            DialogType.Warning
          ),
          { root: true }
        );

        context.commit(`${MainStore.Mutations.toggleDialog}`, null, {
          root: true
        });

        context.commit(
          EnrichmentStore.Mutations.setQtdTotalConsults,
          qtdTotalConsults
        );

        return;
      }

      const dataReportPersonArray = new Array<DataReport>();
      const dataReportConsultArray = new Array<DataReport>();

      const dataReportReduced = Array.from(
        dataReports
          .reduce((acc, curr) => {
            const key = `${curr.username}||${curr.dataSetName}`;
            const item = acc.get(key) || {
              ...curr,
              consultType: curr.dataSetName.toLocaleUpperCase(),
              qtd: 0
            };
            item.qtd++;
            return acc.set(key, item);
          }, new Map())
          .values()
      );

      // PersonData
      const usernameArray = dataReportReduced
        .map(x => x.username)
        .filter((value, index, self) => self.indexOf(value) === index);

      usernameArray.forEach(username => {
        const dataReportObject = new DataReport();
        const dataReportsByPerson = dataReportReduced.filter(
          x => x.username === username
        );

        dataReportObject.name = username;
        dataReportObject.qtdConsultsUsed = `${dataReportsByPerson.length} consulta(s)`;

        dataReportsByPerson.forEach(item => {
          const dataReportDetailedObject = new DataReportDetailed();

          dataReportObject.qtdAllConsults += item.qtd;

          dataReportDetailedObject.name = item.consultType;
          dataReportDetailedObject.qtd = item.qtd;

          dataReportObject.dataReportDetailed.push(dataReportDetailedObject);
        });

        dataReportPersonArray.push(dataReportObject);
      });

      // ConsultData
      const consultTypeArray = dataReportReduced
        .map(x => x.consultType)
        .filter((value, index, self) => self.indexOf(value) === index);

      consultTypeArray.forEach(consultType => {
        const dataReportObject = new DataReport();
        const dataReportsByConsultType = dataReportReduced.filter(
          x => x.consultType === consultType
        );

        dataReportObject.name = consultType;
        dataReportObject.qtdConsultsUsed = `${dataReportsByConsultType.length} consulta(s)`;

        dataReportsByConsultType.forEach(item => {
          const dataReportDetailedObject = new DataReportDetailed();

          dataReportObject.qtdAllConsults += item.qtd;

          dataReportDetailedObject.name = item.username;
          dataReportDetailedObject.qtd = item.qtd;

          dataReportObject.dataReportDetailed.push(dataReportDetailedObject);
        });

        qtdTotalConsults += dataReportObject.qtdAllConsults;
        dataReportConsultArray.push(dataReportObject);
      });

      context.commit(
        EnrichmentStore.Mutations.setDataReportPerson,
        dataReportPersonArray
      );
      context.commit(
        EnrichmentStore.Mutations.setDataReportConsult,
        dataReportConsultArray
      );
      context.commit(
        EnrichmentStore.Mutations.setQtdTotalConsults,
        qtdTotalConsults
      );
    });
  },

  showDialogMessage(context: IContextVuex, dialogModel: DialogModel) {
    context.commit(
      `${MainStore.Mutations.setDialogMessage}`,
      new DialogModel(dialogModel.title, dialogModel.text, dialogModel.type),
      { root: true }
    );

    context.commit(`${MainStore.Mutations.toggleDialog}`, null, { root: true });
  },

  clearDataReports(context: IContextVuex) {
    const dataReportEmptyArray = new Array<DataReport>();

    context.commit(
      EnrichmentStore.Mutations.setDataReportPerson,
      dataReportEmptyArray
    );
    context.commit(
      EnrichmentStore.Mutations.setDataReportConsult,
      dataReportEmptyArray
    );
  },

  uncheckBatchDataContexts({ commit, state }) {
    commit(
      EnrichmentStore.Mutations.setBatchDataContexts,
      state.batchDataContexts.map((ctx: DataContext) => ({
        ...ctx,
        dataSets: ctx.dataSets.map(ds => {
          ds.show = false;
          ds.checked = false;

          return ds;
        })
      })),
      { root: true }
    );
  },

  clearDataContexts(context: IContextVuex) {
    context.commit(EnrichmentStore.Mutations.setBatchDataContexts, [], {
      root: true
    });
  },

  updateDataContexts(context: IContextVuex, dataContexts: DataContext[]) {
    context.commit(
      EnrichmentStore.Mutations.setBatchDataContexts,
      dataContexts,
      { root: true }
    );
  },

  updateDataContextTypeSelected(
    context: IContextVuex,
    dataContextType: string
  ) {
    context.commit(
      EnrichmentStore.Mutations.setDataContextTypeSelected,
      dataContextType,
      { root: true }
    );
  },

  consultDataContexts(context: IContextVuex): Promise<DataContext[]> {
    context.commit(EnrichmentStore.Mutations.setBatchDataContexts, [], {
      root: true
    });

    return EnrichmentService.getDataContexts().then(
      (dataContexts: DataContext[]) => {
        const contexts = dataContexts.map(
          (data: DataContext) =>
            new DataContext(
              data.id,
              data.name,
              data.dataSets.map(
                (ds, index) =>
                  new DataSet(
                    ds.id,
                    ds.name,
                    index < DATASET_MAIN_INDEX_CONSIDER
                  )
              )
            )
        );

        context.commit(
          EnrichmentStore.Mutations.setBatchDataContexts,
          contexts,
          {
            root: true
          }
        );

        return contexts;
      }
    );
  },

  getJobsByPeriod(context: IContextVuex, jobFilter: JobFilter) {
    context.commit(EnrichmentStore.Mutations.setJobs, [], { root: true });
    JobService.consultJobs(jobFilter.initialDate, jobFilter.finalDate).then(
      (data: Job[]) => {
        const jobs = data?.map(
          job =>
            new Job(
              job.id,
              job.name,
              job.username,
              job.initialDate,
              job.endDate,
              job.documentCount,
              job.errorCount,
              job.nullCount,
              job.pendingCount,
              job.processingCount,
              job.successCount,
              job.statusPercent,
              []
            )
        );
        context.commit(EnrichmentStore.Mutations.setJobs, jobs, { root: true });
      }
    );
  },

  createJob(context: IContextVuex, formData: FormData) {
    JobService.createJob(formData).then(() => {
      context.commit(
        `${MainStore.Mutations.setDialogMessage}`,
        new DialogModel(
          vuei18n.t('common.success_title_message').toString(),
          vuei18n.t('common.success_text_message').toString(),
          DialogType.Success
        ),
        { root: true }
      );

      context.commit(`${MainStore.Mutations.toggleDialog}`, null, {
        root: true
      });
    });
  },

  clearAddressData(context: IContextVuex) {
    context.commit(EnrichmentStore.Mutations.setAddressFetched, {});
  },

  clearPeopleData(context: IContextVuex) {
    context.commit(EnrichmentStore.Mutations.setPeopleFetched, {});
  },

  clearCompanyData(context: IContextVuex) {
    context.commit(EnrichmentStore.Mutations.setCompanyFetched, {});
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
