import vueI18n from '@/plugins/vuei18n';

export default class DocumentValidator {
  public static regexRules = {
    telephone: [
      (v: string) =>
        !!v ||
        `${vueI18n.t('client-create-page.textInputTelephone')} ${vueI18n.t(
          'common.required'
        )}`
    ],

    clientName: [
      (v: string) =>
        !!v ||
        `${vueI18n.t('client-create-page.textInputName')} ${vueI18n.t(
          'common.required'
        )}`
    ],

    companyName: [
      (v: string) =>
        !!v ||
        `${vueI18n.t('client-create-page.textInputCompanyName')} ${vueI18n.t(
          'common.required'
        )}`
    ],

    email: [
      (v: string) => !!v || `E-mail ${vueI18n.t('common.required')}`,
      (v: string) =>
        /@[a-z]..+\..+./.test(v) || `E-mail ${vueI18n.t('common.invalid')}`
    ],

    cpf: [
      (v: string) => !!v || `CPF ${vueI18n.t('common.required')}`,
      (v: string) =>
        (v != null && DocumentValidator.isCpfValid(v)) ||
        `CPF ${vueI18n.t('common.invalid')}`
    ],

    cnpj: [
      (v: string) => !!v || `CNPJ ${vueI18n.t('common.required')}`,
      (v: string) =>
        (v != null && DocumentValidator.isCnpjValid(v)) ||
        `CNPJ ${vueI18n.t('common.invalid')}`
    ],

    cep: [(v: string) => !!v || `CEP ${vueI18n.t('common.required')}`]
  };

  public static isCpfValid(strCPF: any): any {
    strCPF = strCPF.replace(/\D+/g, '');
    let rest;
    let sum = 0;

    const invalidCPF = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999'
    ];

    if (invalidCPF.filter(x => x === strCPF).length > 0) {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    if (rest !== parseInt(strCPF.substring(9, 10), 10)) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    }

    rest = (sum * 10) % 11;

    if (rest === 10 || rest === 11) {
      rest = 0;
    }

    return rest === parseInt(strCPF.substring(10, 11), 10);
  }

  public static isCnpjValid(cnpj: any): any {
    cnpj = cnpj.replace(/\D+/g, '');

    if (cnpj === '' || cnpj.length !== 14) {
      return false;
    }

    const invalidCNPJ = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999'
    ];

    if (invalidCNPJ.filter(x => x === cnpj).length > 0) {
      return false;
    }

    let position: number;
    let result: number;
    let sum = 0;
    let quantityDigits = 12;
    let numbers = cnpj.substring(0, quantityDigits);

    const digits = cnpj.substring(quantityDigits);
    const divisionModule = 11;
    const referenceValueOfCNPJ = 2;
    const rulesPosition = {
      first: {
        position: 5
      },
      second: {
        position: 6
      },
      numberRegistration: {
        lastPosition: 2
      },
      filialUniqueCode: {
        firstPosition: 9,
        lastPosition: 2
      }
    };

    position = rulesPosition.first.position;

    for (let i = quantityDigits; i >= 1; i--) {
      sum += numbers.charAt(quantityDigits - i) * position--;

      if (position < rulesPosition.numberRegistration.lastPosition) {
        position = rulesPosition.filialUniqueCode.firstPosition;
      }
    }

    result =
      sum % divisionModule < referenceValueOfCNPJ
        ? 0
        : divisionModule - (sum % divisionModule);

    if (result !== parseInt(digits.charAt(0), 10)) {
      return false;
    }

    quantityDigits++;
    numbers = cnpj.substring(0, quantityDigits);
    position = rulesPosition.second.position;
    sum = 0;

    for (let i = quantityDigits; i >= 1; i--) {
      sum += numbers.charAt(quantityDigits - i) * position--;

      if (position < rulesPosition.filialUniqueCode.lastPosition) {
        position = rulesPosition.filialUniqueCode.firstPosition;
      }
    }

    result =
      sum % divisionModule < referenceValueOfCNPJ
        ? 0
        : divisionModule - (sum % divisionModule);

    return result === parseInt(digits.charAt(1), 10);
  }
}
