import { render, staticRenderFns } from "./cpf-detail-card.vue?vue&type=template&id=88ef1178&scoped=true&"
import script from "./cpf-detail-card.vue?vue&type=script&lang=ts&"
export * from "./cpf-detail-card.vue?vue&type=script&lang=ts&"
import style0 from "./cpf-detail-card.vue?vue&type=style&index=0&id=88ef1178&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ef1178",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VList,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSimpleTable})
