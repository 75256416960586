




























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ReportDataTable'
})
export default class ReportDataTable extends Vue {
  @Prop({ required: true })
  public headers!: string[];

  @Prop({ required: true })
  public data!: string[];

  @Prop({ required: true })
  public loading!: string[];

  @Prop({ required: true })
  public qtdTotalConsults!: number;
}
