import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const currentLocale = localStorage.getItem('current-locale') || 'pt-BR';
const loadLocaleMessages = () => {
  const locales = require.context(
    '../assets/locales/',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: any = {};
  locales.keys().forEach((key: any) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
};

export default new VueI18n({
  locale: currentLocale,
  fallbackLocale: currentLocale,
  messages: loadLocaleMessages()
});
