






















































































































import { Component, Vue } from 'vue-property-decorator';

import moment from 'moment';
import vuei18n from '@/plugins/vuei18n';
import { Action, Getter } from 'vuex-class';
import { MainStore } from '@/store/types';

import TrillionDatePicker from '@/components/trillion-date-picker.vue';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { EnrichmentRoutes } from '../../router';
import { DialogModel } from '@/utils/models/dialogModel';
import { DialogType } from '@/utils/models/enums';

import Campaign from '@/apps/enrichment/models/discover-owner/campaign';
import DiscoverOwnerService from '@/apps/enrichment/services/discoverowner.service';

const DEFAULT_RELOAD_COUNT = 3600;
const MAX_DAYS_OF_PERIOD = 31;

@Component({
  components: {
    TBreadcrumbs,
    TrillionDatePicker,
    DialogMessage
  }
})
export default class CampaignList extends Vue {
  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

  @Getter(MainStore.Getters.getLoading)
  public isLoading!: boolean;

  public enrichmentRoutes = EnrichmentRoutes;

  public count = DEFAULT_RELOAD_COUNT;

  public interval;

  public windowSize = {
    x: 0,
    y: 0
  };

  public campaigns: Campaign[] = [];

  public headers = [
    {
      text: 'ID',
      value: 'id',
      sortable: true,
      width: '100px',
      align: 'center'
    },
    { text: 'Name', value: 'name', sortable: true, width: '200px' },
    { text: 'Criado em', value: 'createdAt', sortable: true, width: '160px' },
    {
      text: 'Processados/Total',
      value: 'processed',
      sortable: true,
      width: '300px'
    },
    { text: 'Coletados/Total', value: 'ownerQuantity', sortable: true },
    {
      text: 'Ordem',
      value: 'order',
      sortable: true,
      align: 'center',
      width: '150px'
    },
    {
      text: 'Opções',
      value: 'options',
      align: 'center',
      width: '150px'
    }
  ];

  public dates: { start: Date; end: Date } = {
    start: moment()
      .startOf('month')
      .toDate(),
    end: moment()
      .endOf('month')
      .toDate()
  };

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(
      EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.meta.title,
      '',
      true
    )
  ];

  public created() {
    this.load();
  }

  public changeDate() {
    this.load();
  }

  public destroyed() {
    clearInterval(this.interval);
  }

  public doUpdateOrder(id: number, order: number) {
    DiscoverOwnerService.updateCampaignOrder(id, order);
  }

  public onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight };
  }

  public onDownloadCampaign(idCampaign: number, name: string) {
    DiscoverOwnerService.downloadCampaign({ id: idCampaign, name })
      .then(() => {
        this.showDialogMessage(
          new DialogModel(
            vuei18n.t('common.success_title_message').toString(),
            vuei18n.t('common.success_text_message').toString(),
            DialogType.Success
          )
        );
      })
      .catch(() => {
        this.showDialogMessage(
          new DialogModel(
            vuei18n.t('enrichment-job.titleErrorMessage').toString(),
            vuei18n.t('enrichment-job.textErrorMessage').toString(),
            DialogType.Error
          )
        );
      });
  }

  private load() {
    if (this.InvalidPeriod()) {
      this.showDialogMessage(
        new DialogModel(
          vuei18n.t('enrichment-job.titleMessage').toString(),
          vuei18n.t('enrichment-job.textMessage').toString(),
          DialogType.Information
        )
      );
      return;
    }

    clearInterval(this.interval);
    this.resetCount();

    DiscoverOwnerService.consultCampaigns(
      this.dates.start.toISOString(),
      this.dates.end.toISOString()
    )
      .then(data =>
        data.map(
          x =>
            new Campaign(
              x.id,
              x.name,
              x.createdAt,
              x.processed,
              x.total,
              x.ownerQuantity,
              x.order
            )
        )
      )
      .then(data => {
        this.createIntervalReload();
        return (this.campaigns = data);
      });
  }

  private InvalidPeriod() {
    const diffPeriod = moment(this.dates.end).diff(
      moment(this.dates.start),
      'days'
    );
    return diffPeriod >= MAX_DAYS_OF_PERIOD;
  }

  private resetCount() {
    this.count = DEFAULT_RELOAD_COUNT;
  }

  private createIntervalReload() {
    this.interval = setInterval(() => {
      this.count--;

      if (this.count === 0) {
        this.load();
      }
    }, 1000);
  }

  private getProgressColor(value) {
    const progress = [
      { reference: 25, color: 'red' },
      { reference: 50, color: 'orange' },
      { reference: 75, color: 'teal' },
      { reference: 100, color: 'primary' }
    ].filter(x => value * 100 <= x.reference)[0];

    return progress ? progress.color : 'red';
  }
}
