export default class EventName {
  public static readonly Common = {
    save: 'save',
    update: 'update'
  };

  public static readonly Role = {
    confirm: 'confirm',
    back: 'back',
    backToListRole: 'backToListRole',
    setStepRoleForm: 'setStepRoleForm'
  };

  public static readonly Client = {
    addNewDomain: 'addNewDomain'
  };

  public static readonly User = {
    changeToggleDialogModal: 'changeToggleDialogModal',
    getAllRoles: 'getAllRoles',
    setDefaultRole: 'setDefaultRole'
  };
}
