




































































































































import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import { Component, Prop, Vue } from 'vue-property-decorator';
import User from '@/apps/controlPanel/models/user';
import vueI18n from '@/plugins/vuei18n';
import PageDescription from '@/components/PageDescription.vue';
import Role from '@/apps/controlPanel/models/role';
import { DialogModel } from '@/utils/models/dialogModel';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import DocumentValidator from '@/utils/documentValidator';
import { Getter } from 'vuex-class';
import { MainStore } from '@/store/types';
import EventName from '@/apps/controlPanel/models/event-name';

@Component({
  components: {
    PageDescription,
    DialogMessage
  }
})
export default class UserUpdateForm extends Vue {
  @Prop({ required: true })
  public roles!: Role[];

  @Prop({ required: true })
  public user!: User;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  public rules = {
    maxlength: {
      name: 50,
      password: 15,
      email: 50,
      department: 100
    },
    name: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t('user-create-page.textInputName')} ${this.vueI18n.t(
          'common.required'
        )}`,
      (v: string) =>
        (!!v && v.length >= 3) ||
        `${this.vueI18n.t('user-create-page.textInputName')} ${this.vueI18n.t(
          'common.minLength'
        )}`
    ],
    password: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length >= 5) ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.minLength')}`,
      (v: string) =>
        (!!v && v.length <= 15) ||
        `${this.vueI18n.t(
          'user-create-page.textInputPassword'
        )} ${this.vueI18n.t('common.maxLength')}`
    ],
    confirmPassword: [
      () => this.passwordEquals() || 'As senhas precisam ser iguais'
    ],
    department: [
      (v: string) => !!v || `Departamento ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length <= 100) ||
        `Departamento ${this.vueI18n.t('common.maxLength')}`
    ],
    telephone: [
      (v: string) =>
        !!v ||
        `${this.vueI18n.t(
          'user-create-page.textInputTelephone'
        )} ${this.vueI18n.t('common.required')}`,
      (v: string) =>
        (!!v && v.length > 13) ||
        `${this.vueI18n.t(
          'user-create-page.textInputTelephone'
        )} ${this.vueI18n.t('common.minLength')}`
    ],
    role: [
      v =>
        !!v ||
        `${this.vueI18n.t('user-create-page.textInputRole')} ${this.vueI18n.t(
          'common.required'
        )}`
    ]
  };

  public vueI18n = vueI18n;
  public confirmPassword = '';
  public boolPassword = false;
  public documentValidator = DocumentValidator;
  public eventName = EventName.Common;

  public update() {
    if (this.validateUserForm()) {
      this.$emit(this.eventName.update, this.user);
    }
  }

  private backToListUser(): void {
    this.$router.push(CtrlPanelRoutes.USER_LIST);
  }

  private validateUserForm() {
    const form = this.$refs.formUser as HTMLFormElement;
    return !!form.validate();
  }

  private passwordEquals() {
    return this.user.password === this.confirmPassword;
  }
}
