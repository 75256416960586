














































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';

import TagDomainRow from '@/apps/controlPanel/components/tag-domain-row.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import OneLineListItemText from '@/components/list-item/OneLineListItemText.vue';
import TwoLineItem from '@/components/list-item/TwoLineListItem.vue';
import TwoLineListItem from '@/components/list-item/TwoLineListItem.vue';
import OneLineItemAction from '@/components/list-item/OneLineListItemButtonAction.vue';
import PageDescription from '@/components/PageDescription.vue';
import DataSetTab from '@/apps/controlPanel/components/data-set-tab.vue';

import Functionality from '@/apps/controlPanel/models/functionality';
import DataSet from '@/apps/controlPanel/models/dataSet';

import { StepEnum } from '@/apps/controlPanel/models/enums';
import { DataSourceEnum } from '@/utils/models/enums';
import Role from '@/apps/controlPanel/models/role';
import TagDomain from '@/apps/controlPanel/models/tagDomain';
import EventName from '@/apps/controlPanel/models/event-name';

@Component({
  components: {
    TwoLineListItem,
    InfoMessage,
    TagDomainRow,
    DataSetTab,
    OneLineListItemText,
    OneLineItemAction,
    TwoLineItem,
    PageDescription
  }
})
export default class RoleStepSecond extends Vue {
  @Prop({ required: true })
  public tagDomains!: TagDomain[];

  @Prop({ required: true })
  public role!: Role;

  @Prop({ required: true })
  public tagFunctionalities!: Functionality[];

  @Prop({ required: true })
  public enrichmentFunctionalities!: Functionality[];

  @Prop({ required: true })
  public dataSets!: DataSet[];

  public Tabs = {
    Domain: 1,
    Advanced: 2,
    DataSet: 3
  };

  public dataSourceEnum = DataSourceEnum;
  public selectedTab: any = null;
  public eventName = EventName.Role;

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public tagActiveTab = this.Tabs.Domain;
  public enrichmentActiveTab = this.Tabs.DataSet;
  public enableTagSwitch = false;
  public enableEnrichmentSwitch = false;

  public get dataSetGrouped(): DataSet[] {
    return this.dataSets.groupBy(x => x.dataSource);
  }

  public setStepRoleForm(step: StepEnum): void {
    this.$emit(this.eventName.setStepRoleForm, step);
  }
}
