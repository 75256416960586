















































import { Component, Prop, Vue } from 'vue-property-decorator';
import DataContext from '@/models/DataContext';

@Component({
  name: 'TrillionDatasetSelect'
})
export default class TrillionDatasetSelect extends Vue {
  @Prop({ required: true })
  public dataContexts!: DataContext[];

  @Prop({ required: true })
  public width!: number;

  @Prop({ required: false })
  public tabSelected!: string;

  private tab!: string;

  public selectTab(value: string) {
    this.tab = value;
    this.$emit('update:tabSelected', value);
    this.$emit('selectTab', value);
  }

  public created() {
    this.tab = this.tabSelected;
  }
}
