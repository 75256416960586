







































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';

import ClientDomainForm from '@/apps/controlPanel/modules/client/pages/client-domain-form.page.vue';
import TagDomainRow from '@/apps/controlPanel/components/tag-domain-row.vue';
import LimitRequest from '@/apps/controlPanel/components/limit-request.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import OneLineListItemText from '@/components/list-item/OneLineListItemText.vue';
import TwoLineItem from '@/components/list-item/TwoLineListItem.vue';
import OneLineItemAction from '@/components/list-item/OneLineListItemButtonAction.vue';
import PageDescription from '@/components/PageDescription.vue';
import DataSetTab from '@/apps/controlPanel/components/data-set-tab.vue';

import Functionality from '@/apps/controlPanel/models/functionality';
import Client from '@/apps/controlPanel/models/client';

import { StepEnum } from '@/apps/controlPanel/models/enums';
import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import DataSource from '@/models/DataSource';
import DataSourceSetSelectable from '@/apps/controlPanel/components/data-source-set-selectable.vue';

@Component({
  components: {
    InfoMessage,
    ClientDomainForm,
    LimitRequest,
    TagDomainRow,
    DataSetTab,
    OneLineListItemText,
    OneLineItemAction,
    TwoLineItem,
    PageDescription,
    DataSourceSetSelectable
  }
})
export default class ClientStepSecond extends Vue {
  @Prop({ required: true })
  public client!: Client;

  @Prop({ required: true })
  public getTagFunctionalities!: Functionality[];

  @Prop({ required: true })
  public getEnrichmentFunctionalities!: Functionality[];

  @Prop({ required: true })
  public dataSources!: DataSource[];

  public Tabs = {
    Domain: 1,
    Advanced: 2,
    DataSet: 3,
    RequestLimit: 4
  };

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public editDomainIndex: number | undefined;
  public enableTag = false;
  public showFormAddDomain = false;
  public enableEnrichment = false;
  public tagActiveTab = this.Tabs.Domain;
  public enrichmentActiveTab = this.Tabs.DataSet;
  public dataSetsChecked = [];

  public get tagFunctionalities(): Functionality[] {
    return this.getTagFunctionalities;
  }

  public get enrichmentFunctionalities(): Functionality[] {
    return this.getEnrichmentFunctionalities;
  }

  public eraseDomains() {
    this.$store.commit(StoreCtrlClient.Mutations.clearTagDomains);
  }

  public eraseDataSets() {
    this.$store.commit(StoreCtrlClient.Mutations.clearDataSets);
  }

  public setStepClientForm(step: StepEnum) {
    this.$store.commit(
      StoreCtrlClient.Mutations.setDataSets,
      this.dataSetsChecked
    );
    this.$store.commit(StoreCtrlClient.Mutations.setStepClientForm, step);
  }

  public toggleAddTagForm() {
    this.showFormAddDomain = !this.showFormAddDomain;
    this.editDomainIndex = undefined;
  }

  public editDomain(index: number) {
    this.showFormAddDomain = true;
    this.editDomainIndex = index;
  }
}
