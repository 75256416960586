import JwtDecode, { JwtPayload } from 'jwt-decode';

export class AuthUser {
  constructor(
    email = '',
    username = '',
    currentJwt = '',
    idClient = 0,
    nameClient = '',
    functionalities = new Array<string>()
  ) {
    this._email = email;
    this._username = username;
    this._jwtToken = currentJwt;
    this._idClient = idClient;
    this._nameClient = nameClient;
    this._functionalities = functionalities;
  }

  private readonly _email: string;
  private readonly _username: string;
  private readonly _jwtToken: string;
  private readonly _idClient: number;
  private readonly _nameClient: string;
  private readonly _functionalities: string[];

  public get isLogged(): boolean {
    if (this._jwtToken) {
      const { exp } = JwtDecode<JwtPayload>(this._jwtToken);
      return (
        !!(this._email && this._username && this._jwtToken) &&
        this._jwtToken !== '' &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Date.now() <= exp! * 1000
      );
    }

    return false;
  }

  public get email(): string {
    return this._email;
  }

  public get token(): string {
    return this._jwtToken;
  }

  public get username(): string {
    return this._username;
  }

  public get idClient(): number {
    return this._idClient;
  }

  public get nameClient(): string {
    return this._nameClient;
  }

  public get functionalities(): string[] {
    return this._functionalities;
  }
}
