

























































import { Component, Prop, Vue } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';

import Functionality from '@/apps/controlPanel/models/functionality';

import OneLineListItemText from '@/components/list-item/OneLineListItemText.vue';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import Role from '@/apps/controlPanel/models/role';
import EventName from '@/apps/controlPanel/models/event-name';

@Component({
  components: {
    OneLineListItemText
  }
})
export default class extends Vue {
  @Prop({ required: false, default: false })
  public usedOnModal!: boolean;

  @Prop({ required: true })
  public role!: Role;

  @Prop({ required: true })
  public idClient!: number;

  @Prop({ required: true })
  public functionalities!: Functionality[];

  public vueI18n = vueI18n;
  public stepEnum = StepEnum;
  public eventName = EventName;

  public setStepRoleForm(step: StepEnum) {
    this.$emit(this.eventName.Role.setStepRoleForm, step);
  }

  public save() {
    this.$emit(this.eventName.Common.save);
  }

  public update() {
    this.$emit(this.eventName.Common.update);
  }
}
