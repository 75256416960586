


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { MainStore } from '@/store/types';

@Component({})
export default class ModalNewRole extends Vue {
  @Getter(MainStore.Getters.getIsOpenDialogModal)
  private openDialogModal!: boolean;

  @Mutation(MainStore.Mutations.toggleDialogModal)
  private toggleDialogModal;

  @Prop({ required: true })
  private title!: string;

  public close() {
    this.$emit('close');
  }
}
