























































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreCtrlRole } from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.types';

import Role from '@/apps/controlPanel/models/role';
import { StepEnum } from '@/apps/controlPanel/models/enums';
import { AuthStore } from '@/apps/auth/store/types';
import { MainStore } from '@/store/types';
import { DialogModel } from '@/utils/models/dialogModel';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';

import DialogMessage from '@/components/dialog/DialogMessage.vue';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import FeaturePermission from '@/utils/feature-permission';
import { DialogType } from '@/utils/models/enums';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage
  }
})
export default class extends Vue {
  @Getter(AuthStore.Getters.getIdClient)
  public idClient!: number;

  @Getter(StoreCtrlRole.Getters.getRoles)
  public roles!: Role[];

  @Getter(MainStore.Getters.getIsOpenDialog)
  public openDialog!: boolean;

  @Getter(StoreCtrlRole.Getters.getRoleHasUser)
  public roleHasUser!: boolean;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Action(StoreCtrlRole.Actions.setStepRoleForm)
  public setStepRoleForm!: (step: StepEnum) => void;

  @Action(StoreCtrlRole.Actions.getAllRoles)
  public getAllRoles;

  @Action(StoreCtrlRole.Actions.deleteRole)
  public deleteRole!: (idRole: number) => void;

  @Action(StoreCtrlRole.Actions.roleHasUser)
  public fetchRoleHasUser!: (role: Role) => Promise<void>;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogModel: DialogModel) => void;

  @Action(MainStore.Actions.changeToggleDialog)
  public toggleDialog!: () => void;

  public vueI18n = vueI18n;
  public featurePermission = FeaturePermission;
  public idRoleToDelete!: number;

  public dialog = false;

  private breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.ROLE_LIST.meta.title,
      CtrlPanelRoutes.ROLE_LIST.path
    )
  ];

  public created() {
    this.getAllRoles(this.idClient);
  }

  public createRole() {
    this.$router.push(CtrlPanelRoutes.ROLE_CREATE);
  }

  public editRole(idRole: number) {
    this.setStepRoleForm(StepEnum.First);
    this.$router.push({
      name: CtrlPanelRoutes.ROLE_UPDATE.name,
      params: {
        idRole: String(idRole)
      }
    });
  }

  public getDataTableHeader(name: string) {
    return [
      { text: name, align: 'start', value: 'name' },
      { text: '', value: 'id', align: 'end' }
    ];
  }

  public openModalDeleteRole(role: Role) {
    const getTitle = (): string => {
      return this.roleHasUser
        ? vueI18n.t('common.attention').toString()
        : vueI18n.t('role-page.deleteMessage').toString();
    };
    const getText = (): string => {
      return this.roleHasUser
        ? vueI18n.t('role-delete.are_you_sure').toString()
        : role.name;
    };
    this.fetchRoleHasUser(role).then(() => {
      this.idRoleToDelete = role.id;
      this.dialogMessage.type = DialogType.Warning;
      this.dialogMessage.title = getTitle();
      this.dialogMessage.text = getText();
      this.dialogMessage.isConfirm = true;
      this.showDialogMessage(this.dialogMessage);
    });
  }

  public confirmDelete(): void {
    this.deleteRole(this.idRoleToDelete);
  }

  public destroyed(): void {
    this.idRoleToDelete = 0;
  }
}
