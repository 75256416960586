






import { Component, Prop, Vue } from 'vue-property-decorator';
import { TupleDictionary } from '@/utils/models/tupleDictionary';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { DocumentSpecification } from '@/apps/enrichment/models/types';

interface BtnAction {
  pathName: string;
}

@Component({
  name: 'BtnLinkConsult'
})
export default class BtnLinkConsult extends Vue {
  @Prop({ required: true })
  public documentFormat!: string;

  public get dictRoutes(): TupleDictionary<[number], BtnAction> {
    const dict = new TupleDictionary<[number], BtnAction>();
    dict.set([DocumentSpecification.documentFormattedLength.CPF], {
      pathName: EnrichmentRoutes.CONSULT_CPF.name
    });
    dict.set([DocumentSpecification.documentFormattedLength.CNPJ], {
      pathName: EnrichmentRoutes.CONSULT_CNPJ.name
    });
    dict.set([DocumentSpecification.documentFormattedLength.CEP], {
      pathName: EnrichmentRoutes.CONSULT_CEP.name
    });
    return dict;
  }

  public goRoute() {
    const currentRoute = this.dictRoutes.get([this.documentFormat.length]);
    if (this.$route.name === currentRoute.pathName) {
      this.$emit('updateDocument', this.documentFormat);
      return;
    }

    this.$router.push({
      name: currentRoute.pathName,
      params: {
        document: this.getUnMaskDocument.toString()
      }
    });
  }

  private get getUnMaskDocument(): number {
    return parseInt(this.documentFormat.replace(/\D+/g, ''), 10);
  }
}
