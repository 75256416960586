






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { searchWithoutAccents } from '@/utils/input.helpers';
import DataContext from '@/models/DataContext';

@Component({
  name: 'TrillionDatasetDialog'
})
export default class TrillionDatasetDialog extends Vue {
  @Prop({ required: true })
  public dataContexts!: DataContext[];

  @Prop({ required: false })
  public tabSelected!: string;

  @Prop({ required: false, default: () => false })
  public dialog!: boolean;

  public dialogModel = false;

  public searchDataset = '';

  public get contextSearched() {
    if (this.dataContexts && this.dataContexts.length) {
      return this.dataContexts
        .findOrFirst(item => item.name === this.tabSelected)
        .dataSets.filter(dataSet =>
          searchWithoutAccents(this.searchDataset, dataSet)
        );
    }

    return [];
  }

  public closeDialog() {
    this.dialogModel = false;
    this.$emit('close');
  }
}
