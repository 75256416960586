import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';

import Login from '@/apps/auth/pages/login.page.vue';
import PowerbiPage from '@/apps/enrichment/pages/powerbi.page.vue';

import { AuthStore } from '@/apps/auth/store/types';
import { controlPanelRoutes } from '@/apps/controlPanel/router';
import { EnrichmentRoutes, enrichmentRoutes } from '@/apps/enrichment/router';

Vue.use(VueRouter);

export const AppRoutes = {
  ROOT: {
    name: 'Root',
    path: '/'
  },
  POWERBI: {
    name: 'Avon',
    path: '/avon'
  },
  LOGIN: {
    name: 'Login',
    path: '/login'
  }
};

const routes: RouteConfig[] = [
  {
    path: AppRoutes.ROOT.path,
    component: () => import('@/root.vue'),
    children: [
      ...controlPanelRoutes,
      ...enrichmentRoutes,
      {
        path: '',
        redirect: EnrichmentRoutes.CONSULT.path
      }
    ]
  },
  {
    path: AppRoutes.POWERBI.path,
    component: () => import('@/app.vue'),
    children: [
      {
        path: EnrichmentRoutes.POWERBI_EVAS_PAGE.path,
        name: EnrichmentRoutes.POWERBI_EVAS_PAGE.name,
        meta: EnrichmentRoutes.POWERBI_EVAS_PAGE.meta,
        component: PowerbiPage,
        props: {
          idWorkspace: '079ad921-23ea-487c-aaa4-2d2e1141ce15',
          idReport: '2a65a5da-8b1b-459c-9712-2c85f5740ee5'
        }
      },
      {
        path: EnrichmentRoutes.POWERBI_REZONEAMENTO_PAGE.path,
        name: EnrichmentRoutes.POWERBI_REZONEAMENTO_PAGE.name,
        meta: EnrichmentRoutes.POWERBI_REZONEAMENTO_PAGE.meta,
        component: PowerbiPage,
        props: {
          idWorkspace: '154fdb35-817c-470a-8517-ef27c7084f6b',
          idReport: '36e6fba2-38ac-424e-b7d1-2ce0ed6a5bda'
        }
      }
    ]
  },
  {
    path: AppRoutes.LOGIN.path,
    name: AppRoutes.LOGIN.name,
    component: Login
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const checkUrlAuthorization = (authorizes, next) => {
  const { isNavigationFailure, NavigationFailureType } = VueRouter;
  const isAnyAuthorized: (feature: string) => boolean =
    store.getters[AuthStore.Getters.isAnyAuthorized];

  if (!isAnyAuthorized(authorizes)) {
    return router
      .push({ path: EnrichmentRoutes.CONSULT.path })
      .catch(failure => {
        if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
          failure.to.path = '/';
        }
      });
  }

  next();
};

router.beforeEach((to, from, next) => {
  const publicPages = [AppRoutes.LOGIN.path];
  const authRequired = !publicPages.includes(to.path);
  const { authorize }: any = to.meta;
  const isAuthenticated = store.getters[AuthStore.Getters.isAuthenticatedUser];

  if (authRequired && !isAuthenticated) {
    return next(AppRoutes.LOGIN.path);
  }

  if (!!authorize && authorize.length) {
    return checkUrlAuthorization(authorize, next);
  }

  next();
});

export default router;
