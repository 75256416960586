const base = 'ctrlPanel/user';

export const StoreCtrlUser = {
  Getters: {
    getUsers: `${base}/getUsers`,
    getUser: `${base}/getUser`
  },

  Mutations: {
    setUsers: 'setUsers',
    setUser: 'setUser',
    removeUserFromList: `removeUserFromList`
  },

  Actions: {
    getAllUsers: `${base}/getAllUsers`,
    getUser: `${base}/getUser`,
    inactivateUser: `${base}/inactivateUser`,
    setDefaultUser: `${base}/setDefaultUser`,
    saveUser: `${base}/saveUser`,
    updateUser: `${base}/updateUser`,
    removeUserFromList: `${base}/removeUserFromList`
  }
};
