import { IKeyValue } from '@/utils/models/contracts/iKeyValue';
import { KeyValue } from '@/utils/models/keyValue';

export class EnumHelpers {
  public static getKeyDescription(targetEnum: any, propEnum: any) {
    const isValueProperty = parseInt(propEnum, 10) >= 0;
    if (isValueProperty) {
      return targetEnum[propEnum];
    }
  }

  public static TransformToKeyValue(targetEnum: any): IKeyValue[] {
    const result: IKeyValue[] = [];
    for (const enumMember in targetEnum) {
      const isValueProperty = parseInt(enumMember, 10) >= 0;
      if (isValueProperty) {
        result.push(
          new KeyValue(
            this.getKeyDescription(targetEnum, enumMember),
            enumMember
          )
        );
      }
    }

    return result;
  }
}

declare global {
  interface Array<T> {
    findOrFirst(predicate: (obj: T) => boolean): T;

    findOrReturn(predicate: (obj: T) => boolean, defaultValue: T): T;

    group(key: string): any;

    groupBy(selector: (obj: T) => any): any;

    distinctBy(selector: (obj: T) => any): T[];

    sum(): number;

    sumBy(selector: (obj: T) => number): number;

    sortBy(selector: (obj: T) => any, order?: 'asc' | 'desc'): T[];

    sortByDesc(selector: (obj: T) => any): T[];

    maxValueBy(selector: (obj: T) => number): number;

    minValueBy(selector: (obj: T) => number): number;
  }
}

Array.prototype.findOrFirst = function<T>(
  this: T[],
  predicate: (obj: T) => boolean
): T {
  const result = this.find(predicate) || this[0];
  return result;
};

Array.prototype.findOrReturn = function<T>(
  this: T[],
  predicate: (obj: T) => boolean,
  defaultValue: T
): T {
  return this.find(predicate) || defaultValue;
};

Array.prototype.group = function<T, TResult>(this: T[], key: string): any {
  return this.reduce((result: any, currentValue: any) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );

    return result;
  }, {});
};

Array.prototype.groupBy = function<T, TResult>(
  this: T[],
  selector: (obj: T) => any
): any {
  return this.reduce((result: any, currentValue) => {
    (result[selector(currentValue)] =
      result[selector(currentValue)] || []).push(currentValue);

    return result;
  }, {});
};

Array.prototype.distinctBy = function<T>(
  this: T[],
  selector: (obj: T) => any
): T[] {
  return this.filter(
    (obj, index, list) =>
      list.map(x => selector(x)).indexOf(selector(obj)) === index
  );
};

Array.prototype.sum = function(this: number[]): number {
  return this.reduce((agg, crr) => agg + crr, 0);
};

Array.prototype.sumBy = function<T>(
  this: T[],
  selector: (obj: T) => number
): number {
  return this.reduce((agg, crr) => agg + selector(crr), 0);
};

Array.prototype.sortBy = function<T>(
  this: T[],
  selector: (obj: T) => any,
  order = 'asc'
): T[] {
  if (!this || !this.length) {
    return [];
  }

  const isString = typeof selector(this[0]) === 'string';

  const sortNumbers = (a: any, b: any): number =>
    order === 'asc' ? selector(a) - selector(b) : selector(b) - selector(a);
  const sortStrings = (a: any, b: any): number =>
    order === 'asc'
      ? selector(a).localeCompare(selector(b))
      : selector(b).localeCompare(selector(a));

  const sorter = isString ? sortStrings : sortNumbers;

  return this.sort(sorter);
};

Array.prototype.sortByDesc = function<T>(
  this: T[],
  selector: (obj: T) => any
): T[] {
  return this.sortBy(selector, 'desc');
};

Array.prototype.minValueBy = function<T>(
  this: T[],
  selector: (obj: T) => number
): number {
  return Math.min(...this.map(selector));
};

Array.prototype.maxValueBy = function<T>(
  this: T[],
  selector: (obj: T) => number
): number {
  return Math.max(...this.map(selector));
};
