import { IKeyValue } from '@/utils/models/contracts/iKeyValue';

export class KeyValue implements IKeyValue {
  constructor(text: string, value: string) {
    this.text = text;
    this.value = value;
  }

  public text: string;
  public value: string;
}
