








































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'TrillionDatePicker'
})
export default class TrillionDatePicker extends Vue {
  @Prop({ required: true })
  public dates!: { start: Date; end: Date };

  @Prop({ required: false, default: () => 'rgb(0, 0, 0, 0.38)' })
  public color!: string;

  @Prop({ required: false, default: () => 'rgb(0, 0, 0)' })
  public colorText!: string;

  @Prop({ required: false, default: () => false })
  public closeInChange!: boolean;

  public menu = false;

  public internalDates!: { start: Date; end: Date };

  public get from() {
    return this.formatDate(this.dates.start);
  }

  public get to() {
    return this.formatDate(this.dates.end);
  }

  public created() {
    this.internalDates = this.dates;
  }

  public selectDate() {
    this.$emit('update:dates', this.internalDates);
    this.$emit('selectDate', this.internalDates);

    if (this.closeInChange) {
      this.menu = false;
    }
  }

  public formatDate(date) {
    if (!date) {
      return null;
    }
    const dateFormatted = moment(date).format('DD MMMM YYYY');

    const indexInitialMonthString = dateFormatted.indexOf(' ') + 4;

    return `${dateFormatted.substring(
      0,
      indexInitialMonthString
    )} ${dateFormatted.substring(dateFormatted.lastIndexOf(' '))}`;
  }
}
