





















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';

import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { MainStore } from '@/store/types';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { DialogModel } from '@/utils/models/dialogModel';
import {
  DocumentSpecification,
  TabResult
} from '@/apps/enrichment/models/types';

import DocumentValidator from '@/utils/documentValidator';
import CnpjDetailCard from '@/apps/enrichment/components/cnpj-detail-card.vue';
import CnpjDetailTable from '@/apps/enrichment/components/cnpj-detail-table.vue';
import Company from '@/apps/enrichment/models/company';
import TBtnLoading from '@/components/trillion-button-loading.vue';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import TListDatasets from '@/apps/enrichment/components/list-datasets.vue';
import { DocumentSearch } from '@/apps/enrichment/store/store';
import TCardTrillion from '@/apps/enrichment/components/card-trillion.vue';

@Component({
  components: {
    CnpjDetailCard,
    CnpjDetailTable,
    TBtnLoading,
    TBreadcrumbs,
    DialogMessage,
    TListDatasets,
    TCardTrillion
  }
})
export default class ConsultCnpj extends Vue {
  @Action(EnrichmentStore.Actions.clearCompanyData)
  public clearCompanyDataStored!: () => void;

  @Action(EnrichmentStore.Actions.consultCnpj)
  public consultCpf!: (document: number) => Promise<Company>;

  @Mutation(EnrichmentStore.Mutations.setTabResultSelected)
  public setTabResultSelected!: (tabResult: TabResult) => void;

  @Mutation(EnrichmentStore.Mutations.setCompanySearch)
  public setCompanySearch!: (search: DocumentSearch) => void;

  @Getter(EnrichmentStore.Getters.getCompanyFetched)
  public companyFetched: Company | undefined;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Getter(EnrichmentStore.Getters.getTabResultSelected)
  public tabResultSelected!: TabResult;

  @Getter(EnrichmentStore.Getters.getCompanySearch)
  public companySearchResult!: DocumentSearch;

  @Prop({ required: false })
  public queryDocument: number | undefined;

  public documentValidator = DocumentValidator;
  public vueI18n = vueI18n;
  public moment = moment;
  public TabResult = TabResult;
  public document = '';

  public selectedTab = 0;

  public get tabSelected() {
    return this.tabResultSelected;
  }

  public set tabSelected(value: string) {
    this.setTabResultSelected(value as TabResult);
  }

  public get companySearch(): DocumentSearch {
    return this.companySearchResult;
  }

  public set companySearch(value: DocumentSearch) {
    this.setCompanySearch(value);
  }

  public dataSetDescription: string[] = [
    'Razão Social',
    'Data de Fundação',
    'Outros telefones do mesmo CNPJ',
    'Endereço',
    'Emails',
    'Informações Complementares',
    'Participações empresariais',
    'Quadro Sócio Administrativo',
    'Filiais',
    'Condição'
  ];

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_CNPJ.meta.title, '', true)
  ];

  public updateDocument(document: string) {
    this.document = document;
    this.doConsult();
  }

  public created(): void {
    if (this.companySearch.document) {
      this.document = this.companySearch.document;
    }

    if (this.queryDocument) {
      this.document = this.queryDocument
        .toString()
        .padStart(DocumentSpecification.DocumentLength.CNPJ, '0');
    }

    window.addEventListener('keydown', this.keyDownEvent);
  }

  public destroyed() {
    this.clearCompanyDataStored();
    this.clearData();
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public keyDownEvent(event) {
    const treat = {
      27: {
        name: 'esc',
        func: () => this.clearCNPJ()
      }
    };

    treat[event.keyCode]?.func();
  }

  public clearCNPJ(): void {
    this.clearData();
    (this.$refs.document as Vue & { focus: () => void }).focus();
  }

  public mounted(): void {
    if (this.queryDocument) {
      this.doConsult();
    }
  }

  public doConsult() {
    (this.$refs.document as Vue & { onBlur: () => void }).onBlur();

    if (this.form.validate() && this.getUnMaskDocument) {
      this.setCompanySearch({
        document: this.document,
        date: moment().format('lll')
      });
      this.consultCpf(this.getUnMaskDocument);
    }
  }

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public get hasData(): boolean {
    return !!(
      this.document &&
      this.document === this.companySearch.document &&
      this.companyFetched &&
      this.companyFetched.document
    );
  }

  private get subsidiarySummary() {
    if (!this.companyFetched) {
      return [];
    }

    const groupBySituation = this.companyFetched.subsidieries.reduce(
      (acc, x) => {
        acc[x.situation] = ++acc[x.situation] || 1;
        acc.Quantidade++;
        return acc;
      },
      { Quantidade: 0 }
    );

    return groupBySituation;
  }

  private get getUnMaskDocument(): number {
    return parseInt(this.document.replace(/\D+/g, ''), 10);
  }

  private clearData(): void {
    this.document = '';
    this.setCompanySearch({ document: '', date: '' });
  }
}
