







import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  @Prop()
  private icon!: string;

  @Prop()
  private message!: string;
}
