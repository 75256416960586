import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { httpService } from '@/services/http.service';

type method = 'get' | 'post' | 'put' | 'delete';
export interface Options {
  method: method;
  parameters?: any;
  axiosRequestConfig?: AxiosRequestConfig;
}

export class ServiceHelper {
  public static mapToPromise = <T>(
    url: string,
    options: Options = { method: 'get', axiosRequestConfig: {} }
  ): Promise<T> =>
    new Promise<T>((resolve, reject) => {
      const get = (): AxiosPromise<T> =>
        httpService.http.get<T>(url, options.axiosRequestConfig);
      const post = (): AxiosPromise<T> =>
        httpService.http.post<T>(
          url,
          options.parameters,
          options.axiosRequestConfig
        );
      const put = (): AxiosPromise<T> =>
        httpService.http.put<T>(
          url,
          options.parameters,
          options.axiosRequestConfig
        );
      const del = (): AxiosPromise<T> =>
        httpService.http.delete<T>(url, options.parameters);

      const dictAction: Array<{
        type: method;
        action: () => AxiosPromise<T>;
      }> = [
        { type: 'get', action: get },
        { type: 'post', action: post },
        { type: 'put', action: put },
        { type: 'delete', action: del }
      ];

      const { action } =
        dictAction.find(x => x.type === options.method) || dictAction[0];

      action()
        .then(res => res.data)
        .then(x => {
          resolve(x);
        })
        .catch(err => {
          reject(err);
        });
    });

  public static generateFile = <T>(
    url: string,
    nameFile: string,
    options: Options = { method: 'get', axiosRequestConfig: {} },
    mimeType = 'application/vnd.ms-excel'
  ): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const get = (): AxiosPromise<T> => httpService.httpBlob.get<T>(url);
      const post = (): AxiosPromise<T> =>
        httpService.httpBlob.post<T>(url, options.parameters);
      const dict: Array<{ type: method; action: () => AxiosPromise<T> }> = [
        { type: 'get', action: get },
        { type: 'post', action: post }
      ];

      const { action } = dict.findOrFirst(x => x.type === options.method);

      action()
        .then((res: AxiosResponse) => {
          const blob = new Blob([res.data], {
            type: mimeType
          });
          const link = document.createElement('a');

          link.style.display = 'none';
          link.href = window.URL.createObjectURL(blob);
          link.download = nameFile;
          link.click();

          window.URL.revokeObjectURL(url);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
}
