import User from '@/apps/controlPanel/models/user';
import { ServiceHelper } from '@/utils/service.helper';
import { CONFIG } from '../../../../../../config';

export default class UserService {
  public static getAllUsers = (idClient: number): Promise<User[]> =>
    ServiceHelper.mapToPromise<User[]>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user/${idClient}/all`
    );

  public static getUser = (idUser: number): Promise<User> =>
    ServiceHelper.mapToPromise<User>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user/${idUser}`
    );

  public static inactivateUser = (userDto: User): Promise<boolean> =>
    ServiceHelper.mapToPromise<boolean>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user/inactivate`,
      { method: 'put', parameters: userDto }
    );

  public static saveUser = (userDto: User): Promise<number> =>
    ServiceHelper.mapToPromise<number>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user/create`,
      { method: 'post', parameters: userDto }
    );

  public static updateUser = (userDto: User): Promise<void> =>
    ServiceHelper.mapToPromise<void>(
      `${CONFIG.URL.CONTROLPANEL_API}/v1/user/update`,
      { method: 'put', parameters: userDto }
    );
}
