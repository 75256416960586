
























































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';
import moment from 'moment';

import { EnrichmentStore } from '@/apps/enrichment/store/types';
import { EnrichmentRoutes } from '@/apps/enrichment/router';
import { DialogModel } from '@/utils/models/dialogModel';
import { MainStore } from '@/store/types';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { ITextValue } from '@/utils/models/contracts/iKeyValue';

import Address from '@/apps/enrichment/models/address';
import DocumentValidator from '@/utils/documentValidator';
import DataUnavailable from '@/apps/enrichment/components/data-unavailable.vue';
import TBtnLoading from '@/components/trillion-button-loading.vue';
import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import BtnLinkConsult from '@/apps/enrichment/components/btn-link-consult.vue';
import DialogMessage from '@/components/dialog/DialogMessage.vue';
import TCardTrillion from '@/apps/enrichment/components/card-trillion.vue';

import FeaturePermission from '@/utils/feature-permission';

@Component({
  components: {
    TBtnLoading,
    TBreadcrumbs,
    DataUnavailable,
    BtnLinkConsult,
    DialogMessage,
    TCardTrillion
  }
})
export default class ConsultCep extends Vue {
  @Action(EnrichmentStore.Actions.clearAddressData)
  public clearAddressDataStored!: () => void;

  @Action(EnrichmentStore.Actions.consultCep)
  public consultCep!: (address: Address) => Promise<Address>;

  @Mutation(EnrichmentStore.Mutations.setAddressSearch)
  public setAddressSearch!: (value: Address) => void;

  @Getter(EnrichmentStore.Getters.getAddressFetched)
  public addressFetched: Address | undefined;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Getter(EnrichmentStore.Getters.getAddressSearch)
  public addressSearchStore!: Address;

  public vueI18n = vueI18n;
  public documentValidator = DocumentValidator;
  public filter = new Address();
  public sizeItemsPerPage = 10;
  public featurePermission = FeaturePermission;

  public breadcrumps: BreadcrumbItem[] = [
    new BreadcrumbItem(
      EnrichmentRoutes.CONSULT.meta.title,
      EnrichmentRoutes.CONSULT.path
    ),
    new BreadcrumbItem(EnrichmentRoutes.CONSULT_CEP.meta.title, '', true)
  ];

  public created() {
    window.addEventListener('keydown', this.keyDownEvent);
  }

  public destroyed() {
    this.clearAddressDataStored();
    this.filter = new Address();
    window.removeEventListener('keydown', this.keyDownEvent);
  }

  public keyDownEvent(event) {
    const treat = {
      27: {
        name: 'esc',
        func: () => {
          this.filter = new Address();
          (this.$refs.cep as Vue & { focus: () => void }).focus();
        }
      }
    };

    treat[event.keyCode]?.func();
  }

  public doConsult() {
    if (this.form.validate() && this.filter.cep) {
      this.filter.cep = this.filter.unMaskCep();
      const searchStore = new Address();

      searchStore.cep = this.filter.cep;
      searchStore.address = this.filter.address;
      searchStore.additionalAddress = this.filter.additionalAddress;
      searchStore.addressNumber = this.filter.addressNumber;
      searchStore.dateSearch = moment().format('lll');

      this.setAddressSearch(searchStore);
      this.consultCep(this.filter);
    }
  }

  public get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public get headerTablePeoples(): Array<ITextValue<string>> {
    return [
      { text: 'CPF', value: 'link' },
      { text: 'Nome', value: 'name' }
    ];
  }

  public get headerTableCompany(): Array<ITextValue<string>> {
    return [
      { text: 'CNPJ', value: 'link' },
      { text: 'Razão Social', value: 'socialReason' }
    ];
  }
}
