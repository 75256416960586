export enum StepEnum {
  First = 1,
  Second = 2,
  Third = 3
}

export enum PersonType {
  Juridic = 1,
  Social = 2
}
