import { IRole } from '@/apps/controlPanel/models/types';

export default class Role implements IRole {
  constructor() {
    this.id = 0;
    this.idClient = 0;
    this.name = '';
    this.idFunctionalities = [];
    this.idTagDomains = [];
    this.idDataSets = [];
  }

  public id: number;
  public idClient: number;
  public name: string;
  public idFunctionalities: number[];
  public idTagDomains: number[];
  public idDataSets: number[];

  public isValid(): boolean {
    return Boolean(this.idFunctionalities.length || this.idDataSets.length);
  }
}
