














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITagDomain } from '@/apps/controlPanel/models/types';
import TagDomain from '@/apps/controlPanel/models/tagDomain';
import { Getter } from 'vuex-class';
import { StoreCtrlClient } from '@/apps/controlPanel/modules/client/store/ctrlPanel.client.types';
import Client from '@/apps/controlPanel/models/client';
import EventName from '@/apps/controlPanel/models/event-name';
import vueI18n from '@/plugins/vuei18n';

@Component
export default class ClientDomainForm extends Vue {
  @Prop({ required: false })
  private domainIndex: number | undefined;

  @Getter(StoreCtrlClient.Getters.getClient)
  private client!: Client;

  private vueI18n: typeof vueI18n = vueI18n;
  private localDomain: ITagDomain = new TagDomain('', '');
  private eventName = EventName.Client;

  private isFormValid(): boolean {
    const formDomain = this.$refs.formDomain as HTMLFormElement;
    return formDomain.validate();
  }

  private mounted(): void {
    if (this.domainIndex !== undefined && Number.isInteger(this.domainIndex)) {
      this.localDomain = this.client.tagDomains[this.domainIndex];
    }
  }

  private addNewDomain() {
    if (!this.isFormValid()) {
      return;
    }

    if (this.domainIndex !== undefined && Number.isInteger(this.domainIndex)) {
      this.client.tagDomains[this.domainIndex] = this.localDomain;
      this.clearForm();
      return;
    }

    this.client.tagDomains.push(this.localDomain);
    this.clearForm();
  }

  private removeDomain() {
    if (this.domainIndex !== undefined && Number.isInteger(this.domainIndex)) {
      this.client.tagDomains.splice(this.domainIndex, 1);
      this.clearForm();
    }
  }

  private cancelDomainForm() {
    this.clearForm();
  }

  private clearForm() {
    this.localDomain = new TagDomain('', '');
    this.$emit(this.eventName.addNewDomain);
  }
}
