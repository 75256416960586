




















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';

import { StoreControlPanel } from '@/apps/controlPanel/store/types';
import { StoreCtrlUser } from '@/apps/controlPanel/modules/user/store/ctrlPanel.user.types';

import DialogMessage from '@/components/dialog/DialogMessage.vue';
import FormUserCreate from '@/apps/controlPanel/modules/user/pages/user-forms/user-create-form.page.vue';
import FormUserUpdate from '@/apps/controlPanel/modules/user/pages/user-forms/user-update-form.page.vue';

import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import User from '@/apps/controlPanel/models/user';
import { DialogModel } from '@/utils/models/dialogModel';
import { MainStore } from '@/store/types';
import { AuthStore } from '@/apps/auth/store/types';
import { StoreCtrlRole } from '@/apps/controlPanel/modules/role/store/ctrlPanel.role.types';
import Role from '@/apps/controlPanel/models/role';
import { StepEnum } from '@/apps/controlPanel/models/enums';

@Component({
  components: {
    FormUserCreate,
    FormUserUpdate,
    DialogMessage,
    TBreadcrumbs
  }
})
export default class extends Vue {
  @Getter(StoreControlPanel.Getters.getValidationMessage)
  public validationMessage: string | undefined;

  @Getter(AuthStore.Getters.getIdClient)
  public idClient!: number;

  @Getter(StoreCtrlRole.Getters.getRoles)
  public roles!: Role[];

  @Getter(StoreCtrlUser.Getters.getUser)
  public user!: User;

  @Getter(MainStore.Getters.getIsOpenDialogModal)
  public modalIsOpen!: boolean;

  @Mutation(MainStore.Mutations.toggleDialog)
  public toggleDialog;

  @Action(StoreCtrlUser.Actions.setDefaultUser)
  public setDefaultUser;

  @Action(StoreCtrlUser.Actions.getUser)
  public getUser!: (idUser: number) => Promise<User>;

  @Action(StoreCtrlRole.Actions.getAllRoles)
  public getAllRoles!: (idClient: number) => void;

  @Action(StoreCtrlUser.Actions.updateUser)
  public updateUser!: (user: User) => void;

  @Action(StoreCtrlUser.Actions.saveUser)
  public saveUser!: (user: User) => void;

  @Action(MainStore.Actions.changeToggleDialogModal)
  public changeToggleDialogModal!: () => void;

  @Action(StoreCtrlRole.Actions.setStepRoleForm)
  public setStepRoleForm!: (step: StepEnum) => Promise<void>;

  @Action(StoreCtrlRole.Actions.setDefaultRole)
  public setDefaultRole!: () => Promise<void>;

  public dialogMessage: DialogModel = new DialogModel('', '');
  public vueI18n = vueI18n;

  public breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.USER_LIST.meta.title,
      CtrlPanelRoutes.USER_LIST.path
    ),
    new BreadcrumbItem(
      this.$route.params.idUser
        ? CtrlPanelRoutes.USER_UPDATE.meta.title
        : CtrlPanelRoutes.USER_CREATE.meta.title,
      '',
      true
    )
  ];

  public update(): void {
    this.updateUser(this.user);
  }

  public save(): void {
    this.saveUser(this.user);
  }

  public created(): void {
    this.setDefaultUser();
    this.getAllRoles(this.idClient);
    if (!isNaN(Number(this.$route.params.idUser))) {
      this.getUser(Number(this.$route.params.idUser));
    }
  }
}
