































































import DataContext from '@/models/DataContext';
import DataSet from '@/models/DataSet';
import DataSource from '@/models/DataSource';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { searchWithoutAccents } from '@/utils/input.helpers';

/**
 * This component makes it possible to select datasets by datasource and datacontext.
 *
 * @component
 * @example
 * <data-source-set-selectable :data-sources="[]" :datasets-checked.sync="dataSetsChecked" />
 */
@Component({
  name: 'DataSourceSetSelectable'
})
export default class DataSourceSetSelectable extends Vue {
  @Prop({ required: true })
  public dataSources!: DataSource[];

  public actives: DataContext[] = [];
  public searchDataset = '';

  private readonly EmitterUpdateDataSetsChecked = 'update:datasets-checked';

  public get dataSourceAndContexts() {
    return this.dataSources.map(dataSource => ({
      id: dataSource.id,
      name: dataSource.name,
      children: dataSource.dataContexts.map((dataContext: DataContext) => ({
        id: `${dataSource.id}|${dataContext.id}`,
        name: dataContext.name,
        dataSets: dataContext.dataSets
      }))
    }));
  }

  public get dataSetSearched() {
    if (this.actives && this.actives.length) {
      return this.actives[0].dataSets.filter((dataSet: DataSet) =>
        searchWithoutAccents(this.searchDataset, dataSet)
      );
    }

    return [];
  }

  public handlerCheckAllDatasets() {
    if (this.actives && this.actives.length > 0) {
      this.actives[0].dataSets.forEach((x: DataSet) => (x.checked = true));
    }
    this.handlerDataSetCheckeds();
  }

  private handlerDataSetCheckeds() {
    const dataSetsCheckeds = this.dataSourceAndContexts.reduce((acc, curr) => {
      const dataSets = curr.children.reduce((dsAcc, dsCurr) => {
        return [
          ...dsAcc,
          ...dsCurr.dataSets.filter((ds: DataSet) => ds.checked)
        ];
      }, new Array<DataSet>());

      return [...acc, ...dataSets];
    }, new Array<DataSet>());

    this.$emit(this.EmitterUpdateDataSetsChecked, dataSetsCheckeds);
  }
}
