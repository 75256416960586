import { Route } from 'vue-router';
import FeaturePermission from '@/utils/feature-permission';

import Consult from '@/apps/enrichment/pages/consult-list.page.vue';
import ConsultCpf from '@/apps/enrichment/pages/consult-cpf.page.vue';
import ConsultCnpj from '@/apps/enrichment/pages/consult-cnpj.page.vue';
import ConsultCep from '@/apps/enrichment/pages/consult-cep.page.vue';
import ConsultReport from '@/apps/enrichment/pages/consult-report.page.vue';
import ConsultBatch from '@/apps/enrichment/pages/batch/consult-batch.page.vue';
import ConsultNewBatchPage from '@/apps/enrichment/pages/batch/consult-new-batch.page.vue';
import ConsultFileBatchPage from '@/apps/enrichment/pages/batch/consult-file-batch.page.vue';
import DiscoverOwnerCampaignListPage from '@/apps/enrichment/pages/discoverowner/campaign-list.page.vue';
import DiscoverOwnerCampaignNewPage from '@/apps/enrichment/pages/discoverowner/campaign-new.page.vue';
import DiscoverOwnerJobListPage from '@/apps/enrichment/pages/discoverowner/job-list.page.vue';

export const EnrichmentRoutes = Object.freeze({
  CONSULT: {
    path: '/consult',
    name: 'Consults',
    meta: {
      title: 'Consulta Trillion',
      authorize: [
        FeaturePermission.enrichment.consultCpf,
        FeaturePermission.enrichment.consultCnpj,
        FeaturePermission.enrichment.consultCpfByCep,
        FeaturePermission.enrichment.consultCnpjByCep,
        FeaturePermission.enrichment.consultReport,
        FeaturePermission.enrichment.consultBatch,
        FeaturePermission.discoverOwner.campaign.list,
        FeaturePermission.discoverOwner.campaign.create,
        FeaturePermission.powerbi.avonEvas,
        FeaturePermission.powerbi.avonRezoneamento,
      ],
    },
  },
  POWERBI_EVAS_PAGE: {
    path: `/avon/evas`,
    name: 'ReportEvas',
    meta: {
      title: 'Evas microrregioões',
      authorize: [FeaturePermission.powerbi.avonEvas],
    },
  },
  POWERBI_REZONEAMENTO_PAGE: {
    path: `/avon/rezoneamento`,
    name: 'ReportRezoneamento',
    meta: {
      title: 'Rezoneamento',
      authorize: [FeaturePermission.powerbi.avonRezoneamento],
    },
  },
  CONSULT_CPF: {
    path: `/consult/cpf`,
    name: 'ConsultCpf',
    meta: {
      title: 'Consulta por CPF',
      authorize: [FeaturePermission.enrichment.consultCpf],
    },
  },
  CONSULT_CNPJ: {
    path: `/consult/cnpj`,
    name: 'ConsultCnpj',
    meta: {
      title: 'Consulta por CNPJ',
      authorize: [FeaturePermission.enrichment.consultCnpj],
    },
  },
  CONSULT_CEP: {
    path: `/consult/cep`,
    name: 'ConsultCep',
    meta: {
      title: 'Consulta por CEP',
      authorize: [
        FeaturePermission.enrichment.consultCpfByCep,
        FeaturePermission.enrichment.consultCnpjByCep,
      ],
    },
  },
  CONSULT_REPORT: {
    path: `/consult/audit/report`,
    name: 'ConsultAuditReport',
    meta: {
      title: 'Auditoria de Enriquecimento',
    },
    authorize: [FeaturePermission.enrichment.consultReport],
  },
  CONSULT_BATCH: {
    path: '/consult/batch',
    name: 'ConsultBatch',
    meta: {
      title: 'Consulta em Lote',
      authorize: [FeaturePermission.enrichment.consultBatch],
    },
  },
  CONSULT_NEW_BATCH: {
    path: '/consult/batch/new',
    name: 'ConsultNewBatch',
    meta: {
      title: 'Nova Consulta',
      authorize: [FeaturePermission.enrichment.consultBatch],
    },
  },
  CONSULT_FILE_BATCH: {
    path: '/consult/batch/file',
    name: 'ConsultFileBatch',
    meta: { authorize: [FeaturePermission.enrichment.consultBatch] },
  },
  DISCOVER_OWNER_CAMPAIGN_LIST: {
    path: '/discoverowner/campaigns',
    name: 'DiscoverOwnerCampaignList',
    meta: {
      title: 'Consulta de Campanhas',
      authorize: [FeaturePermission.discoverOwner.campaign.list],
    },
  },
  DISCOVER_OWNER_CAMPAIGN_NEW: {
    path: '/discoverowner/campaigns/new',
    name: 'DiscoverOwnerCampaignNew',
    meta: {
      title: 'Criação de Campanha',
      authorize: [FeaturePermission.discoverOwner.campaign.create],
    },
  },
  DISCOVER_OWNER_JOB_LIST: {
    path: '/discoverowner/jobs',
    name: 'DiscoverOwnerCampaignJobs',
    meta: {
      title: 'Consulta DiscoverOwner - Jobs',
      authorize: [
        FeaturePermission.discoverOwner.campaign.list,
        FeaturePermission.discoverOwner.job.auditRequest
      ],
    },
  },
});

const propDocument = (route: Route) => ({
  queryDocument: Number(route.params.document)
});

export const enrichmentRoutes = [
  {
    path: EnrichmentRoutes.CONSULT.path,
    name: EnrichmentRoutes.CONSULT.name,
    meta: EnrichmentRoutes.CONSULT.meta,
    component: Consult,
  },
  {
    path: EnrichmentRoutes.CONSULT_CPF.path,
    name: EnrichmentRoutes.CONSULT_CPF.name,
    meta: EnrichmentRoutes.CONSULT_CPF.meta,
    component: ConsultCpf,
    props: propDocument,
  },
  {
    path: EnrichmentRoutes.CONSULT_CNPJ.path,
    name: EnrichmentRoutes.CONSULT_CNPJ.name,
    meta: EnrichmentRoutes.CONSULT_CNPJ.meta,
    component: ConsultCnpj,
    props: propDocument,
  },
  {
    path: EnrichmentRoutes.CONSULT_CEP.path,
    name: EnrichmentRoutes.CONSULT_CEP.name,
    meta: EnrichmentRoutes.CONSULT_CEP.meta,
    component: ConsultCep,
    props: propDocument,
  },
  {
    path: EnrichmentRoutes.CONSULT_BATCH.path,
    name: EnrichmentRoutes.CONSULT_BATCH.name,
    meta: EnrichmentRoutes.CONSULT_BATCH.meta,
    component: ConsultBatch,
  },
  {
    path: EnrichmentRoutes.CONSULT_REPORT.path,
    name: EnrichmentRoutes.CONSULT_REPORT.name,
    meta: EnrichmentRoutes.CONSULT_REPORT.meta,
    component: ConsultReport,
  },
  {
    path: EnrichmentRoutes.CONSULT_NEW_BATCH.path,
    name: EnrichmentRoutes.CONSULT_NEW_BATCH.name,
    meta: EnrichmentRoutes.CONSULT_NEW_BATCH.meta,
    component: ConsultNewBatchPage,
  },
  {
    path: EnrichmentRoutes.CONSULT_FILE_BATCH.path,
    name: EnrichmentRoutes.CONSULT_FILE_BATCH.name,
    meta: EnrichmentRoutes.CONSULT_FILE_BATCH.meta,
    component: ConsultFileBatchPage,
  },
  {
    path: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.path,
    name: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.name,
    meta: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_LIST.meta,
    component: DiscoverOwnerCampaignListPage,
  },
  {
    path: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_NEW.path,
    name: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_NEW.name,
    meta: EnrichmentRoutes.DISCOVER_OWNER_CAMPAIGN_NEW.meta,
    component: DiscoverOwnerCampaignNewPage,
  },
  {
    path: EnrichmentRoutes.DISCOVER_OWNER_JOB_LIST.path,
    name: EnrichmentRoutes.DISCOVER_OWNER_JOB_LIST.name,
    meta: EnrichmentRoutes.DISCOVER_OWNER_JOB_LIST.meta,
    component: DiscoverOwnerJobListPage,
  },
];
