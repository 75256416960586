







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreCtrlUser } from '@/apps/controlPanel/modules/user/store/ctrlPanel.user.types';
import User from '@/apps/controlPanel/models/user';
import { Action, Getter } from 'vuex-class';
import vueI18n from '@/plugins/vuei18n';
import { DialogModel } from '@/utils/models/dialogModel';
import { AuthStore } from '@/apps/auth/store/types';
import UserAuthentication from '@/apps/auth/services/auth.service';
import DialogMessage from '@/components/dialog/DialogMessage.vue';

import TBreadcrumbs from '@/components/breadcrumbs/trillion-breadcrumbs.vue';
import { BreadcrumbItem } from '@/components/breadcrumbs/breadcrumb-item';
import { CtrlPanelRoutes } from '@/apps/controlPanel/router';
import FeaturePermission from '@/utils/feature-permission';
import { MainStore } from '@/store/types';
import { DialogType } from '@/utils/models/enums';

@Component({
  components: {
    TBreadcrumbs,
    DialogMessage
  }
})
export default class extends Vue {
  @Prop({ required: false })
  public idUser: number | undefined;

  @Getter(StoreCtrlUser.Getters.getUsers)
  public users!: User[];

  @Getter(AuthStore.Getters.getEmail)
  public authUserEmail!: string;

  @Getter(AuthStore.Getters.getIdClient)
  public idClient!: UserAuthentication;

  @Getter(MainStore.Getters.getDialogMessage)
  public dialogMessage!: DialogModel;

  @Action(MainStore.Actions.showDialogMessage)
  public showDialogMessage!: (dialogMessage: DialogModel) => Promise<void>;

  @Action(StoreCtrlUser.Actions.getAllUsers)
  public getAllUsers;

  @Action(StoreCtrlUser.Actions.inactivateUser)
  public inactivateUser!: (user: User) => Promise<boolean>;

  @Action(StoreCtrlUser.Actions.removeUserFromList)
  public removeUserFromList!: (user: User) => Promise<void>;

  public userToDelete: User = new User();

  public vueI18n = vueI18n;
  public featurePermission = FeaturePermission;

  private breadcrumbs: BreadcrumbItem[] = [
    new BreadcrumbItem(
      CtrlPanelRoutes.CONTROL_PANEL.meta.title,
      CtrlPanelRoutes.CONTROL_PANEL.path
    ),
    new BreadcrumbItem(
      CtrlPanelRoutes.USER_LIST.meta.title,
      CtrlPanelRoutes.USER_LIST.path
    )
  ];

  public created() {
    this.getAllUsers(this.idClient);
  }

  public createUser() {
    this.$router.push({
      name: CtrlPanelRoutes.USER_CREATE.name
    });
  }

  public deleteThisUser() {
    this.userToDelete.isActive = false;
    this.inactivateUser(this.userToDelete);
    this.removeUserFromList(this.userToDelete);
  }

  public editUser(idUser: number) {
    this.$router.push({
      name: CtrlPanelRoutes.USER_UPDATE.name,
      params: {
        idUser: String(idUser.valueOf())
      }
    });
  }

  private openModalDeleteUser(user: User) {
    this.showDialogMessage(
      new DialogModel(
        String(vueI18n.t('user-page.deleteMessage')),
        user.username,
        DialogType.Warning,
        true
      )
    );
    this.userToDelete = user;
  }

  private getDataTableHeader(
    username: string,
    email: string,
    department: string,
    nameClient: string
  ) {
    return [
      { text: username, align: 'start', value: 'username' },
      { text: email, value: 'email' },
      { text: department, value: 'department' },
      { text: nameClient, value: 'nameClient' },
      { text: '', value: 'id', align: 'end' }
    ];
  }
}
