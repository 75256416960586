






















import { Component, Vue, Prop } from 'vue-property-decorator';
import vueI18n from '@/plugins/vuei18n';

import TwoLineItem from '@/components/list-item/TwoLineListItem.vue';
import OneLineItemAction from '@/components/list-item/OneLineListItemButtonAction.vue';
import { ITagDomain } from '../models/types';

@Component({
  components: {
    TwoLineItem,
    OneLineItemAction
  }
})
export default class TagDomainRow extends Vue {
  @Prop({ type: Object as () => ITagDomain, required: true })
  private domain!: ITagDomain;

  @Prop({ required: true })
  private domainIndex!: number;

  private vueI18n: any = vueI18n;

  private editDomain() {
    this.$emit('edit', this.domainIndex);
  }
}
