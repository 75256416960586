











import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { MainStore } from '@/store/types';

@Component({
  name: 't-btn-loading',
  inheritAttrs: false
})
export default class TBtnLoading extends Vue {
  @Getter(MainStore.Getters.getLoading)
  public isLoading!: boolean;

  @Prop({ required: false, default: true })
  public valid!: boolean;
}
